import { createSlice } from '@reduxjs/toolkit'
import { approveAbsent, createAbsent, deleteAbsent, editAbsent } from './absent.api'

export interface AbsentState {
    loading: boolean,
    timeGetData: any
}
const initialState: AbsentState = {
    loading: false,
    timeGetData: undefined
}

export const absentSlice = createSlice({
    name: 'absent',
    initialState,
    reducers: {
        setTimeGetData: (state, action) => {
            state.timeGetData = action.payload;
            console.log(action.payload)
        }
    },
    extraReducers: (builder) => {

        builder.addCase(createAbsent.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createAbsent.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createAbsent.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteAbsent.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteAbsent.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteAbsent.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editAbsent.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editAbsent.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editAbsent.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(approveAbsent.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(approveAbsent.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(approveAbsent.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setTimeGetData
} = absentSlice.actions;

export default absentSlice.reducer