import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createAbsent = createAsyncThunk('createAbsent', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/absent',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteAbsent = createAsyncThunk('deleteAbsent', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/absent?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editAbsent = createAsyncThunk('editAbsent', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/absent',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const approveAbsent = createAsyncThunk('approveAbsent', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/absent/approve',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})