import { Button, ListItemButton, ListItemText } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { RootState } from "../../../store";
import { deleteHoliday } from "../holiday.api";
import { setTimeGetData } from "../holiday.reducer";
import HolidayEdit from "./HolidayEdit";

export default function Holiday() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { yearSelected, timeGetData } = useSelector((state: RootState) => state.holiday)

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteHoliday(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete holiday")
                                dispatch(setTimeGetData(new Date().getTime()))
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Fragment>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit Holiday"
                component={
                    <HolidayEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/holiday"}
                additionalParams={`&year=${yearSelected}`}
                columns={[
                    {
                        name: "Year",
                        key: "year"
                    },
                    {
                        name: "Employee",
                        key: "employee_name"
                    },
                    {
                        name: "Total Holiday",
                        key: "value"
                    },
                    {
                        name: "Active Holiday",
                        key: "value_active"
                    },
                    {
                        name: "Total Holiday Soon",
                        key: "value_soon"
                    },
                    {
                        name: "Total Holiday Taken",
                        key: "value_taken"
                    },
                    {
                        name: "Total Holiday Available",
                        key: "value_available"
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("HOLIDAY_CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => navigate('/holiday/add')}
                            >
                                New Holiday
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("HOLIDAY_EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("HOLIDAY_DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                timeGetData={timeGetData}
            />
        </Fragment>
    )
}