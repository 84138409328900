import { createSlice } from '@reduxjs/toolkit'
import { createProjectDefault, deleteProjectDefault, editProjectDefault } from './projectDefault.api'

export interface ProjectDefaultState {
    loading: boolean
}
const initialState: ProjectDefaultState = {
    loading: false
}

export const projectDefaultSlice = createSlice({
    name: 'projectDefault',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createProjectDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createProjectDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createProjectDefault.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteProjectDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteProjectDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteProjectDefault.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editProjectDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editProjectDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editProjectDefault.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default projectDefaultSlice.reducer