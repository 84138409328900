import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";
export const editEmployeeJob = createAsyncThunk('editEmployeeJob', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})