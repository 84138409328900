import { Autocomplete, Card, CardContent, Container, Grid, List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setLoading, setTitle } from "../../../containers/containers.reducer";
import FormAutocomplete from "../../../shared/FormAutocomplete";
import notificationError from "../../../shared/notificationError";
import { baseUrl } from "../../../state";
import { getJobNumberOptions } from "../project.api";
import JobNumber from "./detail/JobNumber";

export default function JobManagement() {

    const dispatch = useDispatch()

    const [kojList, setKojList] = useState<any>([])
    const [kojSelected, setKojSelected] = useState<any>({ id: 'ALL', name: 'ALL' })
    const [projectId, setProjectId] = useState("")
    const [jobnumberList, setJobnumberList] = useState<any>([])
    const [jobnumberId, setJobnumberId] = useState("")

    useEffect(() => {
        dispatch(setTitle("Job Management"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Project",
                url: "/project"
            },
            {
                name: "Job Management",
                url: "/project/jobnumber"
            }
        ]))
        getData()
    }, [])

    useEffect(() => {
        if (projectId) {
            dispatch<any>(getJobNumberOptions({ project_id: projectId, koj_id: kojSelected.id }))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setJobnumberList(response.data.data)
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }, [projectId])

    useEffect(() => {
        if (!Boolean(kojSelected?.id)) {
            setKojSelected({ id: 'ALL', name: 'ALL' })
        }
    }, [kojSelected])

    const getData = async () => {
        dispatch(setLoading(true))
        try {
            let response = await axios({
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
                },
                url: baseUrl + '/koj'
            })
            if (response.status === 200) {
                dispatch(setLoading(false))
                setKojList([...response.data.data, { id: 'ALL', name: 'ALL' }])
            }
            else {
                dispatch(setLoading(false))
                notificationError(response)
            }
        }
        catch (error) {
            dispatch(setLoading(false))
            notificationError(error)
        }
    }

    const handleSelectKoj = (e: any, value: any) => {
        setJobnumberList([])
        setJobnumberId("")
        setProjectId("")
        setKojSelected(value)
    }

    const handleSelectProject = (e: any) => {
        setJobnumberList([])
        setJobnumberId("")
        setProjectId(e.target.value)
    }

    return (
        <Fragment>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={3}>
                                        <b>KOJ</b>
                                    </Grid>
                                    <Grid item md={9}>
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            id="combo-box-demo"
                                            options={kojList}
                                            getOptionLabel={(option: any) => { return option.name }}
                                            // renderOption={(options: any, option: any) => (<><span>{option.name}</span><br /></>)}
                                            value={kojSelected}
                                            onChange={handleSelectKoj}
                                            renderInput={(params) => <TextField {...params} size="small" placeholder={"Choose KOJ"} />}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <FormAutocomplete url={`/project/options?koj_id=${kojSelected?.id}`} label="Project" onChange={handleSelectProject} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={8}>
                        {projectId && (
                            <Card>
                                <CardContent>
                                    <FormAutocomplete url={`/project/jobnumber/options?project_id=${projectId}&koj_id=${kojSelected.id}`} label="Job Number" value={jobnumberId} onChange={(e: any) => setJobnumberId(e.target.value)} />
                                </CardContent>
                                <Card>
                                    <CardContent>
                                        <List style={{ maxHeight: 200, overflow: 'auto' }}>
                                            {jobnumberList.map((job: any) => (
                                                <ListItem disablePadding key={job.id}>
                                                    <ListItemButton onClick={() => setJobnumberId(job.id)}>
                                                        <ListItemText primary={job.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Container>
            <br />
            {jobnumberId && <JobNumber id={jobnumberId} />}
        </Fragment>
    )
}