import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createRemoveTarget = createAsyncThunk('createRemoveTarget', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/removetarget',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteRemoveTarget = createAsyncThunk('deleteRemoveTarget', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/removetarget?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editRemoveTarget = createAsyncThunk('editRemoveTarget', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/removetarget',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const approveRemoveTargetHead = createAsyncThunk('approveRemoveTargetHead', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/removetarget/approve/head',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const approveRemoveTargetHr = createAsyncThunk('approveRemoveTargetHr', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/removetarget/approve/hr',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})