import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormAutocomplete from "../../../../shared/FormAutocomplete";
import FormDate from "../../../../shared/FormDate";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { createAdditionalPoles } from "../additionalPoles.api";

export default function AdditionalPolesAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        name: "",
        type_id: "",
        employee_id: "",
        additional_minutes: 0,
        notes: "",
        notes_rejection: "",
    })

    useEffect(() => {
        dispatch(setTitle("Add Additional Poles"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Project",
                url: ""
            },
            {
                name: "Additional Poles",
                url: "/project/additionalpoles"
            },
            {
                name: "Add Additional Poles",
                url: "/project/additionalpoles/add"
            }
        ]))
        setValues({
            ...values,
            name: moment().format("YYYY-MM-DD hh:mm:ss")
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createAdditionalPoles({
            ...values,
            employee_id: window.localStorage.getItem("cqmToken"),
            status_id: "78DDF83B-6C8F-4748-B053-100EBC8B91AE"
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new additionalPoles")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Additional Poles
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Additional Poles
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Date" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete label="Type" name="type_id" value={values.type_id} url="/type/job" onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormNumber label="Additional Minutes" name="additional_minutes" value={values.additional_minutes} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}