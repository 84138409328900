import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormNumber from "../../../../shared/FormNumber"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { editPosition } from "../position.api"
import PositionEditAccess from "./PositionEditAccess"

export default function PositionEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        name: "",
        level_value: 0,
        access: ""
    })

    useEffect(() => {
        setValues(props.data)
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editPosition(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit position")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <div className="form-header modal">
                        <div></div>
                        <div>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => props.onClose(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Check />}
                                type="submit"
                            >
                                Update Position
                            </Button>
                        </div>
                    </div>
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Name" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Level" name="level_value" value={values.level_value} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h6" align="center">Access</Typography>
                    <br />
                    {values.id && <PositionEditAccess value={values.access} onChange={handleChangeValues} />}
                    <br />
                </form>
            </CardContent>
        </Card >
    )
}