import { Typography } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import { RootState } from "../../../store";
import WorksheetDetails from "../../worksheet/component/WorksheetDetails";
import { getEmployeeJobRunning } from "../takenjob.api";
import { setTimeGetData } from "../takenjob.reducer";
import TakenjobRunning from "./TakenjobRunning";

export default function TakenjobMain() {

    const dispatch = useDispatch()

    const { jobRunning, timeGetData } = useSelector((state: RootState) => state.takenjob)

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalDetails, setModalDetails] = useState(false)

    useEffect(() => {
        getDataRunning()
    }, [])

    const getDataRunning = () => {
        dispatch<any>(getEmployeeJobRunning())
            .unwrap()
            .then((response: any) => {
                if (response.status !== 200) {
                    notificationError(response)
                }
            })
    }

    const handleCloseModalDetails = (isReload: boolean) => {
        setModalDetails(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    return (
        <Fragment>
            <CustomModal
                open={modalDetails}
                onClose={() => handleCloseModalDetails(true)}
                title="Taken Job Detail"
                component={
                    <WorksheetDetails onClose={() => handleCloseModalDetails(true)} data={dataSelected} />
                }
            />
            <div className="header-job">
                <Typography variant="h4">Main Job</Typography>
                <TakenjobRunning onClick={() => { setDataSelected(jobRunning); setModalDetails(true) }} />
            </div>
            <br />
            <CustomTable
                url={"/employee/job/main"}
                columns={[
                    {
                        name: "Progress",
                        customRender: (props: any) => {
                            if (props.data.ts_start) {
                                if (props.data.ts_stop) {
                                    return (
                                        <td>
                                            <span className="status-progress complete">
                                                Complete
                                            </span>
                                        </td>
                                    )
                                }
                                else {
                                    if (props.data.job_pause_status === 1) {
                                        return (
                                            <td>
                                                <span className="status-progress hold">
                                                    Hold
                                                </span>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td>
                                            <span className="status-progress running">
                                                Running
                                            </span>
                                        </td>
                                    )
                                }
                            }
                            return (
                                <td>
                                    <span className="status-progress hold">
                                        Hold
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Number",
                        key: "jobnumber_name"
                    },
                    {
                        name: "Requestor",
                        key: "jobnumber_project_requestor_name"
                    },
                    {
                        name: "Submittal",
                        key: "jobnumber_project_submittal_name"
                    },
                    {
                        name: "District",
                        key: "jobnumber_project_district_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span style={{ backgroundColor: props.data.koj_color }}>{props.data.koj_name}</span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Received",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_received ? moment(props.data.jobnumber_date_received).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Due Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_due ? moment(props.data.jobnumber_date_due).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Source Job",
                        key: "source_job"
                    },
                    {
                        name: "QTY Request",
                        key: "quantity_request"
                    },
                    {
                        name: "QTY Get",
                        key: "quantity_get"
                    }
                ]}
                timeGetData={timeGetData}
                onClickRow={(data: any) => { setDataSelected(data); setModalDetails(true) }}
            />
        </Fragment>
    )
}