import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getProjectListWithPaginations = createAsyncThunk('getProjectListWithPaginations', async (data: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project',
            params: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getProjectSingle = createAsyncThunk('getProjectSingle', async (id: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createProject = createAsyncThunk('createProject', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteProject = createAsyncThunk('deleteProject', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editProject = createAsyncThunk('editProject', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getJobNumberListWithPaginationsByProject = createAsyncThunk('getJobNumberListWithPaginationsByProject', async (data: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber',
            params: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getJobNumberSingle = createAsyncThunk('getJobNumberSingle', async (id: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getJobNumberOptions = createAsyncThunk('getJobNumberOptions', async (params: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/options',
            params: params
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createJobNumber = createAsyncThunk('createJobNumber', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createJobNumberRequest = createAsyncThunk('createJobNumberRequest', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/addrequest',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createJobNumberRevision = createAsyncThunk('createJobNumberRevision', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/addrevision',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteJobNumber = createAsyncThunk('deleteJobNumber', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const archiveJobNumber = createAsyncThunk('archiveJobNumber', async (id: string) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/archive?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const activeJobNumber = createAsyncThunk('activeJobNumber', async (id: string) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/active?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editJobNumber = createAsyncThunk('editJobNumber', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const jobnumberPartialCompleted = createAsyncThunk('jobnumberPartialCompleted', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/partialcompleted',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editJobNumberKoj = createAsyncThunk('editJobNumberKoj', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/kojedit',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getJobSingle = createAsyncThunk('getJobSingle', async (id: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editJob = createAsyncThunk('editJob', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteJob = createAsyncThunk('deleteJob', async (id: String) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createJobActivity = createAsyncThunk('createJobActivity', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job/addactivity',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createEmployeeJob = createAsyncThunk('createEmployeeJob', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getProjectDefault = createAsyncThunk('getProjectDefault', async (data: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/default',
            params: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})