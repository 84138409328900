import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getEmployeeJobByJobnumber = createAsyncThunk('getEmployeeJobByJobnumber', async (jobnumberId: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job?jobnumber_id=' + jobnumberId,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteEmployeeJob = createAsyncThunk('deleteEmployeeJob', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlJobStart = createAsyncThunk('controlJobStart', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/control/start/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlJobHold = createAsyncThunk('controlJobHold', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/control/hold/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlJobComplete = createAsyncThunk('controlJobComplete', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/control/complete/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editEmployeeJobMultiple = createAsyncThunk('editEmployeeJobMultiple', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/editmultiple',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlTimerIncrease = createAsyncThunk('controlTimerIncrease', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/minutes/increase/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlTimerDecrease = createAsyncThunk('controlTimerDecrease', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/minutes/decrease/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlTimerAdjust = createAsyncThunk('controlTimerAdjust', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/minutes/adjust/' + data.id,
            params: { value: data.value }
        })
        return response
    } catch (error: any) {
        return error.response
    }
})