import { Grid, TextField } from "@mui/material";

export default function FormColor(props: any) {
    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <b>{props.label}</b>
            </Grid>
            <Grid item md={9}>
                <TextField
                    type="color"
                    size="small"
                    style={{ width: 100 }}
                    placeholder={props.label}
                    name={props.name}
                    value={props.value || ""}
                    onChange={props.onChange}
                    required={props.required}
                    disabled={props.disabled}
                />
            </Grid>
        </Grid>
    )
}