import { Button, Card, CardContent, Grid } from "@mui/material"
import { Container } from "@mui/system"
import axios from "axios"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer"
import FormAutocomplete from "../../../shared/FormAutocomplete"
import FormDate from "../../../shared/FormDate"
import notificationError from "../../../shared/notificationError"
import { baseUrl } from "../../../state"
import { RootState } from "../../../store"
import { getTeam } from "../../master/team/team.api"

export default function ReportAll() {

    const dispatch = useDispatch()

    const { teamList } = useSelector((state: RootState) => state.team)

    const [dateStart, setDateStart] = useState<null | string>("")
    const [dateEnd, setDateEnd] = useState("")
    const [teamSelected, setTeamSelected] = useState<any>({})
    const [isOnProgress, setIsOnProgress] = useState(false)

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "All Report",
                url: "/report/all"
            }
        ]))
        dispatch<any>(getTeam())
    }, [])

    useEffect(() => {
        let dt = new Date().getTime()
        setDateStart(moment(dt - 604800000).format('YYYY-MM-DD'))
        setDateEnd(moment(dt).format('YYYY-MM-DD'))
    }, [])

    const handleChangeTeam = (e: any) => {
        let value = teamList.filter(((d: any) => d.id === e.target.value))[0]
        setTeamSelected(value)
    }

    const getData = () => {
        setIsOnProgress(true)
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}/report/all?datestart=${dateStart}&dateend=${dateEnd}${teamSelected.id ? `&team_id=${teamSelected.id}` : ``}`,
            responseType: "arraybuffer"
        })
            .then((response: any) => {
                const href = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', `all report ${teamSelected.name ? `${teamSelected.name} ` : ''}${dateStart} - ${dateEnd}.xlsx`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
                setIsOnProgress(false)
            })
            .catch(err => {
                if (err.response.status === 400) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Data not found"
                    })
                }
                else {
                    notificationError(err.response.data)
                }
                setIsOnProgress(false)
            })
    }

    return (
        <Container>
            <Grid container>
                <Grid item md={6}>
                    <Card>
                        <CardContent>
                            <FormDate label="Start" value={dateStart} onChange={(e: any) => setDateStart(e.target.value)} />
                            <br />
                            <FormDate label="End" value={dateEnd} onChange={(e: any) => setDateEnd(e.target.value)} />
                            <br />
                            <FormAutocomplete label="Team" url="/team" onChange={handleChangeTeam} />
                            <br />
                            <Button onClick={getData} variant="contained" fullWidth disabled={isOnProgress || teamSelected.id === undefined}>
                                {isOnProgress
                                    ? `Processing`
                                    : `Get Report`
                                }
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}