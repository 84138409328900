import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import axios from "axios"
import moment from "moment"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../../shared/FormAutocomplete"
import FormDate from "../../../../shared/FormDate"
import FormNumber from "../../../../shared/FormNumber"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { baseUrl } from "../../../../state"
import { editOvertime } from "../overtime.api"

export default function OvertimeEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        id: "",
        employee_id: "",
        name: "",
        minutes: "",
        multiple: "",
        notes: ""
    })
    const [jobList, setJobList] = useState<any>([])

    useEffect(() => {
        setValues({
            id: props.data.id,
            employee_id: props.data.employee_id,
            name: moment(props.data.name).format("YYYY-MM-DD"),
            minutes: props.data.minutes,
            multiple: props.data.multiple,
            notes: props.data.notes
        })
        parseInitial()
    }, [])

    let parseInitial = async () => {
        if (props.data.job_list) {
            let jobListInit = await JSON.parse(props.data.job_list)
            let jobListNew: any = []
            for (const i in jobListInit) {
                let response = await axios({
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
                    },
                    url: `${baseUrl}/project/jobnumber/options?project_id=${jobListInit[i].jobnumber_project_id}&koj_id=ALL`
                })
                let jobnumberListFiltered = await response.data.data.filter((jobnumber: any) => jobnumber.id === jobListInit[i].jobnumber_id)
                let kojIds = []
                if (jobnumberListFiltered.length > 0) {
                    kojIds = await jobnumberListFiltered[0].koj_ids.split(',')
                }
                jobListNew.push({
                    ...jobListInit[i],
                    project_id: jobListInit[i].jobnumber_project_id,
                    jobnumber_list: await response.data.data,
                    koj_ids: kojIds
                })
            }
            setJobList(jobListNew)
        }
    }

    const handleChangeProject = (i: number, value: string) => {
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}/project/jobnumber/options?project_id=${value}&koj_id=ALL`
        })
            .then((response: any) => {
                changeJobList(i, {
                    project_id: value,
                    jobnumber_id: undefined,
                    jobnumber_list: response.data.data
                })
            })
    }

    const handleChangeJobnumber = (i: number, value: string) => {
        let jobnumberListFiltered = jobList[i].jobnumber_list.filter((jobnumber: any) => jobnumber.id === value)
        if (jobnumberListFiltered.length > 0) {
            changeJobList(i, {
                jobnumber_id: value,
                koj_ids: jobnumberListFiltered[0].koj_ids.split(',')
            })
        }
    }

    const changeJobList = (i: number, value: any) => {
        setJobList(jobList.map((d: any, j: number) => {
            if (i === j) {
                return {
                    ...d,
                    ...value
                }
            }
            return d
        }))
    }

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editOvertime({
            ...values,
            id: props.data.id,
            job_list: jobList.map((job: any) => {
                return {
                    id: job.id,
                    quantity_pole: job.quantity_pole,
                    jobnumber_id: job.jobnumber_id,
                    koj_id: job.koj_id
                }
            })
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit overtime")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Card>
                <CardContent className="custom-form">
                    <Divider />
                    <br />
                    <FormDate label="Overtime Date" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Request Minutes" name="minutes" value={values.minutes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Multiple" name="multiple" value={values.multiple} onChange={handleChangeValues} step={0.5} />
                    <br />
                    {jobList.map((job: any, i: number) => (
                        <Fragment key={i}>
                            <br />
                            <Card style={{ border: "1px solid grey" }}>
                                <CardContent className="custom-form">
                                    <br />
                                    <FormAutocomplete url="/project/options?koj_id=ALL" label="Project" value={job.project_id} onChange={(e: any) => handleChangeProject(i, e.target.value)} />
                                    <br />
                                    {job.project_id && (
                                        <Fragment>
                                            <Divider />
                                            <br />
                                            <FormAutocomplete url={`/project/jobnumber/options?project_id=${job.project_id}&koj_id=ALL`} label="Job Number" value={job.jobnumber_id} onChange={(e: any) => handleChangeJobnumber(i, e.target.value)} />
                                            <br />
                                        </Fragment>
                                    )}
                                    {Boolean(job.jobnumber_id) && (
                                        <Fragment>
                                            <Divider />
                                            <br />
                                            <FormAutocomplete url="/koj" label="KOJ" value={job.koj_id} onChange={(e: any) => changeJobList(i, { koj_id: e.target.value })} idShowed={job.koj_ids} />
                                            <br />
                                        </Fragment>
                                    )}
                                    <Divider />
                                    <br />
                                    <FormNumber label="Quantity Pole" value={job.quantity_pole} onChange={(e: any) => changeJobList(i, { quantity_pole: e.target.value })} />
                                    <br />
                                </CardContent>
                            </Card>
                        </Fragment>
                    ))}
                    <br />
                    <Button variant="contained" onClick={() => setJobList([...jobList, {}])}>Add Job</Button>
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Overtime
                        </Button>
                    </div>
                </CardContent>
            </Card >
        </form>
    )
}