import { Button, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import checkAccess from "../../../../shared/checkAccess";
import CustomModal from "../../../../shared/CustomModal";
import CustomTable from "../../../../shared/CustomTable";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { RootState } from "../../../../store";
import { deleteOvertime } from "../overtime.api";
import { setTimeGetData } from "../overtime.reducer";
import OvertimeApprovalHead from "./OvertimeApprovalHead";
import OvertimeApprovalHr from "./OvertimeApprovalHr";
import OvertimeEdit from "./OvertimeEdit";

export default function OvertimeSelf() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { timeGetData } = useSelector((state: RootState) => state.overtime)

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [modalApprovalHead, setModalApprovalHead] = useState(false)
    const [modalApprovalHr, setModalApprovalHr] = useState(false)

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleCloseModalApprovalHead = (isReload: boolean) => {
        setModalApprovalHead(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleCloseModalApprovalHr = (isReload: boolean) => {
        setModalApprovalHr(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteOvertime(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete overtime")
                                dispatch(setTimeGetData(new Date().getTime()))
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Fragment>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit Overtime"
                component={
                    <OvertimeEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomModal
                open={modalApprovalHead}
                onClose={handleCloseModalApprovalHead}
                title="Head Approval"
                component={
                    <OvertimeApprovalHead onClose={handleCloseModalApprovalHead} data={dataSelected} />
                }
            />
            <CustomModal
                open={modalApprovalHr}
                onClose={handleCloseModalApprovalHr}
                title="HR Approval"
                component={
                    <OvertimeApprovalHr onClose={handleCloseModalApprovalHr} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/overtime"}
                additionalParams={`&employee_id=${localStorage.getItem("cqmToken")}`}
                filterDate={true}
                columns={[
                    {
                        name: "Employee",
                        key: "employee_name"
                    },
                    {
                        name: "Overtime Date",
                        key: "name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.name ? moment(props.data.name).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Request Minutes",
                        key: "minutes"
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Status",
                        key: "status_name"
                    },
                    {
                        name: "Approved Minutes",
                        key: "minutes_approval"
                    },
                    {
                        name: "Multiple",
                        key: "multiple"
                    },
                    {
                        name: "Rejection Notes",
                        key: "notes_approval"
                    },
                    {
                        name: "Job",
                        key: "job_list",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.job_list
                                        ? <ul>
                                            {JSON.parse(props.data.job_list).map((job: any) => (
                                                <li>
                                                    {job.jobnumber_number_job} - ({job.quantity_pole})
                                                </li>
                                            ))}
                                        </ul>
                                        : '-'
                                    }
                                </td>
                            )
                        }
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => navigate('/absent/overtime/add')}
                            >
                                New Overtime
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                timeGetData={timeGetData}
            />
        </Fragment>
    )
}