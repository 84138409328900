import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../../shared/FormAutocomplete"
import FormAutocompleteMultiple from "../../../../shared/FormAutocompleteMultiple"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { ID_POSITION_BILLING, ID_POSITION_CSCA, ID_POSITION_MANAGER } from "../../../../state"
import { editEmployee } from "../employee.api"
import EmployeeFormKoj from "./EmployeeFormKoj"
import FormCheck from "../../../../shared/FormCheck"

export default function EmployeeEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        name: "",
        username: "",
        email: "",
        password: "",
        status: "",
        team_ids: "",
        position_id: "",
        shift_id: "",
        koj_list: [],
        is_active: 0
    })

    useEffect(() => {
        setValues({
            ...props.data,
            koj_list: JSON.parse(props.data.koj_list)
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        if (name === "position_id") {
            setValues({
                ...values,
                [name]: value,
                team_ids: ""
            })
        }
        else {
            setValues({
                ...values,
                [name]: value,
            })
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editEmployee(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit employee")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormText label="Name" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="NIK" name="nik" value={values.nik} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Username" name="username" value={values.username} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Email" name="email" value={values.email} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Password" name="password" value={values.password} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/position" label="Position" name="position_id" value={values.position_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {[ID_POSITION_CSCA, ID_POSITION_MANAGER, ID_POSITION_BILLING].includes(values.position_id)
                        ? values.id && <FormAutocompleteMultiple url="/team" label="Team" name="team_ids" value={values.team_ids} onChange={handleChangeValues} required={values.is_active === 1} />
                        : values.id && <FormAutocomplete url="/team" label="Team" name="team_ids" value={values.team_ids} onChange={handleChangeValues} required={values.is_active === 1} />
                    }
                    <br />
                    <Divider />
                    <br />
                    {values.id && <EmployeeFormKoj name="koj_list" value={values.koj_list} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/shift" label="Shift" name="shift_id" value={values.shift_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Status" name="status" value={values.status} onChange={handleChangeValues} required />
                    <br />
                    <FormCheck label="Is Active" name="is_active" value={values.is_active} onChange={handleChangeValues} />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Employee
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}