import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createKoj = createAsyncThunk('createKoj', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/koj',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteKoj = createAsyncThunk('deleteKoj', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/koj?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editKoj = createAsyncThunk('editKoj', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/koj',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})