import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { editEmployeePassword } from "../employee.api"
import FormPassword from "../../../../shared/FormPassword"

export default function EmployeeEditPassword(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<{ password: string, passwordConfirm: string }>({
        password: "",
        passwordConfirm: ""
    })
    const [password, setPassword] = useState<string>("")

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editEmployeePassword({
            ...values,
            id: props.data.id,
            myPassword: password
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("password updated successfully")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormText label="Recent Password" value={props.data.password} readOnly />
                    <br />
                    <Divider />
                    <br />
                    <FormPassword label="New Password" value={values.password} name="password" onChange={handleChangeValues} required />
                    <br />
                    <FormPassword label="Password Confirmation" value={values.passwordConfirm} name="passwordConfirm" onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormPassword label="Your Password" value={password} onChange={(e: any) => setPassword(e.target.value)} required />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Employee
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}