import { createSlice } from '@reduxjs/toolkit'
import { createRequestor, deleteRequestor, editRequestor } from './requestor.api'

export interface RequestorState {
    loading: boolean
}
const initialState: RequestorState = {
    loading: false
}

export const requestorSlice = createSlice({
    name: 'requestor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createRequestor.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRequestor.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRequestor.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteRequestor.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRequestor.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRequestor.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editRequestor.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRequestor.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRequestor.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default requestorSlice.reducer