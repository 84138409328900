import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import loginReducer from './pages/login/login.reducer'
import containersReducer from './containers/containers.reducer'
import positionReducer from './pages/master/position/position.reducer'
import shiftReducer from './pages/master/shift/shift.reducer'
import teamReducer from './pages/master/team/team.reducer'
import districtReducer from './pages/master/district/district.reducer'
import kojReducer from './pages/master/koj/koj.reducer'
import employeeReducer from './pages/master/employee/employee.reducer'
import requestorReducer from './pages/master/requestor/requestor.reducer'
import submittalReducer from './pages/master/submittal/submittal.reducer'
import statusJobnumberReducer from './pages/master/statusJobnumber/statusJobnumber.reducer'
import projectReducer from './pages/project/project.reducer'
import billReducer from './pages/bill/bill.reducer'
import typeJobReducer from './pages/master/typeJob/typeJob.reducer'
import worksheetReducer from './pages/worksheet/worksheet.reducer'
import takenjobReducer from './pages/takenjob/takenjob.reducer'
import reportReducer from './pages/report/report.reducer'
import transferJobReducer from './pages/transferjob/transferJob.reducer'
import absentReducer from './pages/absent/absent/absent.reducer'
import overtimeReducer from './pages/absent/overtime/overtime.reducer'
import projectDefaultReducer from './pages/master/projectDefault/projectDefault.reducer'
import holidayReducer from './pages/holiday/holiday.reducer'
import billDefaultReducer from './pages/master/billDefault/billDefault.reducer'
import invoiceStatusReducer from './pages/invoiceStatus/invoiceStatus.reducer'
import dashboardReducer from './pages/dashboard/dashboard.reducer'
import removeTargetReducer from './pages/absent/removeTarget/removeTarget.reducer'
import trackerReducer from './pages/tracker/tracker.reducer'
import additionalPolesReducer from './pages/project/additionalPoles/additionalPoles.reducer'
import kpiReducer from './pages/kpi/kpi.reducer'

const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        login: loginReducer,
        containers: containersReducer,
        shift: shiftReducer,
        position: positionReducer,
        team: teamReducer,
        district: districtReducer,
        koj: kojReducer,
        employee: employeeReducer,
        requestor: requestorReducer,
        submittal: submittalReducer,
        statusjobnumber: statusJobnumberReducer,
        project: projectReducer,
        bill: billReducer,
        typejob: typeJobReducer,
        worksheet: worksheetReducer,
        takenjob: takenjobReducer,
        report: reportReducer,
        transferJob: transferJobReducer,
        absent: absentReducer,
        overtime: overtimeReducer,
        projectDefault: projectDefaultReducer,
        holiday: holidayReducer,
        billDefault: billDefaultReducer,
        invoiceStatus: invoiceStatusReducer,
        dashboard: dashboardReducer,
        removeTarget: removeTargetReducer,
        tracker: trackerReducer,
        additionalPoles: additionalPolesReducer,
        kpi: kpiReducer
    },
})

export type RootState = ReturnType<typeof store.getState>

export default store