import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function FormInlineCheck(props: any) {

    const handleChange = (e: any, value: any) => {
        props.onChange({
            target: {
                name: props.name,
                value: value ? 1 : 0
            }
        })
    }

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        name={props.name}
                        checked={props.value === 1 ? true : false}
                        onChange={handleChange}
                    />}
                label={props.label}
                disabled={props.disabled}
            />
        </FormGroup>
    )
}