import { Close, DragIndicator } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import notificationError from "../../../../shared/notificationError";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../containers/containers.reducer";
import { baseUrl } from "../../../../state";

export default function JobNumberFormKoj(props: any) {

    const dispatch = useDispatch()

    const [options, setOptions] = useState<any>([])
    const [optionsSelected, setOptionsSelected] = useState<any>([])
    const [startDragY, setStartDragY] = useState(0)

    useEffect(() => {
        dispatch(setLoading(true))
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/koj'
        })
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setLoading(false))
                    setOptions(response.data.data)
                    setOptionsSelected(props.value)
                }
                else {
                    dispatch(setLoading(false))
                    notificationError(response)
                }
            })
            .catch((error: any) => {
                dispatch(setLoading(false))
                notificationError(error.response)
            })
    }, [])

    const handleSelect = (item: any) => {
        let newSelected = [...optionsSelected, { koj_id: item.id, koj_name: item.name, bcode: "", order_value: optionsSelected.length }]
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleUnselect = (item: any) => {
        let newSelected = optionsSelected.filter((option: any) => option.koj_id !== item.koj_id)
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleDrag = (e: any, lastIndex: number) => {
        const { type, clientY } = e
        if (type === "dragstart") {
            setStartDragY(clientY)
        }
        else if (type === "dragend") {
            let moveIndex = clientY - startDragY
            if (moveIndex > 0) {
                moveIndex = Math.floor(moveIndex / 34)
            }
            else {
                moveIndex = Math.floor((moveIndex * -1) / 50) * -1
            }
            let newSelected: any = []
            if (moveIndex > 0) {
                newSelected = optionsSelected.map((option: any) => {
                    if (option.order_value === lastIndex) {
                        return {
                            ...option,
                            order_value: lastIndex + moveIndex
                        }
                    }
                    else if (option.order_value <= lastIndex + moveIndex) {
                        return {
                            ...option,
                            order_value: option.order_value - 1
                        }
                    }
                    else {
                        return {
                            ...option
                        }
                    }
                })
            }
            else {
                newSelected = optionsSelected.map((option: any) => {
                    if (option.order_value === lastIndex) {
                        return {
                            ...option,
                            order_value: lastIndex + moveIndex
                        }
                    }
                    else if (option.order_value >= lastIndex + moveIndex) {
                        return {
                            ...option,
                            order_value: option.order_value + 1
                        }
                    }
                    else {
                        return {
                            ...option
                        }
                    }
                })
            }
            setOptionsSelected(newSelected)
            props.onChange({
                target: {
                    name: 'koj_list',
                    value: newSelected
                }
            })
        }
    }

    const handleChangeBcode = (value: String, id: String) => {
        let newSelected = optionsSelected.map((option: any) => {
            if (option.koj_id === id) {
                return {
                    ...option,
                    bcode: value
                }
            }
            return option
        })
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleChangeTarget = (value: String, id: String) => {
        let newSelected = optionsSelected.map((option: any) => {
            if (option.koj_id === id) {
                return {
                    ...option,
                    target: value
                }
            }
            return option
        })
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    return (
        <Grid container spacing={2} className="project-add-koj">
            <Grid item md={3}>
                <b>KOJ</b>
            </Grid>
            <Grid item md={9}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                KOJ Lists
                                <br />
                                <br />
                                {options.map((item: any) => (
                                    <Button
                                        key={item.id}
                                        style={{ marginTop: 5, marginBottom: 5 }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleSelect(item)}
                                        disabled={optionsSelected.map((koj: any) => { return koj.koj_id }).includes(item.id)}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                Selected KOJ
                                <br />
                                <ul className="koj-list">
                                    {optionsSelected.sort((a: any, b: any) => { return a.order_value - b.order_value }).map((item: any) => (
                                        <li key={item.koj_id} draggable onDragStart={(e) => handleDrag(e, item.order_value)} onDragEnd={(e) => handleDrag(e, item.order_value)}>
                                            <DragIndicator />
                                            <span>{item.koj_name}</span>
                                            <TextField size="small" label="Bcode" value={item.bcode} onChange={(e) => handleChangeBcode(e.target.value, item.koj_id)} />{" "}
                                            <TextField size="small" label="Target" value={item.target} onChange={(e) => handleChangeTarget(e.target.value, item.koj_id)} />
                                            {!(item.id) && <Close onClick={() => handleUnselect(item)} />}
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}