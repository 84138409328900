import { createSlice } from '@reduxjs/toolkit'
import { createTransferJob, deleteTransferJob, getTransferJobAvailable } from './transferJob.api'

export interface TransferJobState {
    loading: boolean
}
const initialState: TransferJobState = {
    loading: false
}

export const transferJobSlice = createSlice({
    name: 'transferJob',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getTransferJobAvailable.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTransferJobAvailable.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getTransferJobAvailable.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createTransferJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTransferJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTransferJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteTransferJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTransferJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTransferJob.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default transferJobSlice.reducer