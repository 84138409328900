import { TimerOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography } from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FormNumber from "../../../shared/FormNumber";
import FormText from "../../../shared/FormText";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { controlJobComplete, controlJobHold, controlJobStart, controlTimerAdjust, controlTimerDecrease, controlTimerIncrease, deleteEmployeeJob, editEmployeeJobMultiple, getEmployeeJobByJobnumber } from "../worksheet.api";
import moment from "moment";
import { editJob } from "../../project/project.api";
import axios from "axios";
import { baseUrl, ID_KOJ_QC, ID_KOJ_QC_MRE, ID_KOJ_QC_AIDS } from "../../../state";
import { setLoading } from "../../../containers/containers.reducer";
import { getEmployeeJobRunning } from "../../takenjob/takenjob.api";
import { editEmployeeJob } from "../../report/report.api";
import Swal from "sweetalert2";

export default function WorksheetDetails(props: any) {

    const dispatch = useDispatch()

    const [timerString, setTimerString] = useState("")
    const [values, setValues] = useState<any>({
        notes: "",
        upload_file: "",
        source_job: "",
        pole_extra: 0
    })
    const [jobOnJobnumber, setJobOnJobnumber] = useState<any>([])
    const [localAdjust, setLocalAdjust] = useState(0)
    const [quantityBillable, setQuantityBillable] = useState(0)
    const [quantityMre, setQuantityMre] = useState(0)
    const [quantityCatapult, setQuantityCatapult] = useState(0)
    const [quantityDrawing, setQuantityDrawing] = useState(0)
    const [timeEditMode, setTimeEditMode] = useState(false)
    const [timeEditValue, setTimeEditValue] = useState("0")

    useEffect(() => {
        var startMillis = 0
        var i: any = undefined
        setValues({
            notes: props.data.notes,
            upload_file: props.data.upload_file,
            source_job: props.data.source_job,
            pole_extra: props.data.pole_extra || 0
        })
        setQuantityBillable(props.data.quantity_billable || 0)
        setQuantityMre(props.data.quantity_mre || 0)
        setQuantityCatapult(props.data.quantity_catapult || 0)
        setQuantityDrawing(props.data.quantity_drawing || 0)
        if ([ID_KOJ_QC, ID_KOJ_QC_MRE, ID_KOJ_QC_AIDS].includes(props.data.koj_id)) {
            dispatch<any>(getEmployeeJobByJobnumber(props.data.jobnumber_id))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setJobOnJobnumber(response.data.data.map((data: any) => {
                            return {
                                id: data.id,
                                employee_name: data.employee_name,
                                quantity_get: data.quantity_get,
                                koj_id: data.koj_id,
                                koj_name: data.koj_name,
                                fault_quantity: data.fault_quantity,
                                fault_note: data.fault_note
                            }
                        }))
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
        dispatch(setLoading(true))
        axios.get(baseUrl + '/time')
            .then((response) => {
                startMillis = new Date().getTime()
                const timing = () => {
                    let millisStart = new Date(props.data.ts_start).getTime() - ((props.data.minutes_adjust + localAdjust) * 60000)
                    let millisNow = new Date(response.data.data).getTime() + (new Date().getTime() - startMillis)
                    let millisPause = props.data.job_pause_value * 1000
                    setTimerString(
                        Math.floor((millisNow - (millisStart + millisPause)) / 3600000) + moment(millisNow - (millisStart + millisPause)).format(':mm:ss')
                    )
                }
                if (props.data.ts_start) {
                    if (props.data.ts_stop) {
                        let millisStart = new Date(props.data.ts_start).getTime() - ((props.data.minutes_adjust + localAdjust) * 60000)
                        let millisStop = new Date(props.data.ts_stop).getTime()
                        let millisPause = props.data.job_pause_value * 1000
                        setTimerString(
                            Math.floor((millisStop - millisStart - millisPause) / 3600000) + moment(millisStop - millisStart - millisPause).format(':mm:ss')
                        )
                    }
                    else {
                        if (props.data.job_pause_status === 1) {
                            let millisStart = new Date(props.data.ts_start).getTime() - ((props.data.minutes_adjust + localAdjust) * 60000)
                            let millisNow = new Date(props.data.job_pause_ts_created).getTime()
                            let millisPause = props.data.job_pause_value * 1000
                            setTimerString(
                                Math.floor((millisNow - (millisStart + millisPause)) / 3600000) + moment(millisNow - (millisStart + millisPause)).format(':mm:ss')
                            )
                        }
                        else {
                            timing()
                            i = setInterval(() => {
                                timing()
                            }, 1000)
                        }
                    }
                }
                else {
                    setTimerString("00:00:00")
                }
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
        return () => {
            clearInterval(i)
        }
    }, [localAdjust])

    useEffect(() => {
        setTimeEditValue(timerString.substring(0, timerString.lastIndexOf(":")))
    }, [timeEditMode])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = () => {
        dispatch<any>(editJob({
            ...values,
            id: props.data.job_id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch<any>(editEmployeeJob({
                        id: props.data.id,
                        quantity_billable: quantityBillable,
                        quantity_mre: quantityMre,
                        quantity_catapult: quantityCatapult,
                        quantity_drawing: quantityDrawing
                    }))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("Detail successfully updated")
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleCancel = () => {
        dispatch<any>(deleteEmployeeJob(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Job succesfully deleted")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleStart = () => {
        dispatch<any>(controlJobStart(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data.status === true) {
                        notificationSuccess(response.data.message)
                            .then(() => {
                                dispatch<any>(getEmployeeJobRunning())
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleHold = () => {
        dispatch<any>(controlJobHold(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Job succesfully holded")
                        .then(() => {
                            dispatch<any>(getEmployeeJobRunning())
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleComplete = () => {
        dispatch<any>(controlJobComplete(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Job succesfully completed")
                        .then(() => {
                            dispatch<any>(getEmployeeJobRunning())
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleChangeFault = (e: any) => {
        setJobOnJobnumber(jobOnJobnumber.map((job: any) => {
            if (job.id === e.target.id) {
                return {
                    ...job,
                    [e.target.name]: e.target.value
                }
            }
            return job
        }))
    }

    const handleCompleteQc = () => {
        handleComplete()
        dispatch<any>(editEmployeeJobMultiple({
            data_list: jobOnJobnumber
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status !== 200) {
                    notificationError(response)
                }
            })
    }

    const handleIncrease = () => {
        setLocalAdjust(localAdjust + 1)
        dispatch<any>(controlTimerIncrease(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch<any>(getEmployeeJobRunning())
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleDecrease = () => {
        setLocalAdjust(localAdjust - 1)
        dispatch<any>(controlTimerDecrease(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch<any>(getEmployeeJobRunning())
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleAdjust = () => {
        if (timeEditMode) {
            const originalTimeArr = timerString.split(":")
            const originalHours = parseInt(originalTimeArr[0])
            const originalMinutes = parseInt(originalTimeArr[1])
            const newTimerArr = timeEditValue.split(":")
            const newHours = parseInt(newTimerArr[0])
            const newMinutes = parseInt(newTimerArr[1])
            const adjustMinutes = ((newHours - originalHours) * 60) + (newMinutes - originalMinutes)
            dispatch<any>(controlTimerAdjust({ id: props.data.id, value: localAdjust + adjustMinutes }))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        dispatch<any>(getEmployeeJobRunning())
                        setLocalAdjust(localAdjust + adjustMinutes)
                        setTimeEditMode(false)
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
        else {
            setTimeEditMode(true)
        }
    }

    const handleChangeMinutes = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        const valueMinutes = value.split(":")[1]
        if (value.split(":").length === 2 && parseInt(valueMinutes) < 60) {
            setTimeEditValue(e.target.value)
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Invalid Input",
                text: "Please input time in HH:MM format"
            })
        }
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form>
                    <Divider />
                    <Typography align="center" variant="h3">
                        <TimerOutlined fontSize="large" />{" "}
                        {timeEditMode
                            ? <Box>
                                <TextField
                                    variant="standard"
                                    size="small"
                                    inputProps={{ style: { textAlign: "right" } }}
                                    InputProps={{
                                        endAdornment: <span style={{ marginBottom: 2 }}>{timerString.substring(timerString.lastIndexOf(":"))}</span>
                                    }}
                                    value={timeEditValue}
                                    onChange={handleChangeMinutes}
                                />
                            </Box>
                            : <span>
                                {timerString}
                            </span>
                        }
                    </Typography>
                    <br />
                    <Box textAlign={"center"}>
                        <Button variant="outlined" onClick={handleDecrease}>-1 Minute</Button>
                        <Button variant="outlined" onClick={handleIncrease}>+1 Minute</Button>
                        <Button variant="contained" onClick={handleAdjust}>{timeEditMode ? "Save" : "Adjust"}</Button>
                    </Box>
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Qty" value={props.data.quantity_get} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Note" name="notes" value={values.notes} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Upload File" name="upload_file" value={values.upload_file} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Source Job" name="source_job" value={values.source_job} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Extra Pole" name="pole_extra" value={values.pole_extra} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    {([ID_KOJ_QC, ID_KOJ_QC_MRE, ID_KOJ_QC_AIDS].includes(props.data.koj_id))
                        && (
                            <Fragment>
                                <FormNumber label="Billable Qty" value={quantityBillable} onChange={(e: any) => setQuantityBillable(parseInt(e.target.value))} />
                                <br />
                                <Divider />
                                <br />
                                <FormNumber label="Mre Qty" value={quantityMre} onChange={(e: any) => setQuantityMre(parseInt(e.target.value))} />
                                <br />
                                <Divider />
                                <br />
                                <FormNumber label="Katapult Qty" value={quantityCatapult} onChange={(e: any) => setQuantityCatapult(parseInt(e.target.value))} />
                                <br />
                                <Divider />
                                <br />
                                <FormNumber label="Drawing Qty" value={quantityDrawing} onChange={(e: any) => setQuantityDrawing(parseInt(e.target.value))} />
                                <br />
                            </Fragment>
                        )}
                    <Grid item md={6}>
                        <div className="form-footer">
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit Detail
                            </Button>
                        </div>
                    </Grid>
                    <br />
                    <Divider />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleCancel}
                                disabled={props.data.ts_start !== null}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            {([ID_KOJ_QC, ID_KOJ_QC_MRE, ID_KOJ_QC_AIDS].includes(props.data.koj_id))
                                ? (
                                    <div className="form-footer">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                !(
                                                    props.data.job_pause_status === 1 || props.data.ts_start === null
                                                )
                                                || props.data.ts_stop !== null
                                            }
                                            onClick={handleStart}
                                        >
                                            Start
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            disabled={
                                                props.data.job_pause_status === 1 || props.data.ts_start === null || props.data.ts_stop !== null
                                            }
                                            onClick={handleHold}
                                        >
                                            Hold
                                        </Button>
                                    </div>
                                )
                                : (
                                    <div className="form-footer">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                !(
                                                    props.data.job_pause_status === 1 || props.data.ts_start === null
                                                ) ||
                                                props.data.ts_stop !== null
                                            }
                                            onClick={handleStart}
                                        >
                                            Start
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            disabled={
                                                props.data.job_pause_status === 1 || props.data.ts_start === null || props.data.ts_stop !== null
                                            }
                                            onClick={handleHold}
                                        >
                                            Hold
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            disabled={
                                                props.data.ts_start === null
                                                || props.data.ts_stop !== null
                                            }
                                            onClick={handleComplete}
                                        >
                                            Completed
                                        </Button>
                                    </div>
                                )
                            }
                        </Grid>
                    </Grid>
                    {([ID_KOJ_QC, ID_KOJ_QC_MRE, ID_KOJ_QC_AIDS].includes(props.data.koj_id))
                        && (
                            <Fragment>
                                <br />
                                <Divider />
                                <br />
                                <div className="custom-table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Worker</th>
                                                <th>QTY</th>
                                                <th>KOJ</th>
                                                <th>Fault Point</th>
                                                <th>Fault Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {jobOnJobnumber.filter((data: any) => (![ID_KOJ_QC, ID_KOJ_QC_MRE, ID_KOJ_QC_AIDS].includes(data.koj_id))).map((data: any) => (
                                                <tr key={data.id}>
                                                    <td>{data.employee_name}</td>
                                                    <td>{data.quantity_get}</td>
                                                    <td>{data.koj_name}</td>
                                                    <td>
                                                        <TextField disabled={props.data.ts_stop !== null} id={data.id} size="small" type="number" name="fault_quantity" value={data.fault_quantity} onChange={handleChangeFault} />
                                                    </td>
                                                    <td>
                                                        <TextField disabled={props.data.ts_stop !== null} id={data.id} size="small" type="text" name="fault_note" value={data.fault_note} onChange={handleChangeFault} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <Divider />
                                <br />
                                <div className="form-footer">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        disabled={
                                            props.data.ts_start === null
                                            || props.data.ts_stop !== null
                                        }
                                        onClick={handleCompleteQc}
                                    >
                                        Completed
                                    </Button>
                                </div>
                            </Fragment>
                        )
                    }
                </form>
            </CardContent>
        </Card>
    )
}