import { Grid, TextField } from "@mui/material";

export default function FormClockRange(props: any) {
    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <b>{props.label}</b>
            </Grid>
            <Grid item md={9}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="time"
                            name={props.startName}
                            value={props.startValue}
                            onChange={props.onChange}
                            required={props.required}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="time"
                            name={props.endName}
                            value={props.endValue}
                            onChange={props.onChange}
                            required={props.required}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}