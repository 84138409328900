import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../../shared/FormAutocomplete"
import FormDate from "../../../../shared/FormDate"
import FormNumber from "../../../../shared/FormNumber"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { editOvertime } from "../overtime.api"

export default function OvertimeApprovalHead(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        id: "",
        name: "",
        minutes: "",
        notes: "",
        head_status_id: "",
        minutes_approval: "",
        notes_approval: "",
        multiple: "",
    })

    useEffect(() => {
        setValues({
            id: props.data.id,
            name: moment(props.data.name).format("YYYY-MM-DD"),
            minutes: props.data.minutes,
            notes: props.data.notes,
            head_status_id: props.data.head_status_id,
            minutes_approval: props.data.minutes_approval || props.data.minutes,
            notes_approval: props.data.notes_approval,
            multiple: props.data.multiple,
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editOvertime(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit overtime")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormDate label="Overtime Date" name="name" value={values.name} onChange={handleChangeValues} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Request Minutes" name="minutes" value={values.minutes} onChange={handleChangeValues} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} disabled />
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/status/approval" label="Status" name="head_status_id" value={values.head_status_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Approved Minutes" name="minutes_approval" value={values.minutes_approval} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Multiple" name="multiple" value={values.multiple} onChange={handleChangeValues} step={0.5} />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Rejection Notes" name="notes_approval" value={values.notes_approval} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Overtime
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}