import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import FormAutocomplete from "../../../shared/FormAutocomplete";
import FormText from "../../../shared/FormText";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { createProject } from "../project.api";
import { getEmployeeDetail } from "../../login/login.api";
import CustomModal from "../../../shared/CustomModal";
import Team from "../../master/team/component/Team";
import Swal from "sweetalert2";

export default function ProjectAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        requestor_id: "",
        submittal_id: "",
        district_id: "",
        team_id: "",
        bcode: ""
    })
    const [modalTeam, setModalTeam] = useState(false)

    useEffect(() => {
        dispatch(setTitle("Project"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Project",
                url: "/project"
            },
            {
                name: "Create Project",
                url: "/project/add"
            }
        ]))
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = async (e: any) => {
        setModalTeam(false)
        if (e) {
            e.preventDefault()
        }
        try {
            const responseEmployeeDetail = await dispatch<any>(getEmployeeDetail()).unwrap()
            const employeeDetail = responseEmployeeDetail.data.data
            const employeeDistrictIds = employeeDetail.team_district_ids.split(',')
            console.log(employeeDistrictIds)
            if (employeeDistrictIds.includes(values.district_id)) {
                const response = await dispatch<any>(createProject(values)).unwrap()
                if (response.status === 200) {
                    notificationSuccess("success create new project")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid District Selection',
                    text: 'The district you have chosen is not correct. Please update the selected district in your team.',
                    showConfirmButton: true,
                    showCancelButton: true
                })
                    .then(response => {
                        if (response.isConfirmed) {
                            setModalTeam(true)
                        }
                    })
            }
        } catch (error) {
            notificationError(error)
        }
    }

    return (
        <Container>
            <CustomModal
                open={modalTeam}
                onClose={(changed: boolean) => changed ? handleSubmit(undefined) : setModalTeam(false)}
                title="Edit Team"
                component={
                    <Fragment>
                        <Team />
                        <br />
                    </Fragment>
                }
            />
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Project
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Project
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/requestor" label="Requestor" name="requestor_id" value={values.requestor_id} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/submittal" label="Submittal" name="submittal_id" value={values.submittal_id} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/district" label="District" name="district_id" value={values.district_id} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/team" label="Team" name="team_id" value={values.team_id} onChange={handleChangeValues} required />
                        <br />
                        {/* <Divider />
                        <br />
                        <FormText label="Bcode" name="bcode" value={values.bcode} onChange={handleChangeValues} />
                        <br /> */}
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}