import { createSlice } from '@reduxjs/toolkit'
import { getBillDefault, getJobNumberAndKojs, getProjectOptions } from './invoiceStatus.api'

export interface InvoiceStatusState {
    loading: boolean,
    projectList: any,
    jobnumberList: any
}
const initialState: InvoiceStatusState = {
    loading: false,
    projectList: [],
    jobnumberList: []
}

export const invoiceStatusSlice = createSlice({
    name: 'invoiceStatus',
    initialState,
    reducers: {
        clearJobnumberList: (state) => {
            state.jobnumberList = {};
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getProjectOptions.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProjectOptions.fulfilled, (state, action) => {
            state.projectList = action.payload.data.data
            state.loading = false
        })
        builder.addCase(getProjectOptions.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getJobNumberAndKojs.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getJobNumberAndKojs.fulfilled, (state, action) => {
            state.jobnumberList = action.payload.data.data
            state.loading = false
        })
        builder.addCase(getJobNumberAndKojs.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getBillDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBillDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getBillDefault.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    clearJobnumberList
} = invoiceStatusSlice.actions;

export default invoiceStatusSlice.reducer