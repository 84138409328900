import { Container } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import CustomTable from "../../../shared/CustomTable";

export default function ReportDaily() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Bill Job"))
        dispatch(setIsShowBack(true))
        dispatch(setBreadcumbs([
            {
                name: "Bill Job",
                url: "/bill"
            },
            {
                name: "History Bill",
                url: "/bill/history"
            }
        ]))
    }, [])

    return (
        <Container maxWidth='xl'>
            <CustomTable
                url={"/project/jobnumber/billed"}
                filterDate={true}
                columns={[
                    {
                        name: "Done By",
                        key: "project_team_name"
                    },
                    {
                        name: "Job Number",
                        key: "number_job"
                    },
                    {
                        name: "Requestor",
                        key: "project_requestor_name"
                    },
                    {
                        name: "Submittal",
                        key: "project_submittal_name"
                    },
                    {
                        name: "District",
                        key: "project_district_name"
                    },
                    {
                        name: "Project",
                        key: "project_name"
                    },
                    {
                        name: "Job Received",
                        key: "date_received",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.date_received ? moment(props.data.date_received).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Due Date",
                        key: "date_due",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.date_due ? moment(props.data.date_due).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Source Job",
                        key: "?"
                    },
                    {
                        name: "QTY Request",
                        key: "quantity_request"
                    },
                    {
                        name: "Req Number",
                        key: "number_auto_request"
                    },
                    {
                        name: "Rev Number",
                        key: "number_auto_revision"
                    },
                    {
                        name: "Act Number",
                        key: "number_auto_activity"
                    },
                    {
                        name: "Billable QTY",
                        key: "quantity_billable_total"
                    },
                    {
                        name: "MRE QTY",
                        key: "quantity_mre_total"
                    },
                    {
                        name: "Katapult QTY",
                        key: "quantity_catapult_total"
                    },
                    {
                        name: "Drawing QTY",
                        key: "quantity_drawing_total"
                    },
                    {
                        name: "PLA QTY",
                        key: "pla"
                    },
                    {
                        name: "New",
                        key: "pole_new"
                    },
                    {
                        name: "Extra Pole",
                        key: "pole_extra"
                    },
                    {
                        name: "Already Created",
                        key: "pole_already_created"
                    },
                    {
                        name: "New PLA QTY",
                        key: "pole_new_pla"
                    },
                    {
                        name: "To UG",
                        key: "pole_to_ug"
                    },
                    {
                        name: "Missing Fielding",
                        key: "pole_missing_fielding"
                    },
                    {
                        name: "Revision QTY",
                        key: "quantity_revision"
                    },
                    {
                        name: "Job Minutes",
                        key: "job_calculation_total_minutes"
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Completed Date",
                        key: "date_completed_final",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.date_completed_final ? moment(props.data.date_completed_final).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Status",
                        key: "status_name"
                    },
                    {
                        name: "Bill",
                        key: "is_bill"
                    },
                    {
                        name: "Type",
                        key: "job_type_name"
                    }
                ]}
                columnHidden={[
                    "Job Number",
                    "District",
                    "Job Received",
                    "Due Date",
                    "Source Job",
                    "QTY Request",
                    "Req Number",
                    "Rev Number",
                    "Act Number",
                    "New",
                    "Extra Pole",
                    "Already Created",
                    "New PLA QTY",
                    "To UG",
                    "Missing Fielding",
                    "Revision QTY",
                    "Job Minutes",
                    "Notes",
                    "Completed Date",
                    "Status",
                    "Bill",
                    "Type"
                ]}
                export={true}
                exportUrl={"/export/jobnumber"}
                selectRow={true}
            />
        </Container>
    )
}