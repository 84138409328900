import { createSlice } from '@reduxjs/toolkit'
import { createBilled, getBillListById, getTeamList } from './bill.api'

export interface BillState {
    loading: boolean,
    teamSelected: any
}
const initialState: BillState = {
    loading: false,
    teamSelected: {}
}

export const billSlice = createSlice({
    name: 'bill',
    initialState,
    reducers: {
        setTeamSelected: (state, action) => {
            state.teamSelected = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getBillListById.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBillListById.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getBillListById.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createBilled.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBilled.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBilled.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getTeamList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTeamList.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getTeamList.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setTeamSelected
} = billSlice.actions;

export default billSlice.reducer