import { createSlice } from '@reduxjs/toolkit'
import { createKoj, deleteKoj, editKoj } from './koj.api'

export interface KojState {
    loading: boolean
}
const initialState: KojState = {
    loading: false
}

export const kojSlice = createSlice({
    name: 'koj',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createKoj.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createKoj.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createKoj.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteKoj.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteKoj.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteKoj.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editKoj.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editKoj.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editKoj.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default kojSlice.reducer