import { ExpandLess, ExpandMore, MoreVert, Search, ViewColumn } from "@mui/icons-material";
import { Button, Card, CardContent, Checkbox, Container, Divider, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import CustomModal from "../../../shared/CustomModal";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { activeJobNumber, deleteJob, deleteJobNumber, editJob, editJobNumber, getJobNumberListWithPaginationsByProject, getProjectSingle } from "../project.api";
import JobNumberAdd from "../component/detail/JobNumberAdd";
import FormInlineAutocomplete from "../../../shared/FormInlineAutocomplete"
import FormInlineCheck from "../../../shared/FormInlineCheck";
import moment from "moment";
import JobNumberEditKoj from "../component/detail/JobNumberEditKoj";
import JobNumberFormList from "../component/detail/JobNumberFormList";

export default function JobNumberArchive(props: any) {

    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const [popAnchorShowColumn, setPopAnchorShowColumn] = useState<HTMLButtonElement | null>(null);
    const [popAnchorAction, setPopAnchorAction] = useState<HTMLButtonElement | null>(null);
    const [popAnchorActionChild, setPopAnchorActionChild] = useState<HTMLButtonElement | null>(null);
    const [columnHidden, setColumnHidden] = useState<any>([
        "Requestor",
        "District",
        "Job Received",
        "Source Job",
        "New",
        "Extra Pole",
        "Already Created",
        "New PLA QTY",
        "To UG",
        "Missing Fielding",
        "Revision QTY"
    ])
    const [projectData, setProjectData] = useState<any>({})
    const [dataList, setDataList] = useState<any>([])
    const [dataSelected, setDataSelected] = useState<any>({})
    const [dataSelectedChild, setDataSelectedChild] = useState<any>({})
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEditKoj, setModalEditKoj] = useState(false)
    const [expandedIds, setExpandedIds] = useState<any>([])
    const [isEdit, setIsEdit] = useState(false)
    const [isEditChild, setIsEditChild] = useState(false)
    const [searchWord, setSearchWord] = useState("")

    const handleExpand = (id: string) => {
        if (expandedIds.includes(id)) {
            setExpandedIds(expandedIds.filter((ids: string) => ids !== id))
        }
        else {
            setExpandedIds([...expandedIds, id])
        }
    }

    const columns = [
        {
            name: "Order Number",
            key: "number_order",
            customRender: (props: any) => {
                return (
                    <td>
                        <IconButton onClick={() => handleExpand(props.data.id)}>
                            {expandedIds.includes(props.data.id) ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <span>
                            {props.data.number_order}
                        </span>
                    </td>
                )
            },
            form: {
                type: "text"
            }
        },
        {
            name: "Team",
            key: "project_team_name"
        },
        {
            name: "Job Number",
            key: "number_job"
        },
        {
            name: "Requestor",
            key: "project_requestor_name"
        },
        {
            name: "Submittal",
            key: "project_submittal_name"
        },
        {
            name: "District",
            key: "project_district_name"
        },
        {
            name: "Project",
            key: "project_name"
        },
        {
            name: "Job Received",
            customRender: (props: any) => {
                return (
                    <td>
                        <span>
                            {props.data.date_received ? moment(props.data.date_received).format('DD-MM-YYYY') : '-'}
                        </span>
                    </td>
                )
            }
        },
        {
            name: "Due Date",
            key: "date_due",
            customRender: (props: any) => {
                return (
                    <td>
                        <span>
                            {props.data.date_due ? moment(props.data.date_due).format('DD-MM-YYYY') : '-'}
                        </span>
                    </td>
                )
            },
            form: {
                type: "date"
            }
        },
        {
            name: "Source Job",
            key: "?",
            form: {
                type: "text"
            }
        },
        {
            name: "QTY Request",
            key: "quantity_request",
            form: {
                type: "number"
            }
        },
        {
            name: "Req Number",
            key: "number_auto_request",
            form: {
                type: "number"
            }
        },
        {
            name: "Rev Number",
            key: "number_auto_revision",
            form: {
                type: "number"
            }
        },
        {
            name: "Act Number",
            key: "number_auto_activity",
            form: {
                type: "number"
            }
        },
        {
            name: "Billable QTY",
            key: "quantity_billable_total"
        },
        {
            name: "MRE QTY",
            key: "quantity_mre_total"
        },
        {
            name: "Katapult QTY",
            key: "quantity_catapult_total"
        },
        {
            name: "Drawing QTY",
            key: "quantity_drawing_total"
        },
        {
            name: "New",
            key: "pole_new",
            form: {
                type: "number"
            }
        },
        {
            name: "Extra Pole",
            key: "pole_extra",
            form: {
                type: "number"
            }
        },
        {
            name: "Already Created",
            key: "pole_already_created",
            form: {
                type: "number"
            }
        },
        {
            name: "PLA QTY",
            key: "pla",
            form: {
                type: "number"
            }
        },
        {
            name: "New PLA QTY",
            key: "pole_new_pla",
            form: {
                type: "number"
            }
        },
        {
            name: "To UG",
            key: "pole_to_ug",
            form: {
                type: "number"
            }
        },
        {
            name: "Missing Fielding",
            key: "pole_missing_fielding",
            form: {
                type: "number"
            }
        },
        {
            name: "Revision QTY",
            key: "quantity_revision",
            form: {
                type: "number"
            }
        },
        {
            name: "Job Minutes",
            key: "job_calculation_total_minutes"
        },
        {
            name: "Notes",
            key: "notes",
            form: {
                type: "text"
            }
        },
        {
            name: "Completed Date",
            key: "date_completed_final",
            customRender: (props: any) => {
                return (
                    <td>
                        <span>
                            {props.data.date_completed_final ? moment(props.data.date_completed_final).format('DD-MM-YYYY') : '-'}
                        </span>
                    </td>
                )
            }
        },
        {
            name: "Billed",
            key: "billed",
            form: {
                type: "list"
            },
            customRender: (props: any) => {
                let values = (
                    props.data.billed
                        ? props.data.billed.includes(',')
                            ? props.data.billed.split(',')
                            : [props.data.billed]
                        : []
                )
                return (
                    <td>
                        <ul>
                            {values.map((value: string) => (
                                <li key={value}>
                                    {value}
                                </li>
                            ))}
                        </ul>
                    </td>
                )
            }
        },
        {
            name: "Status",
            key: "status_name",
            form: {
                type: "autocomplete",
                key: "status_id",
                url: "/status/jobnumber"
            }
        },
        {
            name: "Bill",
            key: "is_bill",
            customRender: (props: any) => {
                return (
                    <td>
                        <FormInlineCheck label="Bill" value={props.data.is_bill} disabled />
                    </td>
                )
            },
            form: {
                type: "check"
            }
        }
    ]

    const columnsChild = [
        {
            name: "Order Number",
            key: "jobnumber_number_order"
        },
        {
            name: "Team",
            key: "jobnumber_project_team_name"
        },
        {
            name: "Job Number",
            key: "jobnumber_number_job"
        },
        {
            name: "Requestor",
            key: "jobnumber_project_requestor_name"
        },
        {
            name: "Submittal",
            key: "jobnumber_project_submittal_name"
        },
        {
            name: "District",
            key: "jobnumber_project_district_name"
        },
        {
            name: "KOJ",
            key: "koj_name",
            customRender: (props: any) => {
                return (
                    <td>
                        <span style={{ backgroundColor: props.data.koj_color }}>{props.data.koj_name}</span>
                    </td>
                )
            }
        },
        {
            name: "Status KOJ",
            key: "job_calculation_status"
        },
        {
            name: "Project",
            key: "jobnumber_project_name"
        },
        {
            name: "Job Received",
            customRender: (props: any) => {
                return (
                    <td>
                        <b>Job Received</b><br />
                        {props.data.jobnumber_date_received ? moment(props.data.jobnumber_date_received).format('DD-MM-YYYY') : '-'}
                    </td>
                )
            }
        },
        {
            name: "Due Date",
            customRender: (props: any) => {
                return (
                    <td>
                        <b>Due Date</b><br />
                        {props.data.jobnumber_date_due ? moment(props.data.jobnumber_date_due).format('DD-MM-YYYY') : '-'}
                    </td>
                )
            }
        },
        {
            name: "Source Job",
            key: "source_job",
            form: {
                type: "text"
            }
        },
        {
            name: "QTY Request",
            key: "quantity_request",
            form: {
                type: "number"
            }
        },
        {
            name: "QTY Transfered",
            key: "job_calculation_total_transfer"
        },
        {
            name: "QTY Get",
            key: "job_calculation_total_get"
        },
        {
            name: "QTY Available",
            key: "job_calculation_total_available"
        },
        {
            name: "Req Number",
            key: "jobnumber_number_auto_request"
        },
        {
            name: "Rev Number",
            key: "jobnumber_number_auto_revision"
        },
        {
            name: "Act Number",
            key: "number_auto_activity"
        },
        {
            name: "New",
            key: "pole_new",
            form: {
                type: "number"
            }
        },
        {
            name: "Extra Pole",
            key: "pole_extra",
            form: {
                type: "number"
            }
        },
        {
            name: "Already Created",
            key: "pole_already_created",
            form: {
                type: "number"
            }
        },
        {
            name: "New PLA QTY",
            key: "pole_new_pla",
            form: {
                type: "number"
            }
        },
        {
            name: "To UG",
            key: "pole_to_ug",
            form: {
                type: "number"
            }
        },
        {
            name: "Missing Fielding",
            key: "pole_missing_fielding",
            form: {
                type: "number"
            }
        },
        {
            name: "Revision QTY",
            key: "quantity_revision",
            form: {
                type: "number"
            }
        },
        {
            name: "Job Minutes",
            key: "job_calculation_total_minutes",
            form: {
                type: "number"
            }
        },
        {
            name: "Notes",
            key: "notes",
            form: {
                type: "text"
            }
        },
        {
            name: "Completed Date",
            key: "job_calculation_ts_completed",
            customRender: (props: any) => {
                return (
                    <td>
                        <b>Completed Date</b><br />
                        {
                            props.data.job_calculation_status.includes('Completed')
                                ? (
                                    props.data.job_calculation_ts_completed
                                        ? moment(props.data.job_calculation_ts_completed).format('DD-MM-YYYY')
                                        : '-'
                                )
                                : '-'
                        }
                    </td>
                )
            }
        },
        {
            name: "Status",
            key: "jobnumber_status_name"
        },
        {
            name: "",
            key: "is_billed",
            customRender: (props: any) => {
                return (
                    <td>
                        <FormInlineCheck label="Billed" value={props.data.is_billed} disabled />
                    </td>
                )
            },
        }
    ]

    useEffect(() => {
        let id = searchParams.get('id')
        if (id) {
            dispatch<any>(getProjectSingle(id))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setProjectData(response.data.data[0])
                        dispatch(setTitle(response.data.data[0].name + " Job Number"))
                        dispatch(setIsShowBack(true))
                        dispatch(setBreadcumbs([]))
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (props.id || searchParams.get('id')) {
            getDataList()
        }
    }, [props.id])

    const getDataList = () => {
        dispatch<any>(getJobNumberListWithPaginationsByProject({
            projectid: searchParams.get('id'),
            id: props.id,
            search: searchWord,
            is_archive: 1
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setDataList(response.data.data)
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleChangeColumnHidden = (e: any, name: String) => {
        if (e.target.checked) {
            setColumnHidden(columnHidden.filter((item: String) => item !== name))
        }
        else {
            setColumnHidden([...columnHidden, name])
        }
    }

    const handleDelete = () => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteJobNumber(dataSelected.id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete jobNumber")
                                setPopAnchorAction(null)
                                getDataList()
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    const handleActive = () => {
        dispatch<any>(activeJobNumber(dataSelected.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success active jobNumber")
                    setPopAnchorAction(null)
                    getDataList()
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleDeleteChild = () => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteJob(dataSelectedChild.id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete job")
                                setPopAnchorActionChild(null)
                                getDataList()
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    const handleCloseModalAdd = (isReload: boolean) => {
        setModalAdd(false)
        if (isReload) {
            getDataList()
        }
    }

    const handleCloseModalEditKoj = (isReload: boolean) => {
        setModalEditKoj(false)
        setPopAnchorAction(null)
        if (isReload) {
            getDataList()
        }
    }

    const handleChangeDataSelected = (e: any) => {
        const { name, value } = e.target
        setDataSelected({
            ...dataSelected,
            [name]: value
        })
    }

    const handleChangeDataSelectedChild = (e: any) => {
        const { name, value } = e.target
        setDataSelectedChild({
            ...dataSelectedChild,
            [name]: value
        })
    }

    const handleUpdate = () => {
        dispatch<any>(editJobNumber({
            id: dataSelected.id,
            ts_created: dataSelected.ts_created ? dataSelected.ts_created : undefined,
            submittal_id: dataSelected.submittal_id ? dataSelected.submittal_id : undefined,
            quantity_request: dataSelected.quantity_request ? dataSelected.quantity_request : undefined,
            date_received: dataSelected.date_received ? dataSelected.date_received : undefined,
            number_job: dataSelected.number_job ? dataSelected.number_job : undefined,
            date_due: dataSelected.date_due ? dataSelected.date_due : undefined,
            pole_new: dataSelected.pole_new ? dataSelected.pole_new : undefined,
            pole_extra: dataSelected.pole_extra ? dataSelected.pole_extra : undefined,
            pole_already_created: dataSelected.pole_already_created ? dataSelected.pole_already_created : undefined,
            pole_new_pla: dataSelected.pole_new_pla ? dataSelected.pole_new_pla : undefined,
            pole_to_ug: dataSelected.pole_to_ug ? dataSelected.pole_to_ug : undefined,
            pole_missing_fielding: dataSelected.pole_missing_fielding ? dataSelected.pole_missing_fielding : undefined,
            quantity_revision: dataSelected.quantity_revision ? dataSelected.quantity_revision : undefined,
            notes: dataSelected.notes ? dataSelected.notes : undefined,
            billed: dataSelected.billed ? dataSelected.billed : undefined,
            status_id: dataSelected.status_id ? dataSelected.status_id : undefined,
            number_auto_request: dataSelected.number_auto_request ? dataSelected.number_auto_request : undefined,
            number_auto_revision: dataSelected.number_auto_revision ? dataSelected.number_auto_revision : undefined,
            number_auto_activity: dataSelected.number_auto_activity ? dataSelected.number_auto_activity : undefined,
            is_bill: dataSelected.is_bill ? dataSelected.is_bill : 0,
            pla: dataSelected.pla ? dataSelected.pla : undefined
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit job number")
                        .then(() => {
                            setIsEdit(false)
                            setDataSelected({})
                            getDataList()
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleUpdateChild = () => {
        dispatch<any>(editJob({
            id: dataSelectedChild.id,
            ts_created: dataSelectedChild.ts_created,
            jobnumber_id: dataSelectedChild.jobnumber_id,
            koj_id: dataSelectedChild.koj_id,
            order_value: dataSelectedChild.order_value,
            bcode: dataSelectedChild.bcode,
            koj_status_id: dataSelectedChild.koj_status_id,
            source_job: dataSelectedChild.source_job,
            quantity_request: dataSelectedChild.quantity_request,
            pole_new: dataSelectedChild.pole_new,
            pole_extra: dataSelectedChild.pole_extra,
            pole_already_created: dataSelectedChild.pole_already_created,
            pole_new_pla: dataSelectedChild.pole_new_pla,
            pole_to_ug: dataSelectedChild.pole_to_ug,
            pole_missing_fielding: dataSelectedChild.pole_missing_fielding,
            quantity_revision: dataSelectedChild.quantity_revision,
            notes: dataSelectedChild.notes,
            date_completed: dataSelectedChild.date_completed,
            status_id: dataSelectedChild.status_id,
            job_calculation_total_minutes: dataSelectedChild.job_calculation_total_minutes,
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit job")
                        .then(() => {
                            setIsEditChild(false)
                            setDataSelectedChild({})
                            getDataList()
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleChangeSearch = (e: any) => {
        let { value } = e.target
        setSearchWord(value)
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        getDataList()
    }

    return (
        <Container maxWidth="xl">
            <Popover
                open={Boolean(popAnchorShowColumn)}
                anchorEl={popAnchorShowColumn}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={() => setPopAnchorShowColumn(null)}
            >
                <List>
                    {columns.map((column: any, i: number) => (
                        <ListItem
                            key={i}
                            disablePadding
                            style={{ paddingRight: 20 }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={!columnHidden.includes(column.name)}
                                    onChange={e => handleChangeColumnHidden(e, column.name)}
                                />
                            </ListItemIcon>
                            <ListItemText>{column.name}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Popover>
            <Popover
                open={Boolean(popAnchorAction)}
                anchorEl={popAnchorAction}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorAction(null)}
            >
                <List>
                    <ListItemButton onClick={() => { setIsEdit(true); setPopAnchorAction(null) }}>
                        <ListItemText>Edit Job Number</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => setModalEditKoj(true)}>
                        <ListItemText>Edit KOJ</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleActive}>
                        <ListItemText>Active</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleDelete}>
                        <ListItemText>Delete</ListItemText>
                    </ListItemButton>
                </List>
            </Popover>
            <Popover
                open={Boolean(popAnchorActionChild)}
                anchorEl={popAnchorActionChild}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorActionChild(null)}
            >
                <List>
                    <ListItemButton onClick={() => { setIsEditChild(true); setPopAnchorActionChild(null) }}>
                        <ListItemText>Edit Job</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleDeleteChild}>
                        <ListItemText>Delete</ListItemText>
                    </ListItemButton>
                </List>
            </Popover>
            <CustomModal
                open={modalAdd}
                onClose={handleCloseModalAdd}
                title="Add JobNumber"
                component={
                    <JobNumberAdd onClose={handleCloseModalAdd} data={projectData} />
                }
            />
            <CustomModal
                open={modalEditKoj}
                onClose={handleCloseModalEditKoj}
                title="Edit KOJ"
                component={
                    <JobNumberEditKoj onClose={handleCloseModalEditKoj} data={dataSelected} />
                }
            />
            <Card variant="outlined">
                <CardContent className="custom-table-container">
                    <div className="header">
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<ViewColumn />}
                                endIcon={<ExpandMore />}
                                color="secondary"
                                onClick={e => setPopAnchorShowColumn(e.currentTarget)}
                            >
                                Column Shown
                            </Button>
                            <form onSubmit={handleSearch}>
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                                    }}
                                    placeholder="Type and enter for search"
                                />
                                <button type="submit" hidden={true} />
                            </form>
                            {searchParams.get('id') && (
                                <Typography variant="h6" component="span" style={{ marginLeft: 10 }}>Total archived job: {dataList.length}</Typography>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="body">
                        <table>
                            <thead>
                                <tr>
                                    {columns.map((column: any, i: number) => (
                                        !columnHidden.includes(column.name) && <td key={i}>{column.name}</td>
                                    ))}
                                    <td>
                                        Action
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((data: any, i: number) => {
                                    let jobList = data.job_list ? JSON.parse(data.job_list) : []
                                    return (
                                        <Fragment key={data.id}>
                                            <tr className={i % 2 ? "" : "odd"}>
                                                {columns.map((column: any, j: number) => (
                                                    !columnHidden.includes(column.name) && (
                                                        isEdit && dataSelected.id === data.id && column.form
                                                            ? (
                                                                column.form.type === 'check'
                                                                    ? (
                                                                        <td key={j}>
                                                                            <FormInlineCheck label={column.name} name={column.key} value={dataSelected[column.key]} onChange={handleChangeDataSelected} />
                                                                        </td>
                                                                    )
                                                                    : (
                                                                        column.form.type === 'autocomplete'
                                                                            ? (
                                                                                <td key={j}>
                                                                                    <FormInlineAutocomplete url={column.form.url} label={column.name} name={column.form.key} value={dataSelected[column.form.key]} onChange={handleChangeDataSelected} />
                                                                                </td>
                                                                            )
                                                                            : (
                                                                                column.form.type === 'date'
                                                                                    ? (
                                                                                        <td key={j}>
                                                                                            <TextField
                                                                                                key={j}
                                                                                                style={{ minWidth: 100 }}
                                                                                                size="small"
                                                                                                type="date"
                                                                                                label={column.name}
                                                                                                name={column.key}
                                                                                                value={
                                                                                                    dataSelected[column.key]
                                                                                                        ? (
                                                                                                            dataSelected[column.key].includes('T')
                                                                                                                ? dataSelected[column.key].split('T')[0]
                                                                                                                : dataSelected[column.key]
                                                                                                        )
                                                                                                        : ""
                                                                                                }
                                                                                                onChange={handleChangeDataSelected}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                            />
                                                                                        </td>
                                                                                    )
                                                                                    : (
                                                                                        column.form.type === 'list'
                                                                                            ? (
                                                                                                <td key={j}>
                                                                                                    <JobNumberFormList
                                                                                                        name={column.key}
                                                                                                        value={dataSelected[column.key]}
                                                                                                        onChange={handleChangeDataSelected}
                                                                                                    />
                                                                                                </td>
                                                                                            )
                                                                                            : (
                                                                                                <td key={j}>
                                                                                                    <TextField
                                                                                                        key={j}
                                                                                                        style={{ minWidth: 100 }}
                                                                                                        size="small"
                                                                                                        type={column.form.type}
                                                                                                        label={column.name}
                                                                                                        name={column.key}
                                                                                                        value={dataSelected[column.key] || ""}
                                                                                                        onChange={handleChangeDataSelected}
                                                                                                    />
                                                                                                </td>
                                                                                            )
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                            : (
                                                                column.customRender
                                                                    ? (
                                                                        <column.customRender key={j} data={data} />
                                                                    )
                                                                    : (
                                                                        <td key={j}>{data[column.key]}</td>
                                                                    )
                                                            )
                                                    )
                                                ))}
                                                <td>
                                                    {isEdit && dataSelected.id === data.id
                                                        ? (
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Button variant="contained" color="secondary" onClick={() => { setIsEdit(false); setDataSelected({}) }}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="contained" color="primary" onClick={handleUpdate}>
                                                                    Update
                                                                </Button>
                                                            </div>
                                                        )
                                                        : (
                                                            <IconButton onClick={e => { setPopAnchorAction(e.currentTarget); setDataSelected(data) }}>
                                                                <MoreVert />
                                                            </IconButton>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr hidden={!expandedIds.includes(data.id)}>
                                                <td colSpan={26}>
                                                    <table className="extended">
                                                        {jobList.map((job: any) => {
                                                            return (
                                                                <tbody key={job.id}>
                                                                    <tr>
                                                                        {columnsChild.map((columnChild: any, j: number) => (
                                                                            !columnHidden.includes(columnChild.name) && (
                                                                                isEditChild && dataSelectedChild.id === job.id && columnChild.form
                                                                                    ? (
                                                                                        columnChild.form.type === "date"
                                                                                            ? (
                                                                                                <td key={j}>
                                                                                                    <TextField
                                                                                                        key={j}
                                                                                                        style={{ minWidth: 100 }}
                                                                                                        size="small"
                                                                                                        type="date"
                                                                                                        label={columnChild.name}
                                                                                                        name={columnChild.key}
                                                                                                        value={dataSelectedChild[columnChild.key] || ""}
                                                                                                        onChange={handleChangeDataSelectedChild}
                                                                                                        InputLabelProps={{ shrink: true }}
                                                                                                    />
                                                                                                </td>
                                                                                            )
                                                                                            : (
                                                                                                <td key={j}>
                                                                                                    <TextField
                                                                                                        key={j}
                                                                                                        style={{ minWidth: 100 }}
                                                                                                        size="small"
                                                                                                        type={columnChild.form.type}
                                                                                                        label={columnChild.name}
                                                                                                        name={columnChild.key}
                                                                                                        value={dataSelectedChild[columnChild.key] || ""}
                                                                                                        onChange={handleChangeDataSelectedChild}
                                                                                                    />
                                                                                                </td>
                                                                                            )
                                                                                    )
                                                                                    : (
                                                                                        columnChild.customRender
                                                                                            ? (
                                                                                                <columnChild.customRender key={j} data={job} />
                                                                                            )
                                                                                            : (
                                                                                                <td key={j}>
                                                                                                    <b>{columnChild.name}</b>
                                                                                                    <br />
                                                                                                    <span>{job[columnChild.key]}</span>
                                                                                                </td>
                                                                                            )
                                                                                    )

                                                                            )
                                                                        ))}
                                                                        <td>
                                                                            {isEditChild && dataSelectedChild.id === job.id
                                                                                ? (
                                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        <Button variant="contained" color="secondary" onClick={() => { setIsEditChild(false); setDataSelectedChild({}) }}>
                                                                                            Cancel
                                                                                        </Button>
                                                                                        <Button variant="contained" color="primary" onClick={handleUpdateChild}>
                                                                                            Update
                                                                                        </Button>
                                                                                    </div>
                                                                                )
                                                                                : (
                                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        <IconButton onClick={e => { setPopAnchorActionChild(e.currentTarget); setDataSelectedChild({ ...job, requestor_id: data.requestor_id, district_id: data.district_id }) }}>
                                                                                            <MoreVert />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })}
                                                    </table>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardContent>
            </Card>
        </Container >
    )
}