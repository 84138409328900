import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createPosition = createAsyncThunk('createPosition', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/position',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deletePosition = createAsyncThunk('deletePosition', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/position?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editPosition = createAsyncThunk('editPosition', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/position',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getMenu = createAsyncThunk('getMenu', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/menu',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})