import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadcumbs } from "../../../../containers/containers.reducer";
import FormAutocompleteMultiple from "../../../../shared/FormAutocompleteMultiple";
import FormDate from "../../../../shared/FormDate";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { createJobNumberRevision, getJobNumberSingle } from "../../project.api";

export default function JobNumberAddRevision() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const [values, setValues] = useState<any>({
        koj_ids: null,
        date_received: "",
        date_due: "",
        source_job: "",
        quantity_request: 0

    })
    const [jobNumberData, setJobNumberData] = useState<any>({})
    const [allowedKoj, setAllowedKoj] = useState<any>([])

    useEffect(() => {
        dispatch(setBreadcumbs([
            {
                name: "Project",
                url: "/project"
            },
            {
                name: "Details",
                url: ""
            },
            {
                name: "Add Revision",
                url: "/project/detail/addrevision"
            }
        ]))
        let jobnumberid = searchParams.get('jobnumberid')
        dispatch<any>(getJobNumberSingle(jobnumberid))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    let data = response.data.data[0].id ? response.data.data[0] : {}
                    setJobNumberData(data)
                    let allowedKojNew = data.job_list
                        ? JSON.parse(data.job_list).map((job: any) => {
                            return job.koj_id
                        })
                        : []
                    setAllowedKoj(allowedKojNew)
                    setValues({
                        koj_ids: allowedKojNew.join()
                    })
                }
                else {
                    notificationError(response)
                }
            })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let dataToPost = {
            previous_id: searchParams.get('jobnumberid'),
            date_received: values.date_received,
            date_due: values.date_due,
            source_job: values.source_job,
            quantity_request: values.quantity_request,
            koj_list: values.koj_ids.split(',').map((kojId: string, i: number) => {
                return {
                    koj_id: kojId,
                    order_value: i
                }
            })
        }
        dispatch<any>(createJobNumberRevision(dataToPost))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new revision jobnumber")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container maxWidth="xl">
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                Add Revision
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Add Revision
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <br />
                                <FormText label="Order Number" value={jobNumberData.number_order} disabled />
                                <br />
                                <FormText label="Team" value={jobNumberData.project_team_name} disabled />
                                <br />
                                <FormText label="Job Number" value={jobNumberData.number_job} disabled />
                                <br />
                                <FormText label="Requestor" value={jobNumberData.project_requestor_name} disabled />
                                <br />
                                <FormText label="Submittal" value={jobNumberData.project_submittal_name} disabled />
                                <br />
                                <FormText label="District" value={jobNumberData.project_district_name} disabled />
                                <br />
                            </Grid>
                            <Grid item md={1}></Grid>
                            <Grid item md={6}>
                                <br />
                                <FormText label="Project" value={jobNumberData.project_name} disabled />
                                <br />
                                {values.koj_ids !== null && <FormAutocompleteMultiple label="KOJ" url="/koj" idAllowed={allowedKoj} name="koj_ids" value={values.koj_ids} onChange={handleChangeValues} />}
                                <br />
                                <FormDate label="Job Received" name="date_received" value={values.date_received} onChange={handleChangeValues} />
                                <br />
                                <FormDate label="Due Date" name="date_due" value={values.date_due} onChange={handleChangeValues} />
                                <br />
                                <FormText label="Source Job" name="source_job" value={values.source_job} onChange={handleChangeValues} />
                                <br />
                                <FormNumber label="QTY Request" name="quantity_request" value={values.quantity_request} onChange={handleChangeValues} />
                                <br />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}