import { Add, Check, Delete, Edit, Launch } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Container, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import CustomModal from "../../../shared/CustomModal";
import FormText from "../../../shared/FormText";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { RootState } from "../../../store";
import { getTeam } from "../../master/team/team.api";
import { addTracker, deleteTracker, editTracker, getTracker } from "../tracker.api";

export default function Tracker() {

    const dispatch = useDispatch()

    const { teamList } = useSelector((state: RootState) => state.team)
    const { trackerList } = useSelector((state: RootState) => state.tracker)

    const [modalEdit, setModalEdit] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [trackerSelected, setTrackerSelected] = useState<any>({})

    useEffect(() => {
        dispatch(setTitle("Tracker"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([]))
        dispatch<any>(getTeam())
        dispatch<any>(getTracker())
    }, [])


    const handleChangeTrackerSelected = (e: any) => {
        const { name, value } = e.target
        setTrackerSelected({
            ...trackerSelected,
            [name]: value
        })
    }

    const handleAdd = (team: any) => {
        setTrackerSelected({
            team_id: team.id,
            team_name: team.name
        })
        setModalAdd(true)
    }

    const handleEdit = (tracker: any) => {
        setTrackerSelected(tracker)
        setModalEdit(true)
    }

    const handleCloseModal = () => {
        setTrackerSelected({})
        setModalAdd(false)
        setModalEdit(false)
    }

    const handleSubmitAdd = (e: any) => {
        e.preventDefault()
        dispatch<any>(addTracker({
            team_id: trackerSelected.team_id,
            value: trackerSelected.value,
            name: trackerSelected.name
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit URL")
                        .then(() => {
                            handleCloseModal()
                            dispatch<any>(getTracker())
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleSubmitEdit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editTracker({
            id: trackerSelected.id,
            team_id: trackerSelected.team_id,
            value: trackerSelected.value,
            name: trackerSelected.name
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit URL")
                        .then(() => {
                            handleCloseModal()
                            dispatch<any>(getTracker())
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteTracker(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete tracker")
                                dispatch<any>(getTracker())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                onClose={handleCloseModal}
                title={`Add URL for ${trackerSelected.team_name}`}
                component={
                    <Card>
                        <CardContent className="custom-form">
                            <form onSubmit={handleSubmitAdd}>
                                <FormText label="Name" name="name" value={trackerSelected.name} onChange={handleChangeTrackerSelected} />
                                <br />
                                <FormText label="URL" name="value" value={trackerSelected.value} onChange={handleChangeTrackerSelected} />
                                <br />
                                <br />
                                <div className="form-footer">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Check />}
                                        type="submit"
                                    >
                                        Update URL
                                    </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                }
            />
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModal}
                title={`Edit URL for ${trackerSelected.team_name}`}
                component={
                    <Card>
                        <CardContent className="custom-form">
                            <form onSubmit={handleSubmitEdit}>
                                <FormText label="Name" name="name" value={trackerSelected.name} onChange={handleChangeTrackerSelected} />
                                <br />
                                <FormText label="URL" name="value" value={trackerSelected.value} onChange={handleChangeTrackerSelected} />
                                <br />
                                <br />
                                <div className="form-footer">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Check />}
                                        type="submit"
                                    >
                                        Update URL
                                    </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                }
            />
            {teamList.filter((team: any) => (JSON.parse(localStorage.getItem("cqmData") || "{}").team_ids || []).includes(team.id)).map((team: any) => {
                let trackerListFiltered = trackerList.filter((tracker: any) => tracker.team_id === team.id)
                return (
                    <Fragment key={team.id}>
                        <Card>
                            <CardHeader
                                title={team.name}
                            />
                            <CardContent>
                                <List>
                                    {trackerListFiltered.map((tracker: any) => (
                                        <ListItem key={tracker.id}>
                                            <ListItemText
                                                primary={tracker.name}
                                                secondary={tracker.value}
                                            />
                                            <IconButton onClick={() => handleEdit(tracker)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(tracker.id)}>
                                                <Delete />
                                            </IconButton>
                                            <IconButton onClick={() => tracker.value !== '-' && window.open(tracker.value, "__blank")}>
                                                <Launch />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                    <ListItem>
                                        <ListItemText />
                                        <IconButton onClick={() => handleAdd(team)}>
                                            <Add />
                                        </IconButton>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                        <br />
                    </Fragment>
                )
            })}
        </Container >
    )
}