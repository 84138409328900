import { createSlice } from '@reduxjs/toolkit'

export interface ContainersState {
    loading: boolean,
    title: any,
    isShowBack: boolean,
    breadcumbs: any,
    transferJobBadge: number
}
const initialState: ContainersState = {
    loading: false,
    title: "",
    isShowBack: false,
    breadcumbs: [],
    transferJobBadge: 0
}

export const containersSlice = createSlice({
    name: 'containers',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setIsShowBack: (state, action) => {
            state.isShowBack = action.payload;
        },
        setBreadcumbs: (state, action) => {
            state.breadcumbs = action.payload;
        },
        setTransferJobBadge: (state, action) => {
            state.transferJobBadge = action.payload;
        }
    },
    extraReducers: (builder) => { },
})

export const {
    setLoading,
    setTitle,
    setIsShowBack,
    setBreadcumbs,
    setTransferJobBadge
} = containersSlice.actions;

export default containersSlice.reducer