import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import { getTeam } from "../../master/team/team.api";
import DashboardPrep from "./DashboardPrep";
import DashboardQc from "./DashboardQc";

export default function Dashboard() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Dashboard"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Dashboard",
                url: "/dashboard"
            }
        ]))
        dispatch<any>(getTeam())
    }, [])

    return (
        <Container>
            <Grid container spacing={2} className="dashboard">
                <Grid item md={12}>
                    <DashboardQc />
                </Grid>
                <Grid item md={12}>
                    <DashboardPrep />
                </Grid>
            </Grid>
        </Container>
    )
}