import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { createPosition } from "../position.api";

export default function PositionAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        name: "",
        level_value: 0
    })

    useEffect(() => {
        dispatch(setTitle("Master Data"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Master Data",
                url: ""
            },
            {
                name: "Position",
                url: "/master/position"
            },
            {
                name: "Create Position",
                url: "/master/position/add"
            }
        ]))
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createPosition(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new position")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Position
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Position
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Name" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormNumber label="Level" name="level_value" value={values.level_value} onChange={handleChangeValues} required />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}