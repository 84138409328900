import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getProjectOptions = createAsyncThunk('getProjectOptions', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/options',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getJobNumberAndKojs = createAsyncThunk('getJobNumberAndKojs', async (projectId: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/kojs',
            params: { projectid: projectId }
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getBillDefault = createAsyncThunk('getBillDefault', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/bill/default'
        })
        return response
    } catch (error: any) {
        return error.response
    }
})