import { Close } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField } from "@mui/material";
import { useEffect } from "react";

export default function JobNumberAddFormNames(props: any) {

    useEffect(() => {
        handleAdd()
    }, [])

    const handleAdd = () => {
        props.setValues([...props.values, { number_job: "", quantity_request: 0, is_hot_job: 0 }])
    }

    const handleDelete = (index: number) => {
        props.setValues(props.values.filter((d: number, i: number) => i !== index))
    }

    const handleEdit = (index: number, e: any) => {
        props.setValues(
            props.values
                .map((d: any, i: number) => {
                    if (i === index) {
                        return {
                            ...d,
                            [e.target.name]: e.target.value
                        }
                    }
                    return d
                })
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <ul>
                    {props.values.map((name: any, i: number) => (
                        <li key={i} style={{ marginTop: 10 }}>
                            <div style={{ display: "flex", flexDirection: "row", }}>
                                <TextField size="small" fullWidth required type="text" label="Job Number" name="number_job" value={name.number_job} onChange={e => handleEdit(i, e)} /> &nbsp;
                                <TextField size="small" fullWidth required type="number" label="Quantity Request" name="quantity_request" value={name.quantity_request} onChange={e => handleEdit(i, e)} /> &nbsp;
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={name.is_hot_job} onChange={e => handleEdit(i, { target: { name: "is_hot_job", value: e.target.checked } })} />}
                                        label="Hot Job"
                                    />
                                </FormGroup>
                                <IconButton disabled={i === 0} onClick={() => handleDelete(i)}>
                                    <Close />
                                </IconButton>
                            </div>
                        </li>
                    ))}
                </ul>
                <Button variant="outlined" size="small" fullWidth onClick={handleAdd}>Add</Button>
            </Grid>
        </Grid >
    )
}