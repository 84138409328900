import { ChevronRight } from "@mui/icons-material";
import { Card, CardContent, Checkbox, Grid, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import { RootState } from "../../../store";
import { getBillDefault, getJobNumberAndKojs, getProjectOptions } from "../invoiceStatus.api";
import { clearJobnumberList } from "../invoiceStatus.reducer";

export default function InvoiceStatus() {

    const dispatch = useDispatch()

    const { projectList, jobnumberList } = useSelector((state: RootState) => state.invoiceStatus)

    const [projectSelected, setProjectSelected] = useState<any>({})
    const [jobnumberSelected, setJobnumberSelected] = useState<any>({})
    const [billDefaultList, setBillDefaultList] = useState<any>([])
    const [billDefaultSelected, setBillDefaultSelected] = useState<any>({})
    const [billDefaultKojList, setBillDefaultKojList] = useState<any>([])

    useEffect(() => {
        dispatch(setTitle("Invoice Status"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([]))
        dispatch<any>(getProjectOptions())
    }, [])

    const handleChooseProject = (project: any) => {
        if (project.id !== projectSelected.id) {
            setProjectSelected(project)
            dispatch<any>(getJobNumberAndKojs(project.id))
        }
        else {
            setProjectSelected({})
        }
        setJobnumberSelected({})
        dispatch(clearJobnumberList())
        setBillDefaultList([])
        setBillDefaultSelected({})
        setBillDefaultKojList([])
    }

    const handleChoosejobnumber = (jobnumber: any) => {
        if (jobnumber.id !== jobnumberSelected.id) {
            setJobnumberSelected(jobnumber)
            dispatch<any>(getBillDefault())
                .unwrap()
                .then((response: any) => {
                    let data = response.data.data
                    let newData = data.map((d: any) => {
                        let defaultKojList = JSON.parse(d.koj_list)
                        let jobnumberKojIds = jobnumber.koj_ids ? jobnumber.koj_ids.split(',') : [jobnumber.koj_ids]
                        let isChecked = defaultKojList.every((koj: any) => jobnumberKojIds.includes(koj.koj_id))
                        return {
                            ...d,
                            is_checked: isChecked
                        }
                    })
                    setBillDefaultList(newData)
                })
        }
        else {
            setJobnumberSelected({})
            setBillDefaultList([])
            setBillDefaultSelected({})
            setBillDefaultKojList([])
        }
        setBillDefaultKojList([])
    }

    const handleChooseBillDefault = (billDefault: any) => {
        if (billDefault.id !== billDefaultSelected.id) {
            let data = JSON.parse(billDefault.koj_list)
            let newData = data.map((d: any) => {
                let jobnumberKojIds = jobnumberSelected.koj_ids ? jobnumberSelected.koj_ids.split(',') : [jobnumberSelected.koj_ids]
                let isChecked = jobnumberKojIds.includes(d.koj_id)
                return {
                    ...d,
                    is_checked: isChecked
                }
            })
            setBillDefaultKojList(newData)
            setBillDefaultSelected(billDefault)
        }
        else {
            setBillDefaultKojList([])
            setBillDefaultSelected({})
        }
    }

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item md={3}>
                    {projectList.length > 0 && (
                        <Card>
                            <CardContent className="fit-in-height">
                                <List>
                                    {projectList.map((project: any) => (
                                        <ListItemButton className={projectSelected.id === project.id ? "active" : ""} key={project.id} onClick={() => handleChooseProject(project)}>
                                            <ListItemText primary={project.name} />
                                            <ChevronRight />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid item md={3}>
                    {jobnumberList.length > 0 && (
                        <Card>
                            <CardContent className="fit-in-height">
                                <List>
                                    {jobnumberList.map((jobnumber: any) => (
                                        <ListItemButton className={jobnumberSelected.id === jobnumber.id ? "active" : ""} key={jobnumber.id} onClick={() => handleChoosejobnumber(jobnumber)}>
                                            <ListItemText primary={jobnumber.name} />
                                            <ChevronRight />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid item md={3}>
                    {billDefaultList.length > 0 && (
                        <Card>
                            <CardContent className="fit-in-height">
                                <List>
                                    {billDefaultList.map((billDefault: any) => (
                                        <ListItemButton key={billDefault.id} onClick={() => handleChooseBillDefault(billDefault)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={billDefault.is_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={billDefault.name} />
                                            <ChevronRight />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid item md={3}>
                    {billDefaultKojList.length > 0 && (
                        <Card>
                            <CardContent className="fit-in-height">
                                <List>
                                    {billDefaultKojList.map((billDefaultKoj: any) => (
                                        <ListItemButton key={billDefaultKoj.id}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={billDefaultKoj.is_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={billDefaultKoj.koj_name} />
                                            <ChevronRight />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}