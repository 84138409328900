import { Card, CardContent, Grid } from "@mui/material";
import { useEffect } from "react";
import 'chart.js/auto';
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getChartQc } from "../dashboard.api";

export default function DashboardQc() {

    const dispatch = useDispatch()

    const { teamList } = useSelector((state: RootState) => state.team)
    const { chartQcData } = useSelector((state: RootState) => state.dashboard)

    useEffect(() => {
        dispatch<any>(getChartQc())
    }, [])

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={5}>
                        <table>
                            <tbody>
                                {teamList
                                    .filter((team: any) => !(['28F59F16-A734-4999-9B6F-41BD4F219478', '6B9E5D18-5E22-4949-862C-4FD9DDADAE7B', '7F0ECF49-E04E-469A-92DE-78506520A752'].includes(team.id)))
                                    .sort((a: any, b: any) => {
                                        a = a.name.toLowerCase()
                                        b = b.name.toLowerCase()
                                        return a < b ? -1 : a > b ? 1 : 0
                                    })
                                    .map((team: any) => {
                                        let chartData = chartQcData.filter((chart: any) => chart.team_id === team.id)
                                        return (
                                            <tr key={team.id}>
                                                <td>{team.name}</td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>New</td>
                                                                <td>{chartData.length > 0 ? chartData[0].new : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ready to QC</td>
                                                                <td>{chartData.length > 0 ? chartData[0].qc_ready : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>QC</td>
                                                                <td>{chartData.length > 0 ? chartData[0].qc_progress : 0}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item md={7}>
                        <Pie
                            data={{
                                labels: ["New", "Ready to QC", "QC"],
                                datasets: [
                                    {
                                        data: [
                                            chartQcData.reduce((total: number, chart: any) => total + chart.new, 0),
                                            chartQcData.reduce((total: number, chart: any) => total + chart.qc_ready, 0),
                                            chartQcData.reduce((total: number, chart: any) => total + chart.qc_progress, 0)
                                        ]
                                    }
                                ]
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}