import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";

export default function FormCheck(props: any) {

    const handleChange = (e: any, value: any) => {
        props.onChange({
            target: {
                name: props.name,
                value: value ? 1 : 0
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
            </Grid>
            <Grid item md={9}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={props.name}
                                checked={props.value === 1 ? true : false}
                                onChange={handleChange}
                            />}
                        label={props.label}
                        disabled={props.disabled}
                    />
                </FormGroup>
            </Grid>
        </Grid>
    )
}