import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../shared/FormAutocomplete"
import FormDate from "../../../shared/FormDate"
import FormText from "../../../shared/FormText"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { editCalendar } from "../holiday.api"

export default function CalendarEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        name: "",
        date: "",
        date_off: "",
        type_id: ""
    })

    useEffect(() => {
        setValues({
            id: props.data.id,
            name: props.data.name,
            date: moment(props.data.date).format('YYYY-MM-DD'),
            date_off: moment(props.data.date_off).format('YYYY-MM-DD'),
            type_id: props.data.type_id
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editCalendar(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit holiday")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormText label="Name" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormDate label="Date" name="date" value={values.date} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormDate label="Date Off" name="date_off" value={values.date_off} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/calendar/type" label="Type" name="type_id" value={values.type_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Calendar
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}