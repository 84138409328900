import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import WorksheeMain from "./WorksheetMain";
import WorksheeRelated from "./WorksheetRelated";

export default function Worksheet() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Worksheet"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Worksheet",
                url: ""
            },
            {
                name: "Main Job & Sub Job",
                url: ""
            }
        ]))
    }, [])

    return (
        <Container maxWidth="xl">
            <WorksheeMain />
            <br />
            <WorksheeRelated />
        </Container >
    )
}