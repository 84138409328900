import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Divider } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormAutocomplete from "../../../../shared/FormAutocomplete";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { createTransferJob } from "../../../transferjob/transferJob.api";

export default function JobTransfer(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        quantity_transfer: 0,
        team_id: ""
    })

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let data = {
            ...values,
            job_id: props.data.id
        }
        dispatch<any>(createTransferJob(data))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success transfer job")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormText label="Job Number" value={props.data.jobnumber_number_job} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="KOJ" value={props.data.koj_name} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Get Quantity" name="quantity_transfer" value={values.quantity_transfer} max={props.data.job_calculation_total_available} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormAutocomplete url={'/team'} idDisabled={[props.data.jobnumber_project_team_id]} label="Team" name="team_id" value={values.team_id} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Transfer Job
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}