import { ArrowDropDown, ArrowDropUp, Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { createKpi } from "../kpi.api";
import { getEmployeeUnder } from "../../master/employee/employee.api";
import moment from "moment";

interface Value {
    employee_id: string,
    employee_name: string,
    employee_nik: string,
    value_achievement: string,
    value: string,
    note: string
}

export default function KpiAdd() {

    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const [values, setValues] = useState<Value[]>([])
    const [period, setPeriod] = useState(moment().format("YYYY/MM"));
    const [sortByColumn, setSortByColumn] = useState("")
    const [sortByAsc, setSortByAsc] = useState(false)

    useEffect(() => {
        dispatch(getEmployeeUnder())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setValues(response.data.data.map((item: any) => {
                        const value: Value = {
                            employee_id: item.id,
                            employee_name: item.name,
                            employee_nik: item.nik,
                            value_achievement: "",
                            value: "",
                            note: ""
                        }
                        return value
                    }))
                }
                else {
                    notificationError(response)
                }
            })
    }, [dispatch])

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "KPI",
                url: "/report/kpi"
            },
            {
                name: "Create KPI",
                url: "/report/kpi/add"
            }
        ]))
    }, [])

    const handleChangeValues = (employeeId: string, e: any) => {
        const { name, value } = e.target
        setValues(values.map(last => {
            if (last.employee_id === employeeId) {
                if (name === "value" && (value < 0 || value > 100)) {
                    return last
                }
                return {
                    ...last,
                    [name]: ["value", "value_achievement"].includes(name)
                        ? value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                        : value
                }
            }
            return last
        }))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch(createKpi({
            head_id: localStorage.getItem("cqmToken"),
            name: period,
            employee_list: values.map(value => {
                return {
                    employee_id: value.employee_id,
                    value_achievement: value.value_achievement,
                    value: value.value,
                    note: value.note
                }
            })
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new kpi")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    const periods = [];
    const currentDate = moment();
    const currentYear = currentDate.get("year");
    const currentMonth = currentDate.get("month") + 1

    const columns = [
        {
            name: "Name",
            key: "employee_name"
        },
        {
            name: "NIK",
            key: "employee_nik"
        },
        {
            name: "Achievement",
            key: "value_achievement"
        },
        {
            name: "KPI",
            key: "value"
        },
        {
            name: "Note",
            key: "note"
        }
    ]

    for (let year = currentYear; year >= 2022; year--) {
        for (let month = 12; month >= 1; month--) {
            if (year === currentYear && month > currentMonth) {
                continue;
            }
            const formattedMonth = month.toString().padStart(2, "0");
            const period = `${year}/${formattedMonth}`;
            periods.push(period);
        }
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New KPI
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create KPI
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormControl fullWidth>
                            <InputLabel>Period</InputLabel>
                            <Select
                                label="Period"
                                value={period}
                                onChange={(e) => setPeriod(e.target.value)}
                            >
                                {periods.map((period) => (
                                    <MenuItem key={period} value={period}>
                                        {period}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell key={column.key} sx={{ fontWeight: "bold" }}>
                                            {column.name}
                                            {Boolean(column.key) && (
                                                sortByColumn === column.key
                                                    ? (
                                                        <Fragment>
                                                            {sortByAsc
                                                                ? (
                                                                    <IconButton color="primary" onClick={() => { setSortByColumn(column.key); setSortByAsc(false) }}>
                                                                        <ArrowDropUp />
                                                                    </IconButton>
                                                                )
                                                                : (
                                                                    <IconButton color="primary" onClick={() => { setSortByColumn(column.key); setSortByAsc(true) }}>
                                                                        <ArrowDropDown />
                                                                    </IconButton>
                                                                )
                                                            }
                                                        </Fragment>
                                                    )
                                                    : (
                                                        <IconButton onClick={() => { setSortByColumn(column.key); setSortByAsc(false) }}>
                                                            <ArrowDropDown />
                                                        </IconButton>
                                                    )
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {values
                                    .sort((a: any, b: any) => {
                                        if (sortByColumn !== "") {
                                            return sortByAsc ? a[sortByColumn].localeCompare(b[sortByColumn]) : b[sortByColumn].localeCompare(a[sortByColumn]);
                                        }
                                        return 0;
                                    })
                                    .map(value => (
                                        <TableRow key={value.employee_id}>
                                            <TableCell>{value.employee_name}</TableCell>
                                            <TableCell>{value.employee_nik}</TableCell>
                                            <TableCell sx={{ width: "150px" }}>
                                                <TextField
                                                    size="small"
                                                    name="value_achievement"
                                                    value={value.value_achievement}
                                                    onChange={e => handleChangeValues(value.employee_id, e)}
                                                    InputProps={{
                                                        endAdornment: '%',
                                                        inputProps: { step: 0.01 }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: "150px" }}>
                                                <TextField
                                                    size="small"
                                                    name="value"
                                                    value={value.value}
                                                    onChange={e => handleChangeValues(value.employee_id, e)}
                                                    InputProps={{
                                                        endAdornment: '%',
                                                        inputProps: { min: 0, max: 100, step: 0.01 }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    name="note"
                                                    value={value.note}
                                                    onChange={e => handleChangeValues(value.employee_id, e)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container >
    )
}