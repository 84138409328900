import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getTransferJobAvailable = createAsyncThunk('getTransferJobAvailable', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job/transfer/available',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createTransferJob = createAsyncThunk('createTransferJob', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job/transfer',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteTransferJob = createAsyncThunk('deleteTransferJob', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/jobnumber/job/transfer?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})