import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormDate from "../../../../shared/FormDate";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { ID_SHIFT_PAGI } from "../../../../state";
import { createAbsent } from "../absent.api";

export default function AbsentAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        employee_id: "",
        name: "",
        minutes: "",
        notes: "",
        status_id: ""
    })
    const [disableMinutes, setDisableMinutes] = useState(false)

    useEffect(() => {
        dispatch(setTitle("Absent"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Absent",
                url: ""
            },
            {
                name: "Absent",
                url: "/absent/absent"
            },
            {
                name: "Create Absent",
                url: "/absent/absent/add"
            }
        ]))
        let minutes = 0
        if ((ID_SHIFT_PAGI === JSON.parse(localStorage.getItem("cqmData") || "{}").shift_id) && (parseInt(moment().format("HH")) < 5 || parseInt(moment().format("HH")) > 22)) {
            setDisableMinutes(true)
            minutes = 240
        }
        else {
            setDisableMinutes(false)
        }
        setValues({
            ...values,
            name: moment().format("YYYY-MM-DD"),
            employee_id: window.localStorage.getItem("cqmToken"),
            status_id: "78DDF83B-6C8F-4748-B053-100EBC8B91AE",
            minutes: minutes
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createAbsent(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new absent")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Absent
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Absent
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Absent Date" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormNumber label="Request Minutes" name="minutes" value={values.minutes} onChange={handleChangeValues} required disabled={disableMinutes} />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}