import { ExpandLess, ExpandMore, Search, ViewColumn } from "@mui/icons-material";
import { Button, Card, CardContent, Checkbox, Container, Divider, IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText, Popover, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import notificationError from "../../../shared/notificationError";
import { getProjectListWithPaginations } from "../project.api";

export default function ProjectArchive() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [popAnchorShowColumn, setPopAnchorShowColumn] = useState<HTMLButtonElement | null>(null);
    const [columnHidden, setColumnHidden] = useState<any>([])
    const [dataList, setDataList] = useState<any>([])
    const [expandedIds, setExpandedIds] = useState<any>([])
    const [searchWord, setSearchWord] = useState("")

    const handleExpand = (id: string) => {
        if (expandedIds.includes(id)) {
            setExpandedIds(expandedIds.filter((ids: string) => ids !== id))
        }
        else {
            setExpandedIds([...expandedIds, id])
        }
    }

    const columns = [
        {
            name: "Project",
            customRender: (props: any) => {
                return (
                    <td>
                        <IconButton onClick={() => handleExpand(props.data.id)}>
                            {expandedIds.includes(props.data.id) ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <span className="clickable" onClick={() => navigate('/project/archive/detail?id=' + props.data.id)}>
                            <Link underline="always">{props.data.name}</Link>
                        </span>
                    </td>
                )
            }
        },
        {
            name: "Requestor",
            key: "requestor_name"
        },
        {
            name: "Submittal",
            key: "submittal_name"
        },
        {
            name: "District",
            key: "district_name"
        }
    ]

    useEffect(() => {
        dispatch(setTitle("Archive"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Project",
                url: "/project"
            },
            {
                name: "Archive",
                url: "/archive"
            }
        ]))
    }, [])

    useEffect(() => {
        getDataList()
    }, [])

    const getDataList = () => {
        dispatch<any>(getProjectListWithPaginations({
            search: searchWord,
            is_archive: 1
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setDataList(response.data.data)
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleChangeColumnHidden = (e: any, name: String) => {
        if (e.target.checked) {
            setColumnHidden(columnHidden.filter((item: String) => item !== name))
        }
        else {
            setColumnHidden([...columnHidden, name])
        }
    }

    const handleChangeSearch = (e: any) => {
        let { value } = e.target
        setSearchWord(value)
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        getDataList()
    }

    return (
        <Container className="project">
            <Popover
                open={Boolean(popAnchorShowColumn)}
                anchorEl={popAnchorShowColumn}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={() => setPopAnchorShowColumn(null)}
            >
                <List>
                    {columns.map((column: any, i: number) => (
                        <ListItem
                            key={i}
                            disablePadding
                            style={{ paddingRight: 20 }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={!columnHidden.includes(column.name)}
                                    onChange={e => handleChangeColumnHidden(e, column.name)}
                                />
                            </ListItemIcon>
                            <ListItemText>{column.name}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Popover>
            <Card variant="outlined">
                <CardContent className="custom-table-container">
                    <div className="header">
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<ViewColumn />}
                                endIcon={<ExpandMore />}
                                color="secondary"
                                onClick={e => setPopAnchorShowColumn(e.currentTarget)}
                            >
                                Column Shown
                            </Button>
                            <form onSubmit={handleSearch}>
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                                    }}
                                    placeholder="Type and enter for search"
                                />
                                <button type="submit" hidden={true} />
                            </form>
                        </div>
                    </div>
                    <Divider />
                    <div className="body">
                        <table>
                            <thead>
                                <tr>
                                    {columns.map((column: any, i: number) => (
                                        !columnHidden.includes(column.name) && <td key={i}>{column.name}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((data: any, i: number) => {
                                    let configList = data.config_list ? JSON.parse(data.config_list) : []
                                    return (
                                        <Fragment key={data.id}>
                                            <tr className={i % 2 ? "" : "odd"}>
                                                {columns.map((column: any, j: number) => (
                                                    !columnHidden.includes(column.name) && (
                                                        column.key
                                                            ? <td key={j}>{data[column.key]}</td>
                                                            : <column.customRender key={j} data={data} />

                                                    )
                                                ))}
                                            </tr>
                                            <tr hidden={!expandedIds.includes(data.id)} className={i % 2 ? "" : "odd"}>
                                                <td colSpan={5}>
                                                    <table>
                                                        <tbody>
                                                            {configList.map((config: any, j: number) => (
                                                                <tr>
                                                                    <td>
                                                                        <b>KOJ</b>
                                                                        <br />
                                                                        <span style={{ backgroundColor: config.koj_color }}>{config.koj_name}</span>
                                                                    </td>
                                                                    <td>
                                                                        <b>Type</b>
                                                                        <br />
                                                                        <span>{config.type_job_name}</span>
                                                                    </td>
                                                                    <td>
                                                                        <b>Target</b>
                                                                        <br />
                                                                        <span>{config.target}</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardContent>
            </Card>
        </Container >
    )
}