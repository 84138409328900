import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const login = createAsyncThunk('login', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/auth/login',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getEmployeeDetail = createAsyncThunk('getEmployeeDetail', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/auth/login?id=' + localStorage.getItem('cqmToken'),
        })
        return response
    } catch (error: any) {
        return error.response
    }
})