import { createSlice } from '@reduxjs/toolkit'
import { controlJobComplete, controlJobHold, controlJobStart, controlTimerAdjust, controlTimerDecrease, controlTimerIncrease, deleteEmployeeJob, editEmployeeJobMultiple, getEmployeeJobByJobnumber } from './worksheet.api'

export interface WorksheetState {
    loading: boolean
}
const initialState: WorksheetState = {
    loading: false
}

export const worksheetSlice = createSlice({
    name: 'worksheet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getEmployeeJobByJobnumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getEmployeeJobByJobnumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getEmployeeJobByJobnumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteEmployeeJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteEmployeeJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteEmployeeJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlJobStart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlJobStart.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlJobStart.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlJobHold.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlJobHold.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlJobHold.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlJobComplete.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlJobComplete.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlJobComplete.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editEmployeeJobMultiple.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editEmployeeJobMultiple.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editEmployeeJobMultiple.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlTimerIncrease.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlTimerIncrease.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlTimerIncrease.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlTimerDecrease.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlTimerDecrease.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlTimerDecrease.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlTimerAdjust.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlTimerAdjust.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlTimerAdjust.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default worksheetSlice.reducer