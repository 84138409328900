import { ExpandMore, Search, ViewColumn } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, Checkbox, Container, Divider, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { createBilled, getBillListById } from "../bill.api";

export default function BilledCreate() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const [popAnchorShowColumn, setPopAnchorShowColumn] = useState<HTMLButtonElement | null>(null);
    const [popAnchorAction, setPopAnchorAction] = useState<HTMLButtonElement | null>(null);
    const [columnHidden, setColumnHidden] = useState<any>(["Type"])
    const [dataList, setDataList] = useState<any>([])
    const [totalJob, setTotalJob] = useState(0)
    const [totalBillableQty, setTotalBillableQty] = useState(0)
    const [totalMreQty, setTotalMreQty] = useState(0)
    const [totalKatapultQty, setTotalKatapultQty] = useState(0)
    const [totalPlaQty, setTotalPlaQty] = useState(0)
    const [totalDwgQty, setTotalDwgQty] = useState(0)

    const columns = [
        {
            name: "Project",
            key: "project_name"
        },
        {
            name: "Requestor",
            key: "project_requestor_name"
        },
        {
            name: "Submittal",
            key: "project_submittal_name"
        },
        {
            name: "District",
            key: "project_district_name"
        },
        {
            name: "Done By",
            key: "project_team_name"
        },
        {
            name: "Type",
            customRender: (props: any) => {
                let jobList = props.data.job_list ? JSON.parse(props.data.job_list) : []
                return (
                    <td>
                        <span>
                            {jobList.length > 0 ? jobList[0].type_name : ''}
                        </span>
                    </td>
                )
            }
        },
        {
            name: "Billable QTY",
            key: "quantity_billable_total"
        },
        {
            name: "MRE QTY",
            key: "quantity_mre_total"
        },
        {
            name: "Katapult QTY",
            key: "quantity_catapult_total"
        },
        {
            name: "Drawing QTY",
            key: "quantity_drawing_total"
        },
        {
            name: "PLA QTY",
            key: "pla"
        }
    ]

    useEffect(() => {
        dispatch(setTitle("Bill Job"))
        dispatch(setIsShowBack(true))
        dispatch(setBreadcumbs([
            {
                name: "Bill Job",
                url: "/bill"
            },
            {
                name: "Create Bill",
                url: "/bill/create"
            }
        ]))
        getDataList()
    }, [])

    const getDataList = () => {
        dispatch<any>(getBillListById({
            ids: searchParams.get('ids')
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setDataList(response.data.data)
                    setTotalJob(response.data.data.length)
                    setTotalBillableQty(response.data.data.reduce((summary: number, data: any) => summary + data.quantity_billable_total, 0))
                    setTotalMreQty(response.data.data.reduce((summary: number, data: any) => summary + data.quantity_mre_total, 0))
                    setTotalKatapultQty(response.data.data.reduce((summary: number, data: any) => summary + data.quantity_catapult_total, 0))
                    setTotalPlaQty(response.data.data.reduce((summary: number, data: any) => summary + data.pla, 0))
                    setTotalDwgQty(response.data.data.reduce((summary: number, data: any) => summary + data.quantity_drawing_total, 0))
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleChangeColumnHidden = (e: any, name: String) => {
        if (e.target.checked) {
            setColumnHidden(columnHidden.filter((item: String) => item !== name))
        }
        else {
            setColumnHidden([...columnHidden, name])
        }
    }

    const handleCreateBill = () => {
        let jobnumber_list = dataList.map((data: any) => {
            return { id: data.id }
        })
        dispatch<any>(createBilled({
            jobnumber_list: jobnumber_list
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("job billed succesfully created")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container className="bill">
            <Popover
                open={Boolean(popAnchorShowColumn)}
                anchorEl={popAnchorShowColumn}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={() => setPopAnchorShowColumn(null)}
            >
                <List>
                    {columns.map((column: any, i: number) => (
                        <ListItem
                            key={i}
                            disablePadding
                            style={{ paddingRight: 20 }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={!columnHidden.includes(column.name)}
                                    onChange={e => handleChangeColumnHidden(e, column.name)}
                                />
                            </ListItemIcon>
                            <ListItemText>{column.name}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Popover>
            <Popover
                open={Boolean(popAnchorAction)}
                anchorEl={popAnchorAction}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorAction(null)}
            >
                <List>
                    <ListItemButton>
                        <ListItemText>Edit</ListItemText>
                    </ListItemButton>
                </List>
            </Popover>
            <Card variant="outlined">
                <CardContent className="custom-table-container">
                    <div className="header">
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<ViewColumn />}
                                endIcon={<ExpandMore />}
                                color="secondary"
                                onClick={e => setPopAnchorShowColumn(e.currentTarget)}
                            >
                                Column Shown
                            </Button>
                            <TextField
                                className="inp"
                                size="small"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                                }}
                            />
                        </div>
                    </div>
                    <Divider />
                    <table>
                        <thead>
                            <tr>
                                {columns.map((column: any, i: number) => (
                                    !columnHidden.includes(column.name) && <td key={i}>{column.name}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((data: any) => (
                                <tr key={data.id}>
                                    {columns.map((column: any, j: number) => (
                                        !columnHidden.includes(column.name) && (
                                            column.customRender
                                                ? (
                                                    <column.customRender key={j} data={data} />
                                                )
                                                : (
                                                    <td key={j}>{data[column.key]}</td>
                                                )
                                        )
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardContent>
                <CardActions className="custom-table-footer bg-primary">
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <b>Date</b>
                            <br />
                            <span>{moment(searchParams.get('datestart')).format('DD MMM YYYY')} - {moment(searchParams.get('dateend')).format('DD MMM YYYY')}</span>
                        </Grid>
                        <Grid item md={2}>
                            <b>Total Jobs</b>
                            <br />
                            <span>{totalJob} Jobs</span>
                        </Grid>
                        <Grid item md={2}>
                            <b>Total Quantity</b>
                            <br />
                            <span>{totalBillableQty} Billable Qty</span><br />
                            <span>{totalMreQty} MRE Qty</span><br />
                            <span>{totalKatapultQty} Katapult Qty</span><br />
                            <span>{totalPlaQty} PLA Qty</span><br />
                            <span>{totalDwgQty} DWG Qty</span>
                        </Grid>
                        <Grid item md={3}>
                            <b>Bcode</b>
                            <br />
                            <TextField size="small" />
                        </Grid>
                        <Grid item md={1} className="button-container">
                            <Button variant="contained" color="secondary" onClick={handleCreateBill}>Bill</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Container >
    )
}