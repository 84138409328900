import { createSlice } from '@reduxjs/toolkit'
import { createStatusJobnumber, deleteStatusJobnumber, editStatusJobnumber } from './statusJobnumber.api'

export interface StatusJobnumberState {
    loading: boolean
}
const initialState: StatusJobnumberState = {
    loading: false
}

export const statusJobnumberSlice = createSlice({
    name: 'statusJobnumber',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createStatusJobnumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createStatusJobnumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createStatusJobnumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteStatusJobnumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteStatusJobnumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteStatusJobnumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editStatusJobnumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editStatusJobnumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editStatusJobnumber.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default statusJobnumberSlice.reducer