import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Divider, List, ListItem } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { createEmployeeJob } from "../../project.api";

export default function JobGet(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        quantity_get: 0
    })

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let data = {
            ...values,
            employee_id: window.localStorage.getItem("cqmToken"),
            job_id: props.data.id
        }
        if (props.data.job_calculation_transfer_id) {
            data = {
                ...data,
                job_transfer_id: props.data.job_calculation_transfer_id
            }
        }
        dispatch<any>(createEmployeeJob(data))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success get job")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    let cqmData = localStorage.getItem('cqmData') || "{}"
    let kojList = JSON.parse(cqmData).koj_list.map((koj: any) => {
        if (koj.is_active === 1 || koj.is_additional === 1) {
            return koj.koj_id
        }
    })

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormText label="Job Number" value={props.data.jobnumber_number_job} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="KOJ" value={props.data.koj_name} disabled />
                    <br />
                    <Divider />
                    <br />
                    <Card>
                        <CardHeader subheader="Who get this job?" />
                        <List>
                            {props.data.employee_list && (typeof props.data.employee_list === "object" ? props.data.employee_list : JSON.parse(props.data.employee_list)).map((employee: any) => (
                                <ListItem>{employee.employee_name}, quantity = {employee.quantity_get}</ListItem>
                            ))}
                        </List>
                    </Card>
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Get Quantity" name="quantity_get" value={values.quantity_get} min={0} max={props.data.job_calculation_total_available} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                            disabled={
                                props.data.job_calculation_total_available <= 0 ||
                                !kojList.includes(props.data.koj_id)
                            }
                        >
                            Get Job
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}