import { Container, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer"
import OvertimeAll from "./OvertimeAll"
import OvertimeSelf from "./OvertimeSelf"

export default function Overtime() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Overtime"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Overtime",
                url: ""
            },
            {
                name: "Overtime",
                url: "/overtime/overtime"
            }
        ]))
    }, [])

    return (
        <Container maxWidth="xl">
            <br />
            <OvertimeSelf />
            <br />
            <Typography variant="h4">All Overtime</Typography>
            <br />
            <OvertimeAll />
        </Container >
    )
}