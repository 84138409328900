import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import TakenjobMain from "./TakenjobMain";
import TakenjobSub from "./TakenjobSub";

export default function Takenjob() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Takenjob"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Worksheet",
                url: ""
            },
            {
                name: "Taken Job",
                url: ""
            },
            {
                name: "Main Job & Sub Job",
                url: ""
            }
        ]))
    }, [])

    return (
        <Container maxWidth="xl" className="takenjob">
            <TakenjobMain />
            <br />
            <TakenjobSub />
        </Container >
    )
}