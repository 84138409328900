import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormAutocomplete from "../../../../shared/FormAutocomplete";
import FormDate from "../../../../shared/FormDate";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { baseUrl } from "../../../../state";
import { createOvertime } from "../overtime.api";

export default function OvertimeAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        employee_id: "",
        name: "",
        minutes: "",
        notes: "",
        multiple: 1,
        head_status_id: "",
        hr_status_id: ""
    })
    const [jobList, setJobList] = useState<any>([])

    useEffect(() => {
        dispatch(setTitle("Overtime"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Overtime",
                url: ""
            },
            {
                name: "Overtime",
                url: "/overtime/overtime"
            },
            {
                name: "Create Overtime",
                url: "/overtime/overtime/add"
            }
        ]))
        setValues({
            ...values,
            name: moment().format("YYYY-MM-DD"),
            employee_id: window.localStorage.getItem("cqmToken"),
            head_status_id: "78DDF83B-6C8F-4748-B053-100EBC8B91AE",
            hr_status_id: "78DDF83B-6C8F-4748-B053-100EBC8B91AE"
        })
    }, [])

    const handleChangeProject = (i: number, value: string) => {
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}/project/jobnumber/options?project_id=${value}&koj_id=ALL`
        })
            .then((response: any) => {
                changeJobList(i, {
                    project_id: value,
                    jobnumber_id: undefined,
                    jobnumber_list: response.data.data
                })
            })
    }

    const handleChangeJobnumber = (i: number, value: string) => {
        let jobnumberListFiltered = jobList[i].jobnumber_list.filter((jobnumber: any) => jobnumber.id === value)
        if (jobnumberListFiltered.length > 0) {
            changeJobList(i, {
                jobnumber_id: value,
                koj_ids: jobnumberListFiltered[0].koj_ids.split(',')
            })
        }
    }

    const changeJobList = (i: number, value: any) => {
        setJobList(jobList.map((d: any, j: number) => {
            if (i === j) {
                return {
                    ...d,
                    ...value
                }
            }
            return d
        }))
    }

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createOvertime({
            ...values,
            job_list: jobList.map((job: any) => {
                return {
                    quantity_pole: job.quantity_pole,
                    jobnumber_id: job.jobnumber_id,
                    koj_id: job.koj_id
                }
            })
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new overtime")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Card>
                    <CardContent className="custom-form">
                        <div className="form-header">
                            <span>
                                New Overtime
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Overtime
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Overtime Date" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormNumber label="Request Minutes" name="minutes" value={values.minutes} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormNumber label="Multiple" name="multiple" value={values.multiple} onChange={handleChangeValues} step={0.5} />
                        <br />
                    </CardContent>
                </Card>
                {jobList.map((job: any, i: number) => (
                    <Fragment key={i}>
                        <br />
                        <Card>
                            <CardContent className="custom-form">
                                <br />
                                <FormAutocomplete url="/project/options?koj_id=ALL" label="Project" onChange={(e: any) => handleChangeProject(i, e.target.value)} />
                                <br />
                                {job.project_id && (
                                    <Fragment>
                                        <Divider />
                                        <br />
                                        <FormAutocomplete url={`/project/jobnumber/options?project_id=${job.project_id}&koj_id=ALL`} label="Job Number" value={job.jobnumber_id} onChange={(e: any) => handleChangeJobnumber(i, e.target.value)} />
                                        <br />
                                    </Fragment>
                                )}
                                {Boolean(job.jobnumber_id) && (
                                    <Fragment>
                                        <Divider />
                                        <br />
                                        <FormAutocomplete url="/koj" label="KOJ" value={job.koj_id} onChange={(e: any) => changeJobList(i, { koj_id: e.target.value })} idShowed={job.koj_ids} />
                                        <br />
                                    </Fragment>
                                )}
                                <Divider />
                                <br />
                                <FormNumber label="Quantity Pole" value={job.quantity_pole} onChange={(e: any) => changeJobList(i, { quantity_pole: e.target.value })} />
                                <br />
                            </CardContent>
                        </Card>
                    </Fragment>
                ))}
                <br />
                <Button fullWidth variant="contained" onClick={() => setJobList([...jobList, {}])}>Add Job</Button>
            </form>
        </Container>
    )
}