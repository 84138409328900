import { Button, Container, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import checkAccess from "../../../../shared/checkAccess";
import CustomModal from "../../../../shared/CustomModal";
import CustomTable from "../../../../shared/CustomTable";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { deleteAdditionalPoles } from "../additionalPoles.api";
import AdditionalPolesApproval from "./AdditionalPolesApproval";
import AdditionalPolesEdit from "./AdditionalPolesEdit";

export default function AdditionalPoles() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)
    const [modalApproval, setModalApproval] = useState(false)

    useEffect(() => {
        dispatch(setTitle("Additional Poles"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Project",
                url: ""
            },
            {
                name: "Additional Poles",
                url: "/project/additionalpoles"
            }
        ]))
    }, [])

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    const handleCloseModalApproval = (isReload: boolean) => {
        setModalApproval(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteAdditionalPoles(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete Additional Poles")
                                setTimeGetData(new Date().getTime())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Container maxWidth="xl">
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit Additional Poles"
                component={
                    <AdditionalPolesEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomModal
                open={modalApproval}
                onClose={handleCloseModalApproval}
                title="Approval Additional Poles"
                component={
                    <AdditionalPolesApproval onClose={handleCloseModalApproval} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/additionalpoles"}
                columns={[
                    {
                        name: "Date",
                        key: "date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>{moment(props.data.name).format("YYYY-MM-DD")}</span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Type",
                        key: "type_name"
                    },
                    {
                        name: "Name",
                        key: "employee_name"
                    },
                    {
                        name: "Position",
                        key: "employee_position_name"
                    },
                    {
                        name: "Additional Minutes",
                        key: "additional_minutes"
                    },
                    {
                        name: "Status",
                        key: "status_name"
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Rejection Notes",
                        key: "notes_rejection"
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => navigate('/project/additionalpoles/add')}
                            >
                                New Additional Poles
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                rowActionsAdditional={(props: any) => {
                    return (
                        <Fragment>
                            {checkAccess("APPROVAL") && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={e => {
                                        setDataSelected(props.data)
                                        setModalApproval(true)
                                    }}>
                                    Approval
                                </Button>
                            )}
                        </Fragment>
                    )
                }}
                timeGetData={timeGetData}
            />
        </Container>
    )
}