import { createSlice } from '@reduxjs/toolkit'
import { createPosition, deletePosition, editPosition, getMenu } from './position.api'

export interface PositionState {
    loading: boolean
}
const initialState: PositionState = {
    loading: false
}

export const positionSlice = createSlice({
    name: 'position',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createPosition.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createPosition.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createPosition.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deletePosition.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deletePosition.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deletePosition.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editPosition.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editPosition.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editPosition.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getMenu.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getMenu.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getMenu.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default positionSlice.reducer