import { createSlice } from '@reduxjs/toolkit'
import { getChartPrep, getChartQc } from './dashboard.api'

export interface DashboardState {
    loading: boolean,
    chartQcData: any,
    chartPrepData: any,
}
const initialState: DashboardState = {
    loading: false,
    chartQcData: [],
    chartPrepData: []
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getChartQc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getChartQc.fulfilled, (state, action) => {
            state.loading = false
            state.chartQcData = action.payload.data.data
        })
        builder.addCase(getChartQc.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getChartPrep.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getChartPrep.fulfilled, (state, action) => {
            state.loading = false
            state.chartPrepData = action.payload.data.data
        })
        builder.addCase(getChartPrep.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default dashboardSlice.reducer