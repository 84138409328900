import { ArrowDropDown, ArrowDropUp, Close, ExpandMore, MoreVert, Search, ViewColumn } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemIcon, ListItemText, MenuItem, Pagination, Popover, TextField, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { baseUrl } from "../state";
import checkAccess from "./checkAccess";
import notificationError from "./notificationError";
import CsvDownloader from 'react-csv-downloader';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function CustomTable(props: any) {

    const [popAnchorShowColumn, setPopAnchorShowColumn] = useState<HTMLButtonElement | null>(null);
    const [popAnchorAction, setPopAnchorAction] = useState<HTMLButtonElement | null>(null);
    const [columnHidden, setColumnHidden] = useState<any>([])
    const [paginationPage, setPaginationPage] = useState(1)
    const [paginationTake, setPaginationTake] = useState(5)
    const [dateStart, setDateStart] = useState<null | string>("")
    const [dateEnd, setDateEnd] = useState("")
    const [dataList, setDataList] = useState<any>([])
    const [idList, setIdList] = useState<any>([])
    const [dataRows, setDataRows] = useState(0)
    const [dataSelected, setDataSelected] = useState<any>({})
    const [lastTimeGetData, setLastTimeGetData] = useState<any>(undefined)
    const [dataIdSelected, setDataIdSelected] = useState<any>([])
    const [searchWord, setSearchWord] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingValue, setLoadingValue] = useState(0)
    const [sortByColumn, setSortByColumn] = useState("")
    const [sortByAsc, setSortByAsc] = useState(false)
    const [sumData, setSumData] = useState<any>({})

    useEffect(() => {
        let dt = new Date().getTime()
        setDateStart(props.filterDateInitialStart || moment(dt - 604800000).format('YYYY-MM-DD'))
        setDateEnd(props.filterDateInitialEnd || moment(dt).format('YYYY-MM-DD'))
        setColumnHidden(props.columnHidden || [])
    }, [])

    useEffect(() => {
        if (props.timeGetData !== lastTimeGetData) {
            setLastTimeGetData(props.timeGetData)
            getData()
        }
        else if (dateStart && dateEnd) {
            getData()
        }
    }, [paginationPage, paginationTake, dateStart, dateEnd, props.timeGetData, props.additionalParams, sortByColumn, sortByAsc])

    useEffect(() => {
        setDataIdSelected([])
    }, [dateStart, dateEnd, props.timeGetData])

    useEffect(() => {
        if (!loading) {
            setLoadingValue(0)
        }
    }, [loading])

    const getData = (isClear: boolean = false) => {
        setDataSelected({})
        setPopAnchorAction(null)
        setLoading(true)
        setDataList([])
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}${props.url}?search=${isClear ? "" : searchWord}${(props.additionalParams || '')}${sortByColumn ? `&sortcolumn=${sortByColumn}&sorttype=${sortByAsc ? 'ASC' : 'DESC'}` : ``}${props.filterDate ? `&datestart=${dateStart}&dateend=${dateEnd}` : ``}${props.paginations ? `&skip=${(paginationPage - 1) * paginationTake}&take=${paginationTake}` : ``}`,
            onDownloadProgress: progressEvent => {
                const total = parseFloat(progressEvent.total)
                const current = progressEvent.currentTarget.response.length
                let percentCompleted = Math.round(current / total * 100)
                setLoadingValue(percentCompleted)
            }
        })
            .then((response: any) => {
                if (response.status === 200) {
                    setDataList(response.data.data)
                    setIdList(response.data.id_list || [])
                    setDataRows(response.data.rows)
                    let sumDataNew: any = {}
                    response.data.data.map((data: any) => (
                        props.columns.filter((column: any) => Boolean(column.sum)).map((column: any) => (
                            sumDataNew[column.key] = (sumDataNew[column.key] || 0) + column.sum({ data: data })
                        ))
                    ))
                    setSumData(sumDataNew)
                }
                else {
                    notificationError(response)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChangeColumnHidden = (e: any, name: String) => {
        if (e.target.checked) {
            setColumnHidden(columnHidden.filter((item: String) => item !== name))
        }
        else {
            setColumnHidden([...columnHidden, name])
        }
    }

    const handleChangeShow = (e: any) => {
        setPaginationTake(parseInt(e.target.value))
        setPaginationPage(1)
    }

    const handleChangeDataSelected = (e: any) => {
        const { name, value } = e.target
        setDataSelected({
            ...dataSelected,
            [name]: value
        })
    }

    const handleCheck = (id: string) => {
        if (dataIdSelected.includes(id)) {
            setDataIdSelected(dataIdSelected.filter((item: string) => item !== id))
        }
        else {
            setDataIdSelected([...dataIdSelected, id])
        }
    }

    const handleCheckAll = () => {
        if (dataIdSelected.length === dataList.length) {
            setDataIdSelected([])
        }
        else {
            setDataIdSelected(idList)
        }
    }

    const handleChangeSearch = (e: any) => {
        let { value } = e.target
        setSearchWord(value)
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        getData()
    }

    const handleSearchReset = (e: any) => {
        e.preventDefault()
        setSearchWord("")
        getData(true)
    }

    const handleExport = async () => {
        if (props.exportUrl) {
            let result: any = await new Promise((resolve) => {
                setLoading(true)
                axios({
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
                    },
                    url: `${baseUrl}${props.exportUrl}?ids=${dataIdSelected.map((d: any) => { return `'${d}'` })}`,
                })
                    .then((response: any) => {
                        if (response.status === 200) {
                            let data = response.data.data
                            let dataClean = data.map((d: any) => {
                                let keys = Object.keys(d)
                                let dn: any = {}
                                for (const i in keys) {
                                    dn[keys[i]] = typeof d[keys[i]] === "string" ? d[keys[i]].replaceAll(',', '.') : d[keys[i]]
                                }
                                return dn
                            })
                            resolve(dataClean)
                        }
                        else {
                            notificationError(response)
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            return result
        }
        else {
            let result: any = await new Promise((resolve) => {
                setLoading(true)
                axios({
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
                    },
                    url: `${baseUrl}${props.url}?datestart=${dateStart}&dateend=${dateEnd}`,
                })
                    .then((response: any) => {
                        if (response.status === 200) {
                            let data = response.data.data
                            let dataClean = data.map((d: any) => {
                                let keys = Object.keys(d)
                                let dn: any = {}
                                for (const i in keys) {
                                    dn[keys[i]] = typeof d[keys[i]] === "string" ? d[keys[i]].replaceAll(',', '.') : d[keys[i]]
                                }
                                return dn
                            })
                            resolve(dataClean)
                        }
                        else {
                            notificationError(response)
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            return result
        }
    }

    return (
        <Fragment>
            <Popover
                open={Boolean(popAnchorShowColumn)}
                anchorEl={popAnchorShowColumn}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={() => setPopAnchorShowColumn(null)}
            >
                <List>
                    {props.columns.map((column: any, i: number) => (
                        <ListItem
                            key={i}
                            disablePadding
                            style={{ paddingRight: 20 }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={!columnHidden.includes(column.name)}
                                    onChange={e => handleChangeColumnHidden(e, column.name)}
                                />
                            </ListItemIcon>
                            <ListItemText>{column.name}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Popover>
            <Popover
                open={Boolean(popAnchorAction)}
                anchorEl={popAnchorAction}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorAction(null)}
            >
                <List>
                    {props.rowActions && <props.rowActions data={dataSelected} />}
                </List>
            </Popover>
            <Card variant="outlined">
                <CardContent className="custom-table-container">
                    <div className="header">
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<ViewColumn />}
                                endIcon={<ExpandMore />}
                                color="secondary"
                                onClick={e => setPopAnchorShowColumn(e.currentTarget)}
                            >
                                Column Shown
                            </Button>
                            {props.filterDate && (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="From"
                                        inputFormat="MM/DD/YYYY"
                                        value={dateStart}
                                        onChange={(value) => setDateStart(moment(value).format('YYYY-MM-DD'))}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                    {" "}
                                    <DesktopDatePicker
                                        label="To"
                                        inputFormat="MM/DD/YYYY"
                                        value={dateEnd}
                                        onChange={(value) => setDateEnd(moment(value).format('YYYY-MM-DD'))}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                </LocalizationProvider>
                            )}
                            <form onSubmit={handleSearch}>
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    value={searchWord}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                                        endAdornment: searchWord.length > 0 && (<InputAdornment position="end"><IconButton onClick={handleSearchReset}><Close /></IconButton></InputAdornment>)
                                    }}
                                    placeholder="Type and enter for search"
                                />
                                <button type="submit" hidden={true} />
                            </form>
                        </div>
                        <div>
                            {props.export &&
                                <CsvDownloader
                                    columns={props.columns.map((c: any) => { return { displayName: c.name, id: c.key } })}
                                    datas={handleExport}
                                    filename={`exported_data_${moment(dateStart).format('DDMMYYYY')}-${moment(dateEnd).format('DDMMYYYY')}`}
                                >
                                    <Button
                                        variant="contained"
                                    >
                                        Export To Excel
                                    </Button>
                                </CsvDownloader>
                            }
                            {dataIdSelected.length > 0 && (<span>{dataIdSelected.length} Selected</span>)}
                            {props.headerActions && <props.headerActions dataIdSelected={dataIdSelected} dateStart={dateStart} dateEnd={dateEnd} />}
                        </div>
                    </div>
                    <Divider />
                    {loading && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={loadingValue} />
                        </Box>
                    )}
                    <div className="body">
                        <table>
                            <thead>
                                <tr>
                                    {props.selectRow && (
                                        <td>
                                            <Checkbox
                                                checked={dataIdSelected.length === idList.length}
                                                onChange={handleCheckAll}
                                            />
                                        </td>
                                    )}
                                    {props.columns.map((column: any, i: number) => (
                                        !columnHidden.includes(column.name) && (
                                            <td key={i}>
                                                {column.name}
                                                {Boolean(column.key) && (
                                                    sortByColumn === column.key
                                                        ? (
                                                            <Fragment>
                                                                {sortByAsc
                                                                    ? (
                                                                        <IconButton color="primary" onClick={() => { setSortByColumn(column.key); setSortByAsc(false) }}>
                                                                            <ArrowDropUp />
                                                                        </IconButton>
                                                                    )
                                                                    : (
                                                                        <IconButton color="primary" onClick={() => { setSortByColumn(column.key); setSortByAsc(true) }}>
                                                                            <ArrowDropDown />
                                                                        </IconButton>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        )
                                                        : (
                                                            <IconButton onClick={() => { setSortByColumn(column.key); setSortByAsc(false) }}>
                                                                <ArrowDropDown />
                                                            </IconButton>
                                                        )
                                                )}
                                            </td>
                                        )
                                    ))}
                                    {((props.rowActions && (checkAccess("EDIT", props.pageUrl) || checkAccess("DELETE", props.pageUrl))) || (props.onEditInline && checkAccess("EDIT", props.pageUrl)) || props.rowActionsAdditional) && (
                                        <td>
                                            Action
                                        </td>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((data: any, i: number) => (
                                    <tr key={i} className={`${i % 2 ? `` : `odd`} ${props.onClickRow && `clickable`}`} onClick={() => props.onClickRow && props.onClickRow(data)}>
                                        {props.selectRow && (
                                            <td>
                                                <Checkbox
                                                    checked={dataIdSelected.includes(data.id)}
                                                    onChange={() => handleCheck(data.id)}
                                                />
                                            </td>
                                        )}
                                        {props.columns.map((column: any, j: number) => (
                                            !columnHidden.includes(column.name) && (
                                                dataSelected.id === data.id && column.form
                                                    ? (
                                                        <td key={j}>
                                                            <TextField
                                                                key={j}
                                                                style={{ minWidth: 100 }}
                                                                size="small"
                                                                type={column.form.type}
                                                                label={column.name}
                                                                name={column.key}
                                                                value={dataSelected[column.key] || ""}
                                                                onChange={handleChangeDataSelected}
                                                            />
                                                        </td>
                                                    )
                                                    : (
                                                        column.customRender
                                                            ? (
                                                                <column.customRender key={j} data={data} />
                                                            )
                                                            : (
                                                                <td key={j}>{data[column.key]}</td>
                                                            )
                                                    )
                                            )
                                        ))}
                                        {((props.rowActions && (checkAccess("EDIT", props.pageUrl) || checkAccess("DELETE", props.pageUrl))) || (props.onEditInline && checkAccess("EDIT", props.pageUrl)) || props.rowActionsAdditional) && (
                                            <td>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {props.rowActions && (
                                                        <IconButton onClick={e => { setPopAnchorAction(e.currentTarget); setDataSelected(data) }}>
                                                            <MoreVert />
                                                        </IconButton>
                                                    )}
                                                    {(props.onEditInline && checkAccess("EDIT", props.pageUrl)) && (
                                                        dataSelected.id === data.id
                                                            ? (
                                                                <Fragment>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={() => props.onEditInline(dataSelected)}
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={e => {
                                                                            setDataSelected({})
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Fragment>
                                                            )
                                                            : (
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={e => {
                                                                        setDataSelected(data)
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            )
                                                    )}
                                                    {props.rowActionsAdditional && <props.rowActionsAdditional data={data} />}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {Object.keys(sumData).length > 0 && (
                                    <tr>
                                        {props.selectRow && (
                                            <td></td>
                                        )}
                                        {props.columns.map((column: any, j: number) => (
                                            !columnHidden.includes(column.name) && (
                                                column.sumCustomRender
                                                    ? (
                                                        <column.sumCustomRender key={j} data={sumData} />
                                                    )
                                                    : (

                                                        <td>
                                                            <b>
                                                                {sumData[column.key]}
                                                            </b>
                                                        </td>
                                                    )
                                            )
                                        ))}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardContent>
                {props.paginations && (
                    <CardActions className="custom-table-footer">
                        <div className="rows-per-page">
                            <Typography color={"text.disabled"}>
                                show {paginationTake > dataRows ? dataRows : paginationTake} from {dataRows} data
                            </Typography>
                            <FormControl fullWidth size="small">
                                <TextField size="small" select className="options" value={paginationTake} onChange={handleChangeShow}>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </TextField>
                            </FormControl>
                        </div>
                        <Pagination count={Math.ceil(dataRows / paginationTake)} color="primary" page={paginationPage} onChange={(e, value) => setPaginationPage(value)} />
                    </CardActions>
                )}
            </Card>
        </Fragment >
    )
}