import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormAutocomplete from "../../../../shared/FormAutocomplete";
import FormAutocompleteMultiple from "../../../../shared/FormAutocompleteMultiple";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { ID_POSITION_BILLING, ID_POSITION_CSCA, ID_POSITION_MANAGER } from "../../../../state";
import { createEmployee } from "../employee.api";
import EmployeeFormKoj from "./EmployeeFormKoj";

export default function EmployeeAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        name: "",
        nik: "",
        username: "",
        email: "",
        password: "",
        status: "",
        team_ids: "",
        position_id: "",
        shift_id: "",
        koj_list: []
    })

    useEffect(() => {
        dispatch(setTitle("Master Data"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Master Data",
                url: ""
            },
            {
                name: "Employee",
                url: "/master/employee"
            },
            {
                name: "Create Employee",
                url: "/master/employee/add"
            }
        ]))
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        if (name === "position_id") {
            setValues({
                ...values,
                [name]: value,
                team_ids: ""
            })
        }
        else {
            setValues({
                ...values,
                [name]: value,
            })
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createEmployee(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new employee")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Employee
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Employee
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Name" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="NIK" name="nik" value={values.nik} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Username" name="username" value={values.username} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Email" name="email" value={values.email} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Password" name="password" value={values.password} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/position" label="Position" name="position_id" value={values.position_id} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        {[ID_POSITION_CSCA, ID_POSITION_MANAGER, ID_POSITION_BILLING].includes(values.position_id)
                            ? <FormAutocompleteMultiple url="/team" label="Team" name="team_ids" value={values.team_ids} onChange={handleChangeValues} />
                            : <FormAutocomplete url="/team" label="Team" name="team_ids" value={values.team_ids} onChange={handleChangeValues} />
                        }
                        <br />
                        <Divider />
                        <br />
                        <EmployeeFormKoj name="koj_list" value={values.koj_list} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/shift" label="Shift" name="shift_id" value={values.shift_id} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Status" name="status" value={values.status} onChange={handleChangeValues} required />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}