import { createSlice } from '@reduxjs/toolkit'
import { createBillDefault, deleteBillDefault, editBillDefault } from './billDefault.api'

export interface BillDefaultState {
    loading: boolean
}
const initialState: BillDefaultState = {
    loading: false
}

export const billDefaultSlice = createSlice({
    name: 'billDefault',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createBillDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBillDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBillDefault.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteBillDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteBillDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteBillDefault.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editBillDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editBillDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editBillDefault.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default billDefaultSlice.reducer