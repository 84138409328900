import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getKpiEmployee = createAsyncThunk('getKpiEmployee', async (id: string) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/kpi/employee/' + id,
        })
        return response
    }
    catch (error: any) {
        return error.response
    }
})

export const editKpiEmployee = createAsyncThunk('editKpiEmployee', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/kpi/employee',
            data: data
        })
        return response
    }
    catch (error: any) {
        return error.response
    }
})

export const createKpi = createAsyncThunk('createKpi', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/kpi',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteKpi = createAsyncThunk('deleteKpi', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/kpi?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editKpi = createAsyncThunk('editKpi', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/kpi',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})