import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../../shared/FormAutocomplete"
import FormDate from "../../../../shared/FormDate"
import FormNumber from "../../../../shared/FormNumber"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { editAdditionalPoles } from "../additionalPoles.api"

export default function AdditionalPolesApproval(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        id: "",
        name: "",
        type_id: "",
        additional_minutes: "",
        notes: "",
        status_id: "",
        notes_rejection: ""
    })

    useEffect(() => {
        setValues({
            id: props.data.id,
            name: props.data.name,
            type_id: props.data.type_id,
            additional_minutes: props.data.additional_minutes,
            notes: props.data.notes,
            status_id: props.data.status_id,
            notes_rejection: props.data.notes_rejection
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editAdditionalPoles(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit additionalPoles")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormDate label="Date" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    {Boolean(values.id) && (<FormAutocomplete label="Type" name="type_id" value={values.type_id} url="/type/job" onChange={handleChangeValues} required />)}
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Additional Minutes" name="additional_minutes" value={values.additional_minutes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    {Boolean(values.id) && (<FormAutocomplete label="Status" name="status_id" value={values.status_id} url="/status/approval" onChange={handleChangeValues} required />)}
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Rejection Notes" name="notes_rejection" value={values.notes_rejection} onChange={handleChangeValues} required />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Additional Poles
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}