import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../../state";

export default function TakenjobRunning(props: any) {

    const { jobRunning } = useSelector((state: RootState) => state.takenjob)

    const [timerString, setTimerString] = useState("")

    useEffect(() => {
        var startMillis = 0
        var i: any = undefined
        if (jobRunning.id) {
            axios.get(baseUrl + '/time')
                .then((response) => {
                    startMillis = new Date().getTime()
                    const timing = () => {
                        let millisStart = new Date(jobRunning.ts_start).getTime() - (jobRunning.minutes_adjust * 60000)
                        let millisNow = new Date(response.data.data).getTime() + (new Date().getTime() - startMillis)
                        let millisPause = jobRunning.job_pause_value * 1000
                        setTimerString(
                            Math.floor((millisNow - (millisStart + millisPause)) / 3600000) + moment(millisNow - (millisStart + millisPause)).format(':mm:ss')
                        )
                    }
                    if (jobRunning.ts_start) {
                        timing()
                        i = setInterval(() => {
                            timing()
                        }, 1000)
                    }
                    else {
                        setTimerString("00:00:00")
                    }
                })
            return () => {
                clearInterval(i)
            }
        }
    }, [jobRunning])

    if (jobRunning.id) {
        return (
            <div className="running" onClick={props.onClick}>
                <Fragment>
                    <span className="name">
                        {jobRunning.jobnumber_name}
                    </span>
                    <span className="status">
                        Running
                    </span>
                    <span className="name">
                        {timerString}
                    </span>
                </Fragment>
            </div>
        )
    }
    return <></>
}