import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormAutocomplete from "../../../../shared/FormAutocomplete";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { createProjectDefault } from "../projectDefault.api";
import ProjectDefaultFormKoj from "./ProjectDefaultFormKoj";

export default function ProjectDefaultAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        requestor_id: "",
        submittal_id: "",
        district_id: "",
        koj_list: []
    })

    useEffect(() => {
        dispatch(setTitle("Master Data"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Master Data",
                url: ""
            },
            {
                name: "Project Default Config",
                url: "/master/projectDefault"
            },
            {
                name: "Create Project Default Config",
                url: "/master/projectDefault/add"
            }
        ]))
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createProjectDefault(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new projectDefault")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Project Default Config
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Project Default Config
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/requestor" label="Requestor" name="requestor_id" value={values.requestor_id} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/submittal" label="Submittal" name="submittal_id" value={values.submittal_id} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/district" label="District" name="district_id" value={values.district_id} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                        <ProjectDefaultFormKoj name="koj_list" value={values.koj_list} onChange={handleChangeValues} />
                        <br />
                        <Divider />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}