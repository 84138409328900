import { Check } from "@mui/icons-material"
import { Autocomplete, Button, Card, CardContent, Divider, Grid, TextField } from "@mui/material"
import axios from "axios"
import moment from "moment"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../../shared/FormAutocomplete"
import FormDate from "../../../../shared/FormDate"
import FormNumber from "../../../../shared/FormNumber"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { baseUrl } from "../../../../state"
import { editRemoveTarget } from "../removeTarget.api"

export default function RemoveTargetEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        id: "",
        name: "",
        value: "",
        notes: "",
        jobnumber_id: undefined,
        koj_id: undefined,
        quantity_pole: undefined
    })
    const [projectId, setProjectId] = useState("")
    const [projectList, setProjectList] = useState<any>([])
    const [kojSelected, setKojSelected] = useState<any>(undefined)

    useEffect(() => {
        setValues({
            id: props.data.id,
            name: moment(props.data.name).format("YYYY-MM-DD"),
            value: props.data.value,
            notes: props.data.notes,
            jobnumber_id: props.data.jobnumber_id,
            koj_id: props.data.koj_id,
            quantity_pole: props.data.quantity_pole
        })
        setProjectId(props.data.jobnumber_project_id)
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}/project`
        })
            .then((response: any) => {
                let x = response.data.data.map((d: any) => { return { ...d, config_list: JSON.parse(d.config_list) } })
                setProjectList(x)
                setKojSelected(x.filter((d: any) => d.id === props.data.jobnumber_project_id)[0]?.config_list.filter((d: any) => d.koj_id === props.data.koj_id)[0])
            })
    }, [])

    useEffect(() => {
        if (Boolean(projectId)) {
            setKojSelected(undefined)
            setValues({
                ...values
            })
        }
    }, [projectId])

    useEffect(() => {
        if (Boolean(kojSelected?.id)) {
            setValues({
                ...values,
                quantity_pole: (480 / kojSelected.target) * (values.value || 0)
            })
        }
    }, [kojSelected, values.value])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editRemoveTarget({
            ...values,
            koj_id: kojSelected.koj_id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit remove target")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    console.log(kojSelected)

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormDate label="Remove Target Date" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Remove Target" name="value" value={values.value} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/project" label="Project" value={projectId} onChange={(e: any) => setProjectId(e.target.value)} />}
                    <br />
                    {projectId && (
                        <Fragment>
                            <Divider />
                            <br />
                            <FormAutocomplete url={`/project/jobnumber/options?project_id=${projectId}&koj_id=ALL`} label="Job Number" name="jobnumber_id" value={values.jobnumber_id} onChange={handleChangeValues} />
                            <br />
                        </Fragment>
                    )}
                    {(Boolean(values.jobnumber_id) && (Boolean(kojSelected))) && (
                        <Fragment>
                            <Divider />
                            <br />
                            <Grid container spacing={2}>
                                <Grid item md={3}>
                                    <b>KOJ</b>
                                </Grid>
                                <Grid item md={9}>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        id="combo-box-demo"
                                        options={projectList.filter((d: any) => d.id === projectId)[0]?.config_list || []}
                                        getOptionLabel={(option: any) => { return option.koj_name || "" }}
                                        value={kojSelected}
                                        onChange={(e, value) => setKojSelected(value)}
                                        renderInput={(params) => <TextField {...params} size="small" placeholder={"Choose KOJ"} required />}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                        </Fragment>
                    )}
                    <Divider />
                    <br />
                    <FormNumber label="Quantity Pole" name="quantity_pole" value={values.quantity_pole} onChange={handleChangeValues} disabled />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update RemoveTarget
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}