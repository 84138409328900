import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../shared/FormAutocomplete"
import FormText from "../../../shared/FormText"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { editProject } from "../project.api"

export default function ProjectEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        requestor_id: "",
        submittal_id: "",
        district_id: "",
        team_id: "",
        bcode: ""
    })

    useEffect(() => {
        setValues({
            id: props.data.id,
            requestor_id: props.data.requestor_id,
            submittal_id: props.data.submittal_id,
            district_id: props.data.district_id,
            team_id: props.data.team_id,
            bcode: props.data.bcode
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editProject(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit project")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/requestor" label="Requestor" name="requestor_id" value={values.requestor_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/submittal" label="Submittal" name="submittal_id" value={values.submittal_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/district" label="District" name="district_id" value={values.district_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/team" label="Team" name="team_id" value={values.team_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Bcode" name="bcode" value={values.bcode} onChange={handleChangeValues} />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Project
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}