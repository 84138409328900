import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useDispatch } from "react-redux"
import FormNumber from "../../../../shared/FormNumber"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { jobnumberPartialCompleted } from "../../project.api"

export default function JobNumberPartialCompleted(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        quantity_billable: 0,
        quantity_mre: 0,
        quantity_catapult: 0,
        quantity_drawing: 0
    })

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(jobnumberPartialCompleted({
            ...values,
            id: props.data.id,
            date_received: moment(props.data.date_received).format('YYYY-MM-DD hh:mm:ss'),
            date_completed: moment().format('YYYY-MM-DD hh:mm:ss')
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Success")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormNumber label="Quantity Billable" name="quantity_billable" value={values.quantity_billable} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Quantity MRE" name="quantity_mre" value={values.quantity_mre} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Quantity Katapult" name="quantity_catapult" value={values.quantity_catapult} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Quantity Drawing" name="quantity_drawing" value={values.quantity_drawing} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Partial Completed
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}