import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createTypeJob = createAsyncThunk('createTypeJob', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/type/job',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteTypeJob = createAsyncThunk('deleteTypeJob', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/type/job?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editTypeJob = createAsyncThunk('editTypeJob', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/type/job',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})