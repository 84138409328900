import { Card, CardContent, Grid } from "@mui/material";
import { useEffect } from "react";
import 'chart.js/auto';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getChartPrep } from "../dashboard.api";
import { Line } from "react-chartjs-2";

export default function DashboardPrep() {

    const dispatch = useDispatch()

    const { teamList } = useSelector((state: RootState) => state.team)
    const { chartPrepData } = useSelector((state: RootState) => state.dashboard)

    useEffect(() => {
        dispatch<any>(getChartPrep())
    }, [])

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Line
                            data={{
                                labels:
                                    teamList
                                        .filter((team: any) => !(['28F59F16-A734-4999-9B6F-41BD4F219478', '6B9E5D18-5E22-4949-862C-4FD9DDADAE7B', '7F0ECF49-E04E-469A-92DE-78506520A752'].includes(team.id)))
                                        .sort((a: any, b: any) => {
                                            a = a.name.toLowerCase()
                                            b = b.name.toLowerCase()
                                            return a < b ? -1 : a > b ? 1 : 0
                                        })
                                        .map((team: any) => team.name)
                                ,
                                datasets: [
                                    {
                                        label: "Job In",
                                        borderColor: "blue",
                                        data: teamList
                                            .filter((team: any) => !(['28F59F16-A734-4999-9B6F-41BD4F219478', '6B9E5D18-5E22-4949-862C-4FD9DDADAE7B', '7F0ECF49-E04E-469A-92DE-78506520A752'].includes(team.id)))
                                            .sort((a: any, b: any) => {
                                                a = a.name.toLowerCase()
                                                b = b.name.toLowerCase()
                                                return a < b ? -1 : a > b ? 1 : 0
                                            })
                                            .map((team: any) => chartPrepData.filter((chart: any) => chart.team_id === team.id).length > 0 ? chartPrepData.filter((chart: any) => chart.team_id === team.id)[0].total : 0)
                                    },
                                    {
                                        label: "Ready to Prep",
                                        borderColor: "orange",
                                        data: teamList
                                            .filter((team: any) => !(['28F59F16-A734-4999-9B6F-41BD4F219478', '6B9E5D18-5E22-4949-862C-4FD9DDADAE7B', '7F0ECF49-E04E-469A-92DE-78506520A752'].includes(team.id)))
                                            .sort((a: any, b: any) => {
                                                a = a.name.toLowerCase()
                                                b = b.name.toLowerCase()
                                                return a < b ? -1 : a > b ? 1 : 0
                                            })
                                            .map((team: any) => chartPrepData.filter((chart: any) => chart.team_id === team.id).length > 0 ? chartPrepData.filter((chart: any) => chart.team_id === team.id)[0].prep_ready : 0)
                                    },
                                    {
                                        label: "On Progress Prep",
                                        borderColor: "green",
                                        data: teamList
                                            .filter((team: any) => !(['28F59F16-A734-4999-9B6F-41BD4F219478', '6B9E5D18-5E22-4949-862C-4FD9DDADAE7B', '7F0ECF49-E04E-469A-92DE-78506520A752'].includes(team.id)))
                                            .sort((a: any, b: any) => {
                                                a = a.name.toLowerCase()
                                                b = b.name.toLowerCase()
                                                return a < b ? -1 : a > b ? 1 : 0
                                            })
                                            .map((team: any) => chartPrepData.filter((chart: any) => chart.team_id === team.id).length > 0 ? chartPrepData.filter((chart: any) => chart.team_id === team.id)[0].prep_progress : 0)
                                    },
                                    {
                                        label: "Completed Prep",
                                        borderColor: "yellow",
                                        data: teamList
                                            .filter((team: any) => !(['28F59F16-A734-4999-9B6F-41BD4F219478', '6B9E5D18-5E22-4949-862C-4FD9DDADAE7B', '7F0ECF49-E04E-469A-92DE-78506520A752'].includes(team.id)))
                                            .sort((a: any, b: any) => {
                                                a = a.name.toLowerCase()
                                                b = b.name.toLowerCase()
                                                return a < b ? -1 : a > b ? 1 : 0
                                            })
                                            .map((team: any) => chartPrepData.filter((chart: any) => chart.team_id === team.id).length > 0 ? chartPrepData.filter((chart: any) => chart.team_id === team.id)[0].prep_completed : 0)
                                    }
                                ]
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}