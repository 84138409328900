import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createBillDefault = createAsyncThunk('createBillDefault', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/bill/default',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteBillDefault = createAsyncThunk('deleteBillDefault', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/bill/default?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editBillDefault = createAsyncThunk('editBillDefault', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/bill/default',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})