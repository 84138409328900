import { createSlice } from '@reduxjs/toolkit'
import { addTracker, deleteTracker, editTracker, getTracker } from './tracker.api'

export interface TrackerState {
    loading: boolean,
    trackerList: any
}
const initialState: TrackerState = {
    loading: false,
    trackerList: []
}

export const trackerSlice = createSlice({
    name: 'tracker',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getTracker.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTracker.fulfilled, (state, action) => {
            state.loading = false
            state.trackerList = action.payload.data.data
        })
        builder.addCase(getTracker.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editTracker.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTracker.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTracker.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(addTracker.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addTracker.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(addTracker.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteTracker.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTracker.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTracker.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default trackerSlice.reducer