import { createSlice } from '@reduxjs/toolkit'
import { editEmployeeJob } from './report.api'

export interface ReportState {
    loading: boolean
}
const initialState: ReportState = {
    loading: false
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(editEmployeeJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editEmployeeJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editEmployeeJob.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default reportSlice.reducer