import { createSlice } from '@reduxjs/toolkit'
import { createKpi, deleteKpi, editKpi, editKpiEmployee, getKpiEmployee } from './kpi.api'

export interface KpiState {
    loading: boolean
}
const initialState: KpiState = {
    loading: false
}

export const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getKpiEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getKpiEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getKpiEmployee.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editKpiEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editKpiEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editKpiEmployee.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createKpi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createKpi.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createKpi.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteKpi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteKpi.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteKpi.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editKpi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editKpi.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editKpi.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default kpiSlice.reducer