import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function AbsentReminder() {

    const navigate = useNavigate()

    useEffect(() => {
        fireReminder()
        const interval = setInterval(() => {
            if (["08:00", "13:00"].includes(moment().format("hh:mm"))) {
                fireReminder()
            }
        }, 6000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const fireReminder = () => {
        Swal.fire({
            icon: "warning",
            title: "Jangan lupa absen",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Absent Page",
            cancelButtonText: "Ok"
        })
            .then((value) => {
                if (value.isConfirmed) {
                    navigate("/absent/absent")
                }
            })
    }

    return <></>
}