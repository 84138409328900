import { createSlice } from '@reduxjs/toolkit'
import { createTeam, deleteTeam, editTeam, getTeam } from './team.api'

export interface TeamState {
    loading: boolean,
    teamList: any
}
const initialState: TeamState = {
    loading: false,
    teamList: []
}

export const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
        builder.addCase(getTeam.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTeam.fulfilled, (state, action) => {
            state.loading = false
            state.teamList = action.payload.data.data
        })
        builder.addCase(getTeam.rejected, (state, action) => {
            state.loading = false
        })
        
        builder.addCase(createTeam.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTeam.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTeam.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteTeam.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTeam.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTeam.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editTeam.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTeam.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTeam.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default teamSlice.reducer