import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createStatusJobnumber = createAsyncThunk('createStatusJobnumber', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/status/jobnumber',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteStatusJobnumber = createAsyncThunk('deleteStatusJobnumber', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/status/jobnumber?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editStatusJobnumber = createAsyncThunk('editStatusJobnumber', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/status/jobnumber',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})