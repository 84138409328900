import { createSlice } from '@reduxjs/toolkit'
import { createDistrict, deleteDistrict, editDistrict } from './district.api'

export interface DistrictState {
    loading: boolean
}
const initialState: DistrictState = {
    loading: false
}

export const districtSlice = createSlice({
    name: 'district',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createDistrict.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createDistrict.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDistrict.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteDistrict.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteDistrict.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDistrict.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editDistrict.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editDistrict.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editDistrict.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default districtSlice.reducer