import { Typography } from "@mui/material";
import moment from "moment";
import { Fragment, useState } from "react";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import WorksheetDetails from "./WorksheetDetails";

export default function WorksheeMain() {

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalDetails, setModalDetails] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)

    const handleCloseModalDetails = (isReload: boolean) => {
        setModalDetails(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    return (
        <Fragment>
            <CustomModal
                open={modalDetails}
                onClose={() => handleCloseModalDetails(true)}
                title="Taken Job Detail"
                component={
                    <WorksheetDetails onClose={() => handleCloseModalDetails(true)} data={dataSelected} />
                }
            />
            <Typography variant="h4">Main Job</Typography>
            <br />
            <CustomTable
                url={"/employee/job"}
                columns={[
                    {
                        name: "KOJ Status",
                        key: "job_calculation_status"
                    },
                    {
                        name: "Job Number",
                        key: "jobnumber_name"
                    },
                    {
                        name: "Requestor",
                        key: "jobnumber_project_requestor_name"
                    },
                    {
                        name: "Submittal",
                        key: "jobnumber_project_submittal_name"
                    },
                    {
                        name: "District",
                        key: "jobnumber_project_district_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span style={{ backgroundColor: props.data.koj_color }}>{props.data.koj_name}</span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Received",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_received ? moment(props.data.jobnumber_date_received).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Due Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_due ? moment(props.data.jobnumber_date_due).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Source Job",
                        key: "source_job"
                    },
                    {
                        name: "QTY Request",
                        key: "job_calculation_total_request"
                    },
                    {
                        name: "QTY Get",
                        key: "quantity_get"
                    }
                ]}
                timeGetData={timeGetData}
                onClickRow={(data: any) => { setDataSelected(data); setModalDetails(true) }}
            />
        </Fragment>
    )
}