import { Button, Container } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import CustomTable from "../../../shared/CustomTable";

export default function ReportIndividualJob() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Individual Report",
                url: ""
            },
            {
                name: "Job Report",
                url: "/individualreport/job"
            }
        ]))
    }, [])

    return (
        <Container maxWidth='xl'>
            <CustomTable
                url={"/report/individual/job"}
                filterDate={true}
                columns={[
                    {
                        name: "Progress",
                        customRender: (props: any) => {
                            if (props.data.ts_start) {
                                if (props.data.ts_stop) {
                                    return (
                                        <td>
                                            <span className="status-progress complete">
                                                Complete
                                            </span>
                                        </td>
                                    )
                                }
                                else {
                                    if (props.data.job_pause_status === 1) {
                                        return (
                                            <td>
                                                <span className="status-progress hold">
                                                    Hold
                                                </span>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td>
                                            <span className="status-progress running">
                                                Running
                                            </span>
                                        </td>
                                    )
                                }
                            }
                            return (
                                <td>
                                    <span className="status-progress hold">
                                        Hold
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Number",
                        key: "jobnumber_name"
                    },
                    {
                        name: "Team",
                        key: "jobnumber_project_team_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name"
                    },
                    {
                        name: "QTY Req",
                        key: "job_calculation_total_request"
                    },
                    {
                        name: "QTY Get",
                        key: "quantity_get"
                    },
                    {
                        name: "Extra Pole",
                        key: "pole_extra"
                    },
                    {
                        name: "Already Created",
                        key: "pole_already_created"
                    },
                    {
                        name: "To UG",
                        key: "pole_to_ug"
                    },
                    {
                        name: "Minutes",
                        key: "job_calculation_total_minutes"
                    },
                    {
                        name: "Completion Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {moment(props.data.ts_stop).format('DD-MM-YYYY')}
                                </td>
                            )
                        }
                    }
                ]}
                export={true}
            />
        </Container>
    )
}