import { Button, Container } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import JobGet from "../../project/component/detail/JobGet";
import { deleteTransferJob } from "../transferJob.api";


export default function TransferJob() {

    const dispatch = useDispatch()

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalJobGet, setModalJobGet] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)

    useEffect(() => {
        dispatch(setTitle("Transfer Job"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([]))
    }, [])


    const handleDelete = () => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to decline this job?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteTransferJob(dataSelected.job_calculation_transfer_id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("Job successfully declined")
                                setTimeGetData(new Date().getTime())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    const handleCloseModalJobGet = (isReload: boolean) => {
        setModalJobGet(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    return (
        <Container maxWidth="xl">
            <CustomModal
                open={modalJobGet}
                onClose={handleCloseModalJobGet}
                title="Get Job"
                component={
                    <JobGet onClose={handleCloseModalJobGet} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/project/jobnumber/job/transfer"}
                filterDate={true}
                columns={[
                    {
                        name: "Order Number",
                        key: "jobnumber_number_order"
                    },
                    {
                        name: "Origin Team",
                        key: "jobnumber_project_team_name"
                    },
                    {
                        name: "Target Team",
                        key: "transfer_team_name"
                    },
                    {
                        name: "Job Number",
                        key: "jobnumber_number_job"
                    },
                    {
                        name: "Requestor",
                        key: "jobnumber_project_requestor_name"
                    },
                    {
                        name: "Submittal",
                        key: "jobnumber_project_submittal_name"
                    },
                    {
                        name: "District",
                        key: "jobnumber_project_district_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span style={{ backgroundColor: props.data.koj_color }}>{props.data.koj_name}</span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Status KOJ",
                        key: "job_calculation_status"
                    },
                    {
                        name: "Project",
                        key: "jobnumber_project_name"
                    },
                    {
                        name: "Job Received",
                        key: "jobnumber_date_received",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_received ? moment(props.data.jobnumber_date_received).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Due Date",
                        key: "jobnumber_date_due",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_due ? moment(props.data.jobnumber_date_due).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Source Job",
                        key: "source_job"
                    },
                    {
                        name: "QTY Request",
                        key: "job_calculation_total_request"
                    },
                    {
                        name: "QTY Get",
                        key: "job_calculation_total_get"
                    },
                    {
                        name: "QTY Available",
                        key: "job_calculation_total_available"
                    },
                    {
                        name: "Req Number",
                        key: "jobnumber_number_auto_request"
                    },
                    {
                        name: "Rev Number",
                        key: "jobnumber_number_auto_revision"
                    },
                    {
                        name: "Act Number",
                        key: "number_auto_activity"
                    },
                    {
                        name: "New",
                        key: "pole_new"
                    },
                    {
                        name: "Extra Pole",
                        key: "pole_extra"
                    },
                    {
                        name: "Already Created",
                        key: "pole_already_created"
                    },
                    {
                        name: "New PLA QTY",
                        key: "pole_new_pla"
                    },
                    {
                        name: "To UG",
                        key: "pole_to_ug"
                    },
                    {
                        name: "Missing Fielding",
                        key: "pole_missing_fielding"
                    },
                    {
                        name: "Revision QTY",
                        key: "quantity_revision"
                    },
                    {
                        name: "Job Minutes",
                        key: "job_calculation_total_minutes"
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Completed Date",
                        key: "jobnumber_date_completed"
                    },
                    {
                        name: "Status",
                        key: "jobnumber_status_name"
                    }
                ]}
                rowActionsAdditional={(props: any) => {
                    let cqmData = localStorage.getItem('cqmData') || "{}"
                    let kojList = JSON.parse(cqmData).koj_list.map((koj: any) => {
                        if (koj.is_active === 1 || koj.is_additional === 1) {
                            return koj.koj_id
                        }
                    })
                    return (
                        <Fragment>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={!kojList.includes(props.data.koj_id) || props.data.job_calculation_total_get > 0}
                                onClick={e => {
                                    setDataSelected(props.data)
                                    handleDelete()
                                }}>
                                Decline Job
                            </Button>
                            {checkAccess("GET_JOB") &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={
                                        !kojList.includes(props.data.koj_id) ||
                                        props.data.job_calculation_total_available <= 0
                                    }
                                    onClick={e => {
                                        setModalJobGet(true)
                                        setDataSelected(props.data)
                                    }}>
                                    Get Job
                                </Button>
                            }
                        </Fragment>
                    )
                }}
                timeGetData={timeGetData}
            />
        </Container>
    )
}