import { AccountCircle, ArrowBack, ExpandMore, NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, IconButton, Link, List, ListItemButton, ListItemText, Popover } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLoading from "../../shared/CustomLoading";
import { RootState } from "../../store";

export default function Header() {

    const navigate = useNavigate()

    const { isShowBack, title, breadcumbs } = useSelector((state: RootState) => state.containers)

    const [popAnchorAction, setPopAnchorAction] = useState<HTMLButtonElement | null>(null);

    const handleLogout = () => {
        localStorage.removeItem('cqmToken')
        localStorage.removeItem('cqmData')
        localStorage.removeItem('cqmBorn')
        navigate("/login")
    }

    return (
        <header>
            <CustomLoading />
            <Popover
                open={Boolean(popAnchorAction)}
                anchorEl={popAnchorAction}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorAction(null)}
            >
                <List>
                    <ListItemButton onClick={() => navigate("/profile")}>
                        <ListItemText>My Profile</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemText>Logout</ListItemText>
                    </ListItemButton>
                </List>
            </Popover>
            <div className="title">
                {isShowBack && (
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </IconButton>
                )}
                <span>
                    {title}
                </span>
                <Breadcrumbs
                    separator={<NavigateNext fontSize="inherit" />}
                    aria-label="breadcrumb"
                >
                    {(breadcumbs.length > 1) && breadcumbs.map((breadcumb: any) => (
                        <Link key={breadcumb.name} underline="hover" fontSize={10} color="inherit" onClick={() => navigate(breadcumb.url)}>
                            {breadcumb.name}
                        </Link>
                    ))}
                </Breadcrumbs>
            </div>
            <div className="profile">
                <div className="profile">
                    <AccountCircle />
                </div>
                <div>
                    <b className="name">{JSON.parse(localStorage.getItem('cqmData') || "{}").name}</b>
                    <span className="role">{JSON.parse(localStorage.getItem('cqmData') || "{}").position_name}</span>
                </div>
                <IconButton size="small" onClick={e => setPopAnchorAction(e.currentTarget)}>
                    <ExpandMore />
                </IconButton>
            </div>
        </header>
    )
}