import { createSlice } from '@reduxjs/toolkit'
import { createTypeJob, deleteTypeJob, editTypeJob } from './typeJob.api'

export interface TypeJobState {
    loading: boolean
}
const initialState: TypeJobState = {
    loading: false
}

export const typeJobSlice = createSlice({
    name: 'typeJob',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createTypeJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTypeJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTypeJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteTypeJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTypeJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTypeJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editTypeJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTypeJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTypeJob.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default typeJobSlice.reducer