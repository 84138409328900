import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createSubmittal = createAsyncThunk('createSubmittal', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/submittal',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteSubmittal = createAsyncThunk('deleteSubmittal', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/submittal?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editSubmittal = createAsyncThunk('editSubmittal', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/submittal',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})