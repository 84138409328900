import { Autocomplete, Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import { RootState } from "../../../store";
import { getTeamList } from "../bill.api";
import { setTeamSelected } from "../bill.reducer";
import BillMain from "./BillMain";
import BillRev from "./BillRev";

export default function Bill() {

    const dispatch = useDispatch()

    const { teamSelected } = useSelector((state: RootState) => state.bill)

    const [isTeamsLoaded, setIsTeamLoaded] = useState(false)
    const [teamOptions, setTeamOptions] = useState<any>([])

    useEffect(() => {
        dispatch(setTitle("Bill Job"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([]))
        dispatch<any>(getTeamList())
            .unwrap()
            .then((response: any) => {
                let cqmDataString = localStorage.getItem("cqmData")
                let cqmData = cqmDataString ? JSON.parse(cqmDataString) : {}
                let teamIds = cqmData.team_ids
                setTeamOptions([{ id: '', name: 'All' }].concat(response.data.data.filter((team: any) => teamIds.includes(team.id))))
                dispatch(setTeamSelected({ id: '', name: 'All' }))
            })
            .finally(() => {
                setIsTeamLoaded(true)
            })
    }, [])

    return (
        <Container maxWidth="xl">
            {isTeamsLoaded && (
                <Grid container>
                    <Grid item md={2}>
                        <Autocomplete
                            options={teamOptions}
                            getOptionLabel={(option: any) => { return option.name || "" }}
                            value={teamSelected}
                            onChange={(e, value) => dispatch(setTeamSelected(value))}
                            renderInput={(params) => <TextField {...params} size="small" placeholder={"Choose team"} />}
                        />
                    </Grid>
                </Grid>
            )}
            <br />
            <Typography variant="h4">New</Typography>
            <br />
            <BillMain />
            <br />
            <Typography variant="h4">Revision</Typography>
            <br />
            <BillRev />
        </Container >
    )
}