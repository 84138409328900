import { Button } from "@mui/material";
import moment from "moment";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import checkAccess from "../../../shared/checkAccess";
import CustomTable from "../../../shared/CustomTable";
import { RootState } from "../../../store";

export default function BillMain() {

    const navigate = useNavigate()

    const { teamSelected } = useSelector((state: RootState) => state.bill)

    const handleCreateBill = (dataIdSelected: any, dateStart: string, dateEnd: string) => {
        if (dataIdSelected.length > 0) {
            navigate(`/bill/create?datestart=${dateStart}&dateend=${dateEnd}&ids=${dataIdSelected.toString()}`)
        }
        else {
            Swal.fire({
                title: "Select at least one job",
                icon: "error"
            })
        }
    }

    return (
        <Fragment>
            <CustomTable
                url={"/project/jobnumber/bill"}
                additionalParams={teamSelected.id ? `&team_id=${teamSelected.id}` : ``}
                filterDate={true}
                columns={[
                    {
                        name: "Project",
                        key: "project_name"
                    },
                    {
                        name: "Job Received",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.date_received ? moment(props.data.date_received).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Number",
                        key: "number_job"
                    },
                    {
                        name: "Requestor",
                        key: "project_requestor_name"
                    },
                    {
                        name: "Submittal",
                        key: "project_submittal_name"
                    },
                    {
                        name: "District",
                        key: "project_district_name"
                    },
                    {
                        name: "Done By",
                        key: "project_team_name"
                    },
                    {
                        name: "Type",
                        customRender: (props: any) => {
                            let jobList = props.data.job_list ? JSON.parse(props.data.job_list) : []
                            return (
                                <td>
                                    <span>
                                        {jobList.length > 0 ? jobList[0].type_name : ''}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "QTY Request",
                        key: "quantity_request"
                    },
                    {
                        name: "Completed Date",
                        key: "date_completed_final",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.date_completed_final ? moment(props.data.date_completed_final).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Minutes",
                        key: "job_calculation_total_minutes"
                    },
                    {
                        name: "Billable QTY",
                        key: "quantity_billable_total"
                    },
                    {
                        name: "MRE QTY",
                        key: "quantity_mre_total"
                    },
                    {
                        name: "Katapult QTY",
                        key: "quantity_catapult_total"
                    },
                    {
                        name: "Drawing QTY",
                        key: "quantity_drawing_total"
                    },
                    {
                        name: "PLA QTY",
                        key: "pla"
                    }
                ]}
                columnHidden={["Job Received", "QTY Request", "Completed Date", "Job Minutes", "Type"]}
                headerActions={(props: any) => (
                    <Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate(`/bill/history`)}
                        >
                            History Bill
                        </Button>
                        {checkAccess("CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => handleCreateBill(props.dataIdSelected, props.dateStart, props.dateEnd)}
                            >
                                Create Bill
                            </Button>
                        }
                    </Fragment>
                )}
                selectRow={true}
            />
        </Fragment>
    )
}