import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../state";
import notificationError from "./notificationError";
import { useDispatch } from "react-redux";
import { setLoading } from "../containers/containers.reducer";

export default function FormInlineAutocomplete(props: any) {

    const dispatch = useDispatch()

    const [options, setOptions] = useState<any>([])
    const [optionsSelected, setOptionsSelected] = useState<any>({})

    useEffect(() => {
        dispatch(setLoading(true))
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + props.url
        })
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setLoading(false))
                    setOptions(response.data.data)
                    if (props.value !== "") setOptionsSelected(response.data.data.filter((data: any) => data.id === props.value)[0])
                }
                else {
                    dispatch(setLoading(false))
                    notificationError(response)
                }
            })
            .catch((error: any) => {
                dispatch(setLoading(false))
                notificationError(error.response)
            })
    }, [props.url])

    const handleChange = (e: any, value: any) => {
        setOptionsSelected(value)
        props.onChange({
            target: {
                name: props.name,
                value: value.id
            }
        })
    }

    return (
        <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option: any) => { return option.name || "" }}
            value={optionsSelected}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} style={{ minWidth: 200 }} size="small" label={props.label} placeholder={"Choose " + props.label} disabled={props.disabled} required />}
            disabled={props.disabled}
        />
    )
}