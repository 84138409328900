import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getChartQc = createAsyncThunk('getChartQc', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/chart/qc',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getChartPrep = createAsyncThunk('getChartPrep', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/chart/prep',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})