import { createSlice } from '@reduxjs/toolkit'
import { createSubmittal, deleteSubmittal, editSubmittal } from './submittal.api'

export interface SubmittalState {
    loading: boolean
}
const initialState: SubmittalState = {
    loading: false
}

export const submittalSlice = createSlice({
    name: 'submittal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createSubmittal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSubmittal.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSubmittal.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteSubmittal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSubmittal.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSubmittal.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editSubmittal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSubmittal.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSubmittal.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default submittalSlice.reducer