import { Container, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer"
import AbsentAll from "./AbsentAll"
import AbsentSelf from "./AbsentSelf"

export default function Absent() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Absent"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Absent",
                url: ""
            },
            {
                name: "Absent",
                url: "/absent/absent"
            }
        ]))
    }, [])

    return (
        <Container maxWidth="xl">
            <br />
            <AbsentSelf />
            <br />
            <Typography variant="h4">All Absent</Typography>
            <br />
            <AbsentAll />
        </Container >
    )
}