import { Close, DragIndicator } from "@mui/icons-material";
import { Button, Card, CardContent, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../../state";
import notificationError from "../../../../shared/notificationError";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../containers/containers.reducer";

export default function EmployeeFormKoj(props: any) {

    const dispatch = useDispatch()

    const [options, setOptions] = useState<any>([])
    const [optionsSelected, setOptionsSelected] = useState<any>([])
    const [startDragY, setStartDragY] = useState(0)

    useEffect(() => {
        dispatch(setLoading(true))
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/koj'
        })
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setLoading(false))
                    setOptions(response.data.data)
                    setOptionsSelected(props.value || [])
                }
                else {
                    dispatch(setLoading(false))
                    notificationError(response)
                }
            })
            .catch((error: any) => {
                dispatch(setLoading(false))
                notificationError(error.response)
            })
    }, [])

    const handleSelect = (item: any) => {
        let newSelected = [...optionsSelected, { koj_id: item.id, koj_name: item.name, order_value: optionsSelected.length, is_active: 0, is_additional: 0 }]
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleUnselect = (item: any) => {
        let newSelected = optionsSelected.filter((option: any) => option.koj_id !== item.koj_id)
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleDrag = (e: any, lastIndex: number) => {
        const { type, clientY } = e
        if (type === "dragstart") {
            setStartDragY(clientY)
        }
        else if (type === "dragend") {
            let moveIndex = clientY - startDragY
            if (moveIndex > 0) {
                moveIndex = Math.floor(moveIndex / 34)
            }
            else {
                moveIndex = Math.floor((moveIndex * -1) / 50) * -1
            }
            let newSelected: any = []
            if (moveIndex > 0) {
                newSelected = optionsSelected.map((option: any) => {
                    if (option.order_value === lastIndex) {
                        return {
                            ...option,
                            order_value: lastIndex + moveIndex
                        }
                    }
                    else if (option.order_value <= lastIndex + moveIndex) {
                        return {
                            ...option,
                            order_value: option.order_value - 1
                        }
                    }
                    else {
                        return {
                            ...option
                        }
                    }
                })
            }
            else {
                newSelected = optionsSelected.map((option: any) => {
                    if (option.order_value === lastIndex) {
                        return {
                            ...option,
                            order_value: lastIndex + moveIndex
                        }
                    }
                    else if (option.order_value >= lastIndex + moveIndex) {
                        return {
                            ...option,
                            order_value: option.order_value + 1
                        }
                    }
                    else {
                        return {
                            ...option
                        }
                    }
                })
            }
            setOptionsSelected(newSelected)
            props.onChange({
                target: {
                    name: 'koj_list',
                    value: newSelected
                }
            })
        }
    }

    const handleClickActive = (id: string) => {
        let newSelected = optionsSelected.map((option: any) => {
            if (option.koj_id === id) {
                return {
                    ...option,
                    is_active: !option.is_active ? 1 : 0
                }
            }
            return {
                ...option
            }
        })
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleClickAdditional = (id: string) => {
        let newSelected = optionsSelected.map((option: any) => {
            if (option.koj_id === id) {
                return {
                    ...option,
                    is_additional: !option.is_additional ? 1 : 0
                }
            }
            return {
                ...option
            }
        })
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    return (
        <>
            <Grid container spacing={2} className="form-koj">
                <Grid item md={3}>
                    <b>Training</b>
                </Grid>
                <Grid item md={9}>
                    <Grid container spacing={2}>
                        <Grid item md={7}>
                            <Card variant="outlined">
                                <CardContent>
                                    KOJ Lists
                                    <br />
                                    <br />
                                    {options.map((item: any) => (
                                        <Button
                                            style={{ marginTop: 5, marginBottom: 5 }}
                                            variant="contained"
                                            color="secondary"
                                            key={item.koj_id}
                                            onClick={() => handleSelect(item)}
                                            disabled={optionsSelected.map((koj: any) => { return koj.koj_id }).includes(item.id)}
                                        >
                                            {item.name}
                                        </Button>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Card variant="outlined">
                                <CardContent>
                                    Selected KOJ
                                    <br />
                                    <ul className="koj-list">
                                        {optionsSelected.sort((a: any, b: any) => { return a.order_value - b.order_value }).map((item: any) => (
                                            <li key={item.koj_id} draggable onDragStart={(e) => handleDrag(e, item.order_value)} onDragEnd={(e) => handleDrag(e, item.order_value)}>
                                                <div>
                                                    <DragIndicator />
                                                    {item.koj_name}
                                                </div>
                                                <Close onClick={() => handleUnselect(item)} />
                                            </li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item md={3}>
                    <b>Active Experience</b>
                </Grid>
                <Grid item md={9}>
                    <Grid container spacing={2}>
                        <Grid item md={7}>
                            <Card variant="outlined">
                                <CardContent>
                                    KOJ Lists
                                    <br />
                                    <br />
                                    {optionsSelected.sort((a: any, b: any) => { return a.order_value - b.order_value }).map((item: any) => (
                                        <Button
                                            style={{ marginTop: 5, marginBottom: 5 }}
                                            variant="contained"
                                            color={item.is_active ? "primary" : "secondary"}
                                            key={item.koj_id}
                                            onClick={() => handleClickActive(item.koj_id)}
                                        >
                                            {item.koj_name}
                                        </Button>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item md={3}>
                    <b>Additional Experience</b>
                </Grid>
                <Grid item md={9}>
                    <Grid container spacing={2}>
                        <Grid item md={7}>
                            <Card variant="outlined">
                                <CardContent>
                                    KOJ Lists
                                    <br />
                                    <br />
                                    {optionsSelected.sort((a: any, b: any) => { return a.order_value - b.order_value }).map((item: any) => (
                                        <Button
                                            style={{ marginTop: 5, marginBottom: 5 }}
                                            variant="contained"
                                            color={item.is_additional ? "primary" : "secondary"}
                                            key={item.koj_id}
                                            onClick={() => handleClickAdditional(item.koj_id)}
                                        >
                                            {item.koj_name}
                                        </Button>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}