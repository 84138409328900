import { Button, Card, CardContent, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editKpi, editKpiEmployee, getKpiEmployee } from "../kpi.api";
import moment from "moment";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

interface Value {
    id: string,
    employee_id: string,
    employee_name: string,
    employee_nik: string,
    value_achievement: string,
    value: string,
    note: string
}

export default function KpiEdit(props: { data: any }) {

    const dispatch = useDispatch<any>()

    const [name, setName] = useState(props.data.name)
    const [valuesOriginal, setValuesOriginal] = useState<Value[]>([])
    const [values, setValues] = useState<Value[]>([])
    const [sortByColumn, setSortByColumn] = useState("")
    const [sortByAsc, setSortByAsc] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        dispatch(getKpiEmployee(props.data.id))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    const valuesTemp = response.data.data.map((value: any) => {
                        return {
                            ...value,
                            value_achievement: String(value.value_achievement),
                            value: String(value.value)
                        }
                    })
                    setValuesOriginal(valuesTemp)
                    setValues(valuesTemp)
                }
            })
    }

    const handleChangeValues = (employeeId: string, e: any) => {
        const { name, value } = e.target
        setValues(values.map(last => {
            if (last.employee_id === employeeId) {
                if (name === "value" && (value < 0 || value > 100)) {
                    return last
                }
                return {
                    ...last,
                    [name]: value
                }
            }
            return last
        }))
    }

    const handleSave = async () => {
        for (const i in values) {
            if (Object.prototype.hasOwnProperty.call(values, i)) {
                const value = values[i];
                if (value !== valuesOriginal[i]) {
                    await dispatch(editKpiEmployee({
                        id: value.id,
                        value_achievement: value.value_achievement,
                        value: value.value,
                        note: value.note
                    }))
                        .unwrap()
                }
            }
        }
        getData()
    }

    const handleChangePeriod = (e: any) => {
        setName(e.target.value)
        dispatch(editKpi({
            id: props.data.id,
            name: e.target.value
        }))
    }

    const columns = [
        {
            name: "Name",
            key: "employee_name"
        },
        {
            name: "NIK",
            key: "employee_nik"
        },
        {
            name: "Achievement",
            key: "value_achievement"
        },
        {
            name: "KPI",
            key: "value"
        },
        {
            name: "Note",
            key: "note"
        }
    ]

    const periods = [];
    const currentDate = moment();
    const currentYear = currentDate.get("year");
    const currentMonth = currentDate.get("month") + 1

    for (let year = currentYear; year >= 2022; year--) {
        for (let month = 12; month >= 1; month--) {
            if (year === currentYear && month > currentMonth) {
                continue;
            }
            const formattedMonth = month.toString().padStart(2, "0");
            const period = `${year}/${formattedMonth}`;
            periods.push(period);
        }
    }

    return (
        <Card>
            <CardContent sx={{ paddingTop: 0 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography fontWeight={"bold"}>KPI</Typography>
                        <Typography>{props.data.head_name}</Typography>
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel>Period</InputLabel>
                            <Select
                                size="small"
                                label="Period"
                                value={name}
                                onChange={handleChangePeriod}
                            >
                                {periods.map((period) => (
                                    <MenuItem key={period} value={period}>
                                        {period}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow sx={{ bgcolor: "white", position: "sticky", top: 0, zIndex: 1 }}>
                            {columns.map((column, i) => (
                                <TableCell key={column.key} sx={{ fontWeight: "bold" }}>
                                    {column.name}
                                    {Boolean(column.key) && (
                                        sortByColumn === column.key
                                            ? (
                                                <Fragment>
                                                    {sortByAsc
                                                        ? (
                                                            <IconButton color="primary" onClick={() => { setSortByColumn(column.key); setSortByAsc(false) }}>
                                                                <ArrowDropUp />
                                                            </IconButton>
                                                        )
                                                        : (
                                                            <IconButton color="primary" onClick={() => { setSortByColumn(column.key); setSortByAsc(true) }}>
                                                                <ArrowDropDown />
                                                            </IconButton>
                                                        )
                                                    }
                                                </Fragment>
                                            )
                                            : (
                                                <IconButton onClick={() => { setSortByColumn(column.key); setSortByAsc(false) }}>
                                                    <ArrowDropDown />
                                                </IconButton>
                                            )
                                    )}
                                    {i === columns.length - 1 && (
                                        <Button
                                            sx={{ marginLeft: "auto" }}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disabled={valuesOriginal === values}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values
                            .sort((a: any, b: any) => {
                                if (sortByColumn !== "") {
                                    return sortByAsc ? a[sortByColumn].localeCompare(b[sortByColumn]) : b[sortByColumn].localeCompare(a[sortByColumn]);
                                }
                                return 0;
                            })
                            .map(value => (
                                <TableRow key={value.employee_id}>
                                    <TableCell>{value.employee_name}</TableCell>
                                    <TableCell>{value.employee_nik}</TableCell>
                                    <TableCell sx={{ width: "150px" }}>
                                        <TextField
                                            size="small"
                                            type="number"
                                            name="value_achievement"
                                            value={value.value_achievement}
                                            onChange={e => handleChangeValues(value.employee_id, e)}
                                            InputProps={{
                                                endAdornment: '%'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ width: "150px" }}>
                                        <TextField
                                            size="small"
                                            type="number"
                                            name="value"
                                            value={value.value}
                                            onChange={e => handleChangeValues(value.employee_id, e)}
                                            InputProps={{
                                                endAdornment: '%'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            multiline
                                            fullWidth
                                            size="small"
                                            name="note"
                                            value={value.note}
                                            onChange={e => handleChangeValues(value.employee_id, e)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}