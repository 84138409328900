// const baseUrl = "http://localhost:4001"
const baseUrl = "https://api-cqm.csynergy.com"

const ID_STATUS_APPROVAL_REQUEST = "78DDF83B-6C8F-4748-B053-100EBC8B91AE"
const ID_STATUS_APPROVAL_APPROVED = "766EBE93-61CF-4F43-8C6D-088F5C05F79A"

const ID_POSITION_CSCA = "02371096-7520-40B5-8989-FC83B9084968"
const ID_POSITION_MANAGER = "9A5D395E-6F01-45F3-A793-169E720BF53E"
const ID_POSITION_BILLING = "9DB765E4-0AAA-4FEC-AE2B-A03CF31805FF"

const ID_KOJ_QC = "018D1A60-63A4-4D47-9563-652E9F151E18"
const ID_KOJ_QC_MRE = "D3A969D0-D37F-4FC1-BBC8-4C53B9542D66"
const ID_KOJ_QC_AIDS = "8CBEAB8F-09E1-45AB-AAC7-193DF273648A"

const ID_SHIFT_PAGI = "F79BBB3B-9479-4BB8-8B09-8460F9B02B9F"

export {
    baseUrl,

    ID_STATUS_APPROVAL_REQUEST,
    ID_STATUS_APPROVAL_APPROVED,

    ID_POSITION_CSCA,
    ID_POSITION_MANAGER,
    ID_POSITION_BILLING,

    ID_KOJ_QC,
    ID_KOJ_QC_MRE,
    ID_KOJ_QC_AIDS,

    ID_SHIFT_PAGI
}