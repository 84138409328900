import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./assets/style.scss"
import { useEffect } from "react";

import Login from "./pages/login/component/Login";

import Layout from "./containers/components/Layout";

import Dashboard from "./pages/dashboard/component/Dashboard";

import Project from "./pages/project/component/Project";
import ProjectAdd from "./pages/project/component/ProjectAdd";
import JobNumber from "./pages/project/component/detail/JobNumber";
import JobNumberAddRequest from "./pages/project/component/detail/JobNumberAddRequest";
import JobNumberAddRevision from "./pages/project/component/detail/JobNumberAddRevision";
import JobNumberJobAddActivity from "./pages/project/component/detail/JobNumberJobAddActivity";

import JobManagement from "./pages/project/component/JobManagement";

import ProjectArchive from "./pages/project/archive/ProjectArchive";
import JobNumberArchive from "./pages/project/archive/JobNumberArchive";

import AdditionalPoles from "./pages/project/additionalPoles/component/AdditionalPoles";
import AdditionalPolesAdd from "./pages/project/additionalPoles/component/AdditionalPolesAdd";

import Tracker from "./pages/tracker/component/Tracker";

import TransferJob from "./pages/transferjob/component/TransferJob";

import Worksheet from "./pages/worksheet/component/Worksheet";

import Takenjob from "./pages/takenjob/component/Takenjob";

import Bill from "./pages/bill/component/Bill";
import Billed from "./pages/bill/component/Billed";
import BilledCreate from "./pages/bill/component/BilledCreate";
import InvoiceStatus from "./pages/invoiceStatus/component/InvoiceStatus";

import Absent from "./pages/absent/absent/component/Absent";
import AbsentAdd from "./pages/absent/absent/component/AbsentAdd";
import Overtime from "./pages/absent/overtime/component/Overtime";
import OvertimeAdd from "./pages/absent/overtime/component/OvertimeAdd";
import RemoveTarget from "./pages/absent/removeTarget/component/RemoveTarget";
import RemoveTargetAdd from "./pages/absent/removeTarget/component/RemoveTargetAdd";

import HolidayContainer from "./pages/holiday/component/HolidayContainer";
import HolidayAdd from "./pages/holiday/component/HolidayAdd";
import CalendarAdd from "./pages/holiday/component/CalendarAdd";
import HolidayRequestAdd from "./pages/holiday/component/HolidayRequestAdd";

import Team from "./pages/master/team/component/Team";
import TeamAdd from "./pages/master/team/component/TeamAdd";
import Employee from "./pages/master/employee/component/Employee";
import EmployeeAdd from "./pages/master/employee/component/EmployeeAdd";
import Position from "./pages/master/position/component/Position";
import PositionAdd from "./pages/master/position/component/PositionAdd";
import Shift from "./pages/master/shift/component/Shift";
import ShiftAdd from "./pages/master/shift/component/ShiftAdd";
import District from "./pages/master/district/component/District";
import DistrictAdd from "./pages/master/district/component/DistrictAdd";
import Koj from "./pages/master/koj/component/Koj";
import KojAdd from "./pages/master/koj/component/KojAdd";
import Requestor from "./pages/master/requestor/component/Requestor";
import RequestorAdd from "./pages/master/requestor/component/RequestorAdd";
import Submittal from "./pages/master/submittal/component/Submittal";
import SubmittalAdd from "./pages/master/submittal/component/SubmittalAdd";
import StatusJobnumber from "./pages/master/statusJobnumber/component/StatusJobnumber";
import StatusJobnumberAdd from "./pages/master/statusJobnumber/component/StatusJobnumberAdd";
import TypeJobnumber from "./pages/master/typeJobnumber/component/TypeJobnumber";
import TypeJobnumberAdd from "./pages/master/typeJobnumber/component/TypeJobnumberAdd";
import TypeJob from "./pages/master/typeJob/component/TypeJob";
import TypeJobAdd from "./pages/master/typeJob/component/TypeJobAdd";
import ProjectDefault from "./pages/master/projectDefault/component/ProjectDefault";
import ProjectDefaultAdd from "./pages/master/projectDefault/component/ProjectDefaultAdd";
import BillDefault from "./pages/master/billDefault/component/BillDefault";
import BillDefaultAdd from "./pages/master/billDefault/component/BillDefaultAdd";

import ReportIndividualJob from "./pages/report/component/ReportIndividualJob";
import ReportIndividualSelf from "./pages/report/component/ReportIndividualSelf";

import ReportDaily from "./pages/report/component/ReportDaily";
import ReportDailyQc from "./pages/report/component/ReportDailyQc";
import ReportFault from "./pages/report/component/ReportFault";
import ReportAbsent from "./pages/report/component/ReportAbsent";
import ReportAbsentLog from "./pages/report/component/ReportAbsentLog";
import ReportOvertime from "./pages/report/component/ReportOvertime";
import ReportRemoveTarget from "./pages/report/component/ReportRemoveTarget";
import ReportHolidayRequest from "./pages/report/component/ReportHolidayRequest";
import ReportAll from "./pages/report/component/ReportAll";
import AbsentReminder from "./pages/absent/absent/component/AbsentReminder";
import Profile from "./pages/profile/component/Profile";
import Kpi from "./pages/kpi/component/Kpi";
import KpiAdd from "./pages/kpi/component/KpiAdd";

function App() {

  const navigate = useNavigate()

  useEffect(() => {
    let cqmBorn = localStorage.getItem("cqmBorn")
    if (cqmBorn) {
      if (cqmBorn === '1') {
      }
      else if (cqmBorn === undefined) {
        localStorage.removeItem('cqmToken')
        localStorage.removeItem('cqmData')
        localStorage.removeItem('cqmBorn')
        navigate("/login")
      }
      else {
        let bornValue = parseInt(cqmBorn)
        let relative = (new Date().getTime()) - bornValue
        if (relative > 86400000) {
          localStorage.removeItem('cqmToken')
          localStorage.removeItem('cqmData')
          localStorage.removeItem('cqmBorn')
          navigate("/login")
        }
      }
    }
    else {
      navigate('/login')
    }
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        main: "#5B46F1"
      },
      secondary: {
        main: "#EFECFE",
        contrastText: "#5B46F1"
      }
    },
    shadows: ["none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none"],
    shape: {
      borderRadius: 10
    }
  })

  const routeList = [
    {
      path: "/dashboard",
      component: <Dashboard />
    },
    {
      path: "/project",
      component: <Project />
    },
    {
      path: "/project/add",
      component: <ProjectAdd />
    },
    {
      path: "/project/detail",
      component: <JobNumber />
    },
    {
      path: "/project/detail/addrequest",
      component: <JobNumberAddRequest />
    },
    {
      path: "/project/detail/addrevision",
      component: <JobNumberAddRevision />
    },
    {
      path: "/project/detail/job/addactivity",
      component: <JobNumberJobAddActivity />
    },
    {
      path: "/project/jobnumber",
      component: <JobManagement />
    },
    {
      path: "/project/archive",
      component: <ProjectArchive />
    },
    {
      path: "/project/archive/detail",
      component: <JobNumberArchive />
    },
    {
      path: "/project/additionalpoles",
      component: <AdditionalPoles />
    },
    {
      path: "/project/additionalpoles/add",
      component: <AdditionalPolesAdd />
    },
    {
      path: "/tracker",
      component: <Tracker />
    },
    {
      path: "/transfer",
      component: <TransferJob />
    },
    {
      path: "/bill",
      component: <Bill />
    },
    {
      path: "/bill/history",
      component: <Billed />
    },
    {
      path: "/bill/create",
      component: <BilledCreate />
    },
    {
      path: "/invoicestatus",
      component: <InvoiceStatus />
    },
    {
      path: "/worksheet",
      component: <Worksheet />
    },
    {
      path: "/takenjob",
      component: <Takenjob />
    },
    {
      path: "/absent/absent",
      component: <Absent />
    },
    {
      path: "/absent/absent/add",
      component: <AbsentAdd />
    },
    {
      path: "/absent/overtime",
      component: <Overtime />
    },
    {
      path: "/absent/overtime/add",
      component: <OvertimeAdd />
    },
    {
      path: "/absent/removetarget",
      component: <RemoveTarget />
    },
    {
      path: "/absent/removetarget/add",
      component: <RemoveTargetAdd />
    },
    {
      path: "/holiday",
      component: <HolidayContainer />
    },
    {
      path: "/holiday/add",
      component: <HolidayAdd />
    },
    {
      path: "/holiday/calendar/add",
      component: <CalendarAdd />
    },
    {
      path: "/holiday/request/add",
      component: <HolidayRequestAdd />
    },
    {
      path: "/master/team",
      component: <Team />
    },
    {
      path: "/master/team/add",
      component: <TeamAdd />
    },
    {
      path: "/master/employee",
      component: <Employee />
    },
    {
      path: "/master/employee/add",
      component: <EmployeeAdd />
    },
    {
      path: "/master/position",
      component: <Position />
    },
    {
      path: "/master/position/add",
      component: <PositionAdd />
    },
    {
      path: "/master/shift",
      component: <Shift />
    },
    {
      path: "/master/shift/add",
      component: <ShiftAdd />
    },
    {
      path: "/master/district",
      component: <District />
    },
    {
      path: "/master/district/add",
      component: <DistrictAdd />
    },
    {
      path: "/master/koj",
      component: <Koj />
    },
    {
      path: "/master/koj/add",
      component: <KojAdd />
    },
    {
      path: "/master/requestor",
      component: <Requestor />
    },
    {
      path: "/master/requestor/add",
      component: <RequestorAdd />
    },
    {
      path: "/master/submittal",
      component: <Submittal />
    },
    {
      path: "/master/submittal/add",
      component: <SubmittalAdd />
    },
    {
      path: "/master/statusjobnumber",
      component: <StatusJobnumber />
    },
    {
      path: "/master/statusjobnumber/add",
      component: <StatusJobnumberAdd />
    },
    {
      path: "/master/typejobnumber",
      component: <TypeJobnumber />
    },
    {
      path: "/master/typejobnumber/add",
      component: <TypeJobnumberAdd />
    },
    {
      path: "/master/typejob",
      component: <TypeJob />
    },
    {
      path: "/master/typejob/add",
      component: <TypeJobAdd />
    },
    {
      path: "/master/projectdefault",
      component: <ProjectDefault />
    },
    {
      path: "/master/projectdefault/add",
      component: <ProjectDefaultAdd />
    },
    {
      path: "/master/billdefault",
      component: <BillDefault />
    },
    {
      path: "/master/billdefault/add",
      component: <BillDefaultAdd />
    },
    {
      path: "/individualreport/job",
      component: <ReportIndividualJob />
    },
    {
      path: "/individualreport/self",
      component: <ReportIndividualSelf />
    },
    {
      path: "/report/daily",
      component: <ReportDaily />
    },
    {
      path: "/report/qc",
      component: <ReportDailyQc />
    },
    {
      path: "/report/fault",
      component: <ReportFault />
    },
    {
      path: "/report/absent",
      component: <ReportAbsent />
    },
    {
      path: "/report/absent/log",
      component: <ReportAbsentLog />
    },
    {
      path: "/report/overtime",
      component: <ReportOvertime />
    },
    {
      path: "/report/removetarget",
      component: <ReportRemoveTarget />
    },
    {
      path: "/report/holidayrequest",
      component: <ReportHolidayRequest />
    },
    {
      path: "/report/all",
      component: <ReportAll />
    },
    {
      path: "/report/kpi",
      component: <Kpi />
    },
    {
      path: "/report/kpi/add",
      component: <KpiAdd />
    },
    {
      path: "*",
      component: <>Working on it :)</>
    }
  ]

  return (
    <ThemeProvider theme={theme}>
      <AbsentReminder />
      <Routes>
        {routeList.map((item, i) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/" element={<Navigate to={Object.keys(JSON.parse(localStorage.getItem('cqmData') || '{}').position_access || { "/login": "" })[0]} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
