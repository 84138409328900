import { Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function FormDate(props: any) {

    const handleChange = (value: any) => {
        props.onChange({
            target: {
                name: props.name,
                value: moment(value).format('YYYY-MM-DD')
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <b>{props.label}</b>
            </Grid>
            <Grid item md={9}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        label={props.label}
                        inputFormat="MM/DD/YYYY"
                        value={props.value}
                        disabled={props.disabled}
                        onChange={(value) => handleChange(moment(value).format('YYYY-MM-DD'))}
                        renderInput={(params) => <TextField {...params} size="small" required={props.required} fullWidth />}
                        disablePast={props.disablePast}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    )
}