import { Modal } from "@mui/material"
import ReactLoading from 'react-loading';
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function CustomLoading() {

    const loadingLogin = useSelector((state: RootState) => state.login).loading
    const loadingContainers = useSelector((state: RootState) => state.containers).loading
    const loadingShift = useSelector((state: RootState) => state.shift).loading
    const loadingPosition = useSelector((state: RootState) => state.position).loading
    const loadingTeam = useSelector((state: RootState) => state.team).loading
    const loadingDistrict = useSelector((state: RootState) => state.district).loading
    const loadingKoj = useSelector((state: RootState) => state.koj).loading
    const loadingEmployee = useSelector((state: RootState) => state.employee).loading
    const loadingRequestor = useSelector((state: RootState) => state.requestor).loading
    const loadingSubmittal = useSelector((state: RootState) => state.submittal).loading
    const loadingStatusJobnumber = useSelector((state: RootState) => state.statusjobnumber).loading
    const loadingProject = useSelector((state: RootState) => state.project).loading
    const loadingBill = useSelector((state: RootState) => state.bill).loading
    const loadingTypeJob = useSelector((state: RootState) => state.typejob).loading
    const loadingWorksheet = useSelector((state: RootState) => state.worksheet).loading
    const loadingTakenJob = useSelector((state: RootState) => state.takenjob).loading
    const loadingReport = useSelector((state: RootState) => state.report).loading
    const loadingTransferJob = useSelector((state: RootState) => state.transferJob).loading
    const loadingAbsent = useSelector((state: RootState) => state.absent).loading
    const loadingOvertime = useSelector((state: RootState) => state.overtime).loading
    const loadingProjectDefault = useSelector((state: RootState) => state.projectDefault).loading
    const loadingHoliday = useSelector((state: RootState) => state.holiday).loading
    const loadingBillDefault = useSelector((state: RootState) => state.billDefault).loading
    const loadingInvoiceStatus = useSelector((state: RootState) => state.invoiceStatus).loading
    const loadingDashboard = useSelector((state: RootState) => state.dashboard).loading
    const loadingRemoveTarget = useSelector((state: RootState) => state.removeTarget).loading
    const loadingTracker = useSelector((state: RootState) => state.tracker).loading
    const loadingAdditionalPoles = useSelector((state: RootState) => state.additionalPoles).loading
    const loadingKpi = useSelector((state: RootState) => state.kpi).loading

    return (
        <Modal open={
            loadingLogin ||
            loadingContainers ||
            loadingShift ||
            loadingPosition ||
            loadingTeam ||
            loadingDistrict ||
            loadingKoj ||
            loadingEmployee ||
            loadingRequestor ||
            loadingSubmittal ||
            loadingProject ||
            loadingStatusJobnumber ||
            loadingBill ||
            loadingTypeJob ||
            loadingWorksheet ||
            loadingTakenJob ||
            loadingReport ||
            loadingTransferJob ||
            loadingAbsent ||
            loadingOvertime ||
            loadingProjectDefault ||
            loadingHoliday ||
            loadingBillDefault ||
            loadingInvoiceStatus ||
            loadingDashboard ||
            loadingRemoveTarget ||
            loadingTracker ||
            loadingAdditionalPoles ||
            loadingKpi
        }>
            <div className="custom-loading">
                <ReactLoading className="custom-loading" type="bars" />
            </div>
        </Modal>
    )
}