import { Grid, TextField, Typography } from "@mui/material";

export default function FormText(props: any) {
    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <Typography paddingTop={1} fontWeight={"bold"}>{props.label}</Typography>
            </Grid>
            <Grid item md={9}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder={props.label}
                    name={props.name}
                    value={props.value || ""}
                    onChange={props.onChange}
                    required={props.required}
                    disabled={props.disabled}
                    InputProps={{
                        readOnly: props.readOnly
                    }}
                />
            </Grid>
        </Grid>
    )
}