import { Checkbox, Container, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import AbsentEdit from "../../absent/absent/component/AbsentEdit";
import ReportAbsentEdit from "./ReportAbsentEdit";

export default function ReportAbsent() {

    const dispatch = useDispatch()

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "Absent Report",
                url: "/report/absent"
            }
        ]))
    }, [])

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    return (
        <Container maxWidth='xl'>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit Absent"
                component={
                    <ReportAbsentEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/report/absent"}
                filterDate={true}
                columns={[
                    {
                        name: "Employee",
                        key: "employee_name"
                    },
                    {
                        name: "Absent Date",
                        key: "name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.name ? moment(props.data.name).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Request Minutes",
                        key: "minutes"
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Status",
                        key: "status_name"
                    },
                    {
                        name: "Approved Minutes",
                        key: "minutes_approval"
                    },
                    {
                        name: "Rejection Notes",
                        key: "notes_approval"
                    },
                    {
                        name: "Deduct?",
                        key: "is_deduct",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.is_deduct
                                        ? <Checkbox checked={true} />
                                        : <Checkbox checked={false} />
                                    }
                                </td>
                            )
                        }
                    }
                ]}
                export={true}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                timeGetData={timeGetData}
            />
        </Container>
    )
}