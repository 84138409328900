import { Close } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import { Fragment } from "react";

export default function JobNumberFormList(props: any) {

    let values = (
        props.value
            ? props.value.includes(',')
                ? props.value.split(',')
                : [props.value]
            : []
    )

    const handleAdd = () => {
        props.onChange({
            target: {
                name: props.name,
                value: [...values, 0].join()
            }
        })
    }

    const handleDelete = (index: number) => {
        props.onChange({
            target: {
                name: props.name,
                value: values.filter((d: number, i: number) => i !== index).join()
            }
        })
    }

    const handleEdit = (index: number, value: number) => {
        props.onChange({
            target: {
                name: props.name,
                value: (
                    values
                        .map((d: number, i: number) => {
                            if (i === index) {
                                return value
                            }
                            return d
                        })
                        .join()
                )
            }
        })
    }

    return (
        <Fragment>
            <ul>
                {values.map((value: string, i: number) => (
                    <li key={i} style={{ marginTop: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", }}>
                            <TextField type="number" label="Value Billed" size="small" style={{ minWidth: 100 }} value={value} onChange={e => handleEdit(i, parseInt(e.target.value))} />
                            <IconButton onClick={() => handleDelete(i)}>
                                <Close />
                            </IconButton>
                        </div>
                    </li>
                ))}
            </ul>
            <Button type="submit" variant="outlined" size="small" fullWidth onClick={handleAdd}>Add</Button>
        </Fragment >
    )
}