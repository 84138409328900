import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormCheck from "../../../shared/FormCheck"
import FormDate from "../../../shared/FormDate"
import FormNumber from "../../../shared/FormNumber"
import FormText from "../../../shared/FormText"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { editAbsent } from "../../absent/absent/absent.api"

export default function ReportAbsentEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        name: "",
        minutes: "",
        notes: "",
        minutes_approval: 0,
        is_deduct: 0
    })

    useEffect(() => {
        setValues({
            name: moment(props.data.name).format("YYYY-MM-DD"),
            minutes: props.data.minutes,
            notes: props.data.notes,
            minutes_approval: props.data.minutes_approval,
            is_deduct: props.data.is_deduct
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editAbsent({
            ...values,
            id: props.data.id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit absent")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormDate label="Absent Date" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Request Minutes" name="minutes" value={values.minutes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Approved Minutes" name="minutes_approval" value={values.minutes_approval} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormCheck label="Deduct?" name="is_deduct" value={values.is_deduct} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Absent
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}