import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createProjectDefault = createAsyncThunk('createProjectDefault', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/default',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteProjectDefault = createAsyncThunk('deleteProjectDefault', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/default?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editProjectDefault = createAsyncThunk('editProjectDefault', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/project/default',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})