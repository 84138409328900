import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function FormPassword(props: any) {

    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <Typography paddingTop={1} fontWeight={"bold"}>{props.label}</Typography>
            </Grid>
            <Grid item md={9}>
                <TextField
                    fullWidth
                    size="small"
                    type={showPassword ? "text" : "password"}
                    placeholder={props.label}
                    name={props.name}
                    value={props.value || ""}
                    onChange={props.onChange}
                    required={props.required}
                    disabled={props.disabled}
                    InputProps={{
                        readOnly: props.readOnly,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Grid>
    )
}