import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Modal, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import notificationError from "../../../shared/notificationError";
import { login } from "../login.api";
import ReactLoading from 'react-loading';

export default function Login() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isRememberMe, setIsRememberMe] = useState(false)

    const handleSubmit = (e: any) => {
        setLoading(true)
        e.preventDefault()
        dispatch<any>(login({
            email: email,
            password: password
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    localStorage.setItem('cqmToken', response.data.data.id)
                    localStorage.setItem('cqmData', JSON.stringify({
                        name: response.data.data.name,
                        position_name: response.data.data.position_name,
                        shift_id: response.data.data.shift_id,
                        koj_list: JSON.parse(response.data.data.koj_list),
                        position_access: JSON.parse(response.data.data.position_access),
                        team_ids: (response.data.data.team_ids).split(',')
                    }))
                    if (isRememberMe) {
                        localStorage.setItem('cqmBorn', '1')
                    }
                    else {
                        localStorage.setItem('cqmBorn', String(new Date().getTime()))
                    }
                    navigate('/')
                }
                else {
                    notificationError(response)
                }
                setLoading(false)
            })
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Modal open={loading}>
                <div className="custom-loading">
                    <ReactLoading className="custom-loading" type="bars" />
                </div>
            </Modal>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Lock />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            type={showPassword ? "text" : "password"}
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isRememberMe} onChange={(e, value) => setIsRememberMe(value)} />
                            }
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>

    )
}