import { Card, CardContent } from "@mui/material";
import CustomTable from "../../../shared/CustomTable";

export default function KpiDetail(props: { data: any }) {

    return (
        <Card>
            <CardContent>
                <CustomTable
                    url={`/kpi/employee/${props.data.id}`}
                    columns={[
                        {
                            name: "Period",
                            key: "kpi_name"
                        },
                        {
                            name: "Employee",
                            key: "employee_name"
                        },
                        {
                            name: "Achievement",
                            key: "value_achievement",
                            customRender: (props: any) => {
                                return (
                                    <td>
                                        <span>
                                            {props.data.value_achievement}%
                                        </span>
                                    </td>
                                )
                            }
                        },
                        {
                            name: "KPI",
                            key: "value",
                            customRender: (props: any) => {
                                return (
                                    <td>
                                        <span>
                                            {props.data.value}%
                                        </span>
                                    </td>
                                )
                            }
                        },
                        {
                            name: "Note",
                            key: "note"
                        }
                    ]}
                    export={true}
                />
            </CardContent>
        </Card>
    )
}