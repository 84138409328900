import { createSlice } from '@reduxjs/toolkit'
import { activeJobNumber, archiveJobNumber, createEmployeeJob, createJobActivity, createJobNumber, createJobNumberRequest, createJobNumberRevision, createProject, deleteJob, deleteJobNumber, deleteProject, editJob, editJobNumber, editJobNumberKoj, editProject, getJobNumberListWithPaginationsByProject, getJobNumberOptions, getJobNumberSingle, getJobSingle, getProjectDefault, getProjectListWithPaginations, getProjectSingle, jobnumberPartialCompleted } from './project.api'

export interface ProjectState {
    loading: boolean
}
const initialState: ProjectState = {
    loading: false
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getProjectListWithPaginations.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProjectListWithPaginations.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProjectListWithPaginations.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getProjectSingle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProjectSingle.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProjectSingle.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createProject.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createProject.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createProject.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteProject.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteProject.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteProject.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editProject.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editProject.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editProject.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getJobNumberListWithPaginationsByProject.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getJobNumberListWithPaginationsByProject.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getJobNumberListWithPaginationsByProject.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getJobNumberSingle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getJobNumberSingle.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getJobNumberSingle.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getJobNumberOptions.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getJobNumberOptions.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getJobNumberOptions.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createJobNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createJobNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createJobNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createJobNumberRequest.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createJobNumberRequest.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createJobNumberRequest.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createJobNumberRevision.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createJobNumberRevision.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createJobNumberRevision.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteJobNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteJobNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteJobNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(archiveJobNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(archiveJobNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(archiveJobNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(activeJobNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(activeJobNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(activeJobNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editJobNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editJobNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editJobNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(jobnumberPartialCompleted.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(jobnumberPartialCompleted.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(jobnumberPartialCompleted.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editJobNumberKoj.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editJobNumberKoj.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editJobNumberKoj.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getJobSingle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getJobSingle.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getJobSingle.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createJobActivity.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createJobActivity.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createJobActivity.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createEmployeeJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createEmployeeJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createEmployeeJob.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getProjectDefault.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProjectDefault.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProjectDefault.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default projectSlice.reducer