import { createSlice } from '@reduxjs/toolkit'
import { approveOvertimeHead, approveOvertimeHr, createOvertime, deleteOvertime, editOvertime } from './overtime.api'

export interface OvertimeState {
    loading: boolean,
    timeGetData: any
}
const initialState: OvertimeState = {
    loading: false,
    timeGetData: undefined
}

export const overtimeSlice = createSlice({
    name: 'overtime',
    initialState,
    reducers: {
        setTimeGetData: (state, action) => {
            state.timeGetData = action.payload;
            console.log(action.payload)
        }
    },
    extraReducers: (builder) => {

        builder.addCase(createOvertime.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createOvertime.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createOvertime.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteOvertime.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteOvertime.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteOvertime.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editOvertime.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editOvertime.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editOvertime.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(approveOvertimeHead.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(approveOvertimeHead.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(approveOvertimeHead.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(approveOvertimeHr.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(approveOvertimeHr.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(approveOvertimeHr.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setTimeGetData
} = overtimeSlice.actions;

export default overtimeSlice.reducer