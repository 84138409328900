import { Close, Search } from "@mui/icons-material";
import { Box, Card, CardContent, Container, Divider, IconButton, InputAdornment, LinearProgress, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import notificationError from "../../../shared/notificationError";
import { baseUrl } from "../../../state";

export default function ReportAbsentLog() {

    const dispatch = useDispatch()

    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [dataList, setDataList] = useState<any>([])
    const [searchWord, setSearchWord] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingValue, setLoadingValue] = useState(0)
    const [dateRange, setDateRange] = useState<any>([])

    useEffect(() => {
        let dt = new Date().getTime()
        setDateStart(moment(dt - 604800000).format('YYYY-MM-DD'))
        setDateEnd(moment(dt).format('YYYY-MM-DD'))
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "Absent Log Report",
                url: "/report/absent/log"
            }
        ]))
    }, [])

    useEffect(() => {
        if (dateStart && dateEnd) {
            getData()
        }
    }, [dateStart, dateEnd])

    useEffect(() => {
        if (!loading) {
            setLoadingValue(0)
        }
    }, [loading])

    const getData = (isClear: boolean = false) => {
        setLoading(true)
        setDataList([])
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}/report/absent/log?search=${isClear ? "" : searchWord}&datestart=${dateStart}&dateend=${dateEnd}`,
            onDownloadProgress: progressEvent => {
                const total = parseFloat(progressEvent.total)
                const current = progressEvent.currentTarget.response.length
                let percentCompleted = Math.round(current / total * 100)
                setLoadingValue(percentCompleted)
            }
        })
            .then((response: any) => {
                if (response.status === 200) {
                    setDataList(response.data.data)
                }
                else {
                    notificationError(response)
                }
            })
            .finally(() => {
                setLoading(false)
            })
        setDateRange(getDateInRange(dateStart, dateEnd))
    }

    const handleChangeSearch = (e: any) => {
        let { value } = e.target
        setSearchWord(value)
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        getData()
    }

    const handleSearchReset = (e: any) => {
        e.preventDefault()
        setSearchWord("")
        getData(true)
    }

    const getDateInRange = (dateStart: string, dateEnd: string) => {
        const result: any = []
        let timeRecent = new Date(dateStart).getTime()
        let timeEnd = new Date(dateEnd).getTime()
        while (timeRecent <= timeEnd) {
            result.push(moment(timeRecent).format('YYYY-MM-DD'))
            timeRecent = new Date(new Date(timeRecent).setDate(new Date(timeRecent).getDate() + 1)).getTime()
        }
        return result
    }

    return (
        <Container maxWidth="xl">
            <Card variant="outlined">
                <CardContent className="custom-table-container">
                    <div className="header">
                        <div>
                            <Fragment>
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    type="date"
                                    label="From"
                                    value={dateStart}
                                    onChange={(e) => setDateStart(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    type="date"
                                    label="To"
                                    value={dateEnd}
                                    onChange={(e) => setDateEnd(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Fragment>
                            <form onSubmit={handleSearch}>
                                <TextField
                                    className="inp"
                                    size="small"
                                    variant="outlined"
                                    value={searchWord}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                                        endAdornment: searchWord.length > 0 && (<InputAdornment position="end"><IconButton onClick={handleSearchReset}><Close /></IconButton></InputAdornment>)
                                    }}
                                    placeholder="Type and enter for search"
                                />
                                <button type="submit" hidden={true} />
                            </form>
                        </div>
                    </div>
                    <Divider />
                    {loading && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={loadingValue} />
                        </Box>
                    )}
                    <div className="body">
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        Employee
                                    </td>
                                    <td>
                                        Work Day
                                    </td>
                                    <td>
                                        Overtime
                                    </td>
                                    <td>
                                        Potongan
                                    </td>
                                    <td>
                                        Ijin
                                    </td>
                                    <td>
                                        Alpha
                                    </td>
                                    {dateRange.map((date: string, i: number) => (
                                        <td key={i}>
                                            {moment(date).format('DD/MM')}
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((data: any, i: number) => {
                                    var alphaSum = 0
                                    let logs = data.log_list ? JSON.parse(data.log_list) : []
                                    dateRange.map((date: string, j: number) => {
                                        let logThisDate = logs.filter((log: any) => log.date === date)
                                        let dayOfWeek = new Date(date).getDay()
                                        if (logThisDate.length > 0) {
                                            return null
                                        }
                                        else if (dayOfWeek === 6 || dayOfWeek === 0) {
                                            return null
                                        }
                                        alphaSum = alphaSum + 1
                                    })
                                    return (
                                        <tr key={i} className={`${i % 2 ? `` : `odd`}`}>
                                            <td>
                                                {data.name}
                                            </td>
                                            <td>
                                                {data.absent_count}
                                            </td>
                                            <td>
                                                {data.overtime_minute}
                                            </td>
                                            <td>
                                                {data.absent_cut_minute}
                                            </td>
                                            <td>
                                                {data.absent_permission_count}
                                            </td>
                                            <td>
                                                {alphaSum}
                                            </td>
                                            {dateRange.map((date: string, j: number) => {
                                                let logThisDate = logs.filter((log: any) => log.date === date)
                                                let dayOfWeek = new Date(date).getDay()
                                                if (logThisDate.length > 0) {
                                                    let status = logThisDate[0].status
                                                    let status_color = logThisDate[0].status_color
                                                    let note = logThisDate[0].note
                                                    return (
                                                        <td key={j}>
                                                            <span style={{ color: status_color }}>{status}</span> <br />
                                                            {note}
                                                        </td>
                                                    )
                                                }
                                                else if (dayOfWeek === 6 || dayOfWeek === 0) {
                                                    return (
                                                        <td key={j}>
                                                            L <br />
                                                            Weekend
                                                        </td>
                                                    )
                                                }
                                                return (
                                                    <td key={j}>
                                                        <span style={{ color: 'red' }}>A</span>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardContent>
            </Card>
        </Container >
    )
}