import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../../shared/FormAutocomplete"
import FormDate from "../../../../shared/FormDate"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { createJobNumber, getProjectDefault } from "../../project.api"
import JobNumberAddFormNames from "./JobNumberAddFormNames"
import JobNumberFormKoj from "./JobnumberFormKoj"

export default function JobNumberAdd(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        quantity_request: 0,
        is_hot_job: false,
        date_received: "",
        type_id: "",
        koj_list: [],
        job_type_id: ""
    })
    const [isDefaultFulfill, setIsDefaultFulfill] = useState(false)
    const [nameList, setNameList] = useState<any>([])

    useEffect(() => {
        dispatch<any>(getProjectDefault({
            requestor_id: props.data.requestor_id,
            district_id: props.data.district_id,
            submittal_id: props.data.submittal_id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.data.data.length > 0) {
                    setValues({
                        ...values,
                        job_type_id: response.data.data[0].koj_list ? JSON.parse(response.data.data[0].koj_list)[0].type_id : undefined,
                        koj_list: (
                            response.data.data[0].koj_list
                                ? JSON.parse(response.data.data[0].koj_list).map((koj: any, i: number) => {
                                    return {
                                        koj_id: koj.koj_id,
                                        koj_name: koj.koj_name,
                                        target: koj.target,
                                        order_value: koj.order_value
                                    }
                                })
                                : []
                        )
                    })
                }
                setIsDefaultFulfill(true)
            })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        try {
            for (const i in nameList) {
                await new Promise((resolve, reject) => {
                    dispatch<any>(createJobNumber({
                        ...values,
                        number_job: nameList[i].number_job,
                        quantity_request: nameList[i].quantity_request,
                        is_hot_job: nameList[i].is_hot_job,
                        project_id: props.data.id
                    }))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                resolve(true)
                            }
                            else {
                                reject(response)
                            }
                        })
                        .catch((err: any) => {
                            reject(err)
                        })
                })
            }
            notificationSuccess(`success add ${nameList.length} jobNumber`)
                .then(() => {
                    props.onClose(true)
                })
        }
        catch (error) {
            notificationError(error)
        }
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormAutocomplete url="/requestor" label="Requestor" value={props.data.requestor_id} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormAutocomplete url="/district" label="District" value={props.data.district_id} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormAutocomplete url="/submittal" label="Submittal" value={props.data.submittal_id} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormDate label="Date Received" name="date_received" value={values.date_received} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <FormAutocomplete url="/project/jobnumber/type" label="Type" name="type_id" value={props.data.type_id} onChange={handleChangeValues} />
                    <br />
                    <Divider />
                    <br />
                    <JobNumberAddFormNames values={nameList} setValues={setNameList} />
                    <br />
                    <Divider />
                    <br />
                    {isDefaultFulfill && <FormAutocomplete url="/type/job" label="Job Type" name="job_type_id" value={values.job_type_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {isDefaultFulfill && <JobNumberFormKoj name="koj_list" value={values.koj_list} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                            disabled={nameList.length === 0}
                        >
                            Add Job Number
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}