import { Card, CardContent, Container, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getEmployeeDetail } from "../../login/login.api";
import notificationError from "../../../shared/notificationError";

export default function Profile() {

    const dispatch = useDispatch()

    const [data, setData] = useState<any>({})

    useEffect(() => {
        dispatch<any>(getEmployeeDetail())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setData(response.data.data)
                }
                else {
                    notificationError(response)
                }
            })
    }, [])

    return (
        <Container
            sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Card variant="outlined">
                <CardContent>
                    <TextField fullWidth margin="normal" label="Name" value={data.name || ""} />
                    <TextField fullWidth margin="normal" label="NIK" value={data.nik || ""} />
                    <TextField fullWidth margin="normal" label="Email" value={data.email || ""} />
                    <TextField fullWidth margin="normal" label="Team" value={data.team_names || ""} />
                </CardContent>
            </Card>
        </Container>
    )
}