import { Button, Checkbox, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import checkAccess from "../../../../shared/checkAccess";
import CustomModal from "../../../../shared/CustomModal";
import CustomTable from "../../../../shared/CustomTable";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { RootState } from "../../../../store";
import { approveAbsent, deleteAbsent } from "../absent.api";
import { setTimeGetData } from "../absent.reducer";
import AbsentApproval from "./AbsentApproval";
import AbsentEdit from "./AbsentEdit";

export default function AbsentAll() {

    const dispatch = useDispatch()

    const { timeGetData } = useSelector((state: RootState) => state.absent)

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [modalApproval, setModalApproval] = useState(false)

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleCloseModalApproval = (isReload: boolean) => {
        setModalApproval(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteAbsent(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete absent")
                                dispatch(setTimeGetData(new Date().getTime()))
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    const handleApproveMultiple = (dataIdSelected: any) => {
        if (dataIdSelected.length > 0) {
            dispatch<any>(approveAbsent({
                ids: dataIdSelected
            }))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("absent successfully approved")
                        dispatch(setTimeGetData(new Date().getTime()))
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
        else {
            Swal.fire({
                title: "Select at least one row",
                icon: "error"
            })
        }
    }

    return (
        <Fragment>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit Absent"
                component={
                    <AbsentEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomModal
                open={modalApproval}
                onClose={handleCloseModalApproval}
                title="Edit Absent"
                component={
                    <AbsentApproval onClose={handleCloseModalApproval} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/absent"}
                filterDate={true}
                columns={[
                    {
                        name: "Employee",
                        key: "employee_name"
                    },
                    {
                        name: "Absent Date",
                        key: "name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.name ? moment(props.data.name).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Request Date",
                        key: "minutes",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {moment(props.data.ts_created).utc().format('DD-MM-YYYY HH:mm:ss')}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Request Minutes",
                        key: "minutes"
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Status",
                        key: "status_name"
                    },
                    {
                        name: "Approved Minutes",
                        key: "minutes_approval"
                    },
                    {
                        name: "Rejection Notes",
                        key: "notes_approval"
                    },
                    {
                        name: "Deduct?",
                        key: "notes_approval",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.is_deduct
                                        ? <Checkbox checked={true} />
                                        : <Checkbox checked={false} />
                                    }
                                </td>
                            )
                        }
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("APPROVAL") &&
                            <Button
                                variant="contained"
                                onClick={() => handleApproveMultiple(props.dataIdSelected)}
                            >
                                Approve
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                rowActionsAdditional={(props: any) => {
                    return (
                        <Fragment>
                            {checkAccess("APPROVAL") && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={e => {
                                        setDataSelected(props.data)
                                        setModalApproval(true)
                                    }}>
                                    Approval
                                </Button>
                            )}
                        </Fragment>
                    )
                }}
                timeGetData={timeGetData}
                selectRow={checkAccess("APPROVAL")}
            />
        </Fragment>
    )
}