import { createSlice } from '@reduxjs/toolkit'
import { createAdditionalPoles, deleteAdditionalPoles, editAdditionalPoles } from './additionalPoles.api'

export interface AdditionalPolesState {
    loading: boolean
}
const initialState: AdditionalPolesState = {
    loading: false
}

export const additionalPolesSlice = createSlice({
    name: 'additionalPoles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createAdditionalPoles.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createAdditionalPoles.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createAdditionalPoles.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteAdditionalPoles.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteAdditionalPoles.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteAdditionalPoles.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editAdditionalPoles.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editAdditionalPoles.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editAdditionalPoles.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default additionalPolesSlice.reducer