import { createSlice } from '@reduxjs/toolkit'
import { controlJobHold, controlJobStart, getEmployeeJobRunning } from './takenjob.api'

export interface TakenjobState {
    loading: boolean,
    jobRunning: any,
    timeGetData: number
}
const initialState: TakenjobState = {
    loading: false,
    jobRunning: {},
    timeGetData: 0
}

export const takenjobSlice = createSlice({
    name: 'takenjob',
    initialState,
    reducers: {
        setTimeGetData: (state, action) => {
            state.timeGetData = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getEmployeeJobRunning.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getEmployeeJobRunning.fulfilled, (state, action) => {
            state.loading = false
            state.jobRunning = action.payload.data.data
        })
        builder.addCase(getEmployeeJobRunning.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlJobStart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlJobStart.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlJobStart.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlJobHold.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlJobHold.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlJobHold.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setTimeGetData
} = takenjobSlice.actions;

export default takenjobSlice.reducer