import { Button, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { ID_STATUS_APPROVAL_APPROVED } from "../../../state";
import { RootState } from "../../../store";
import { deleteHolidayRequest } from "../holiday.api";
import { setTimeGetData } from "../holiday.reducer";
import HolidayRequestApprovalHead from "./HolidayRequestApprovalHead";
import HolidayRequestApprovalHr from "./HolidayRequestApprovalHr";
import HolidayRequestEdit from "./HolidayRequestEdit";

export default function HolidayRequest() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { yearSelected, timeGetData } = useSelector((state: RootState) => state.holiday)

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [modalApprovalHead, setModalApprovalHead] = useState(false)
    const [modalApprovalHr, setModalApprovalHr] = useState(false)

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleCloseModalApprovalHead = (isReload: boolean) => {
        setModalApprovalHead(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleCloseModalApprovalHr = (isReload: boolean) => {
        setModalApprovalHr(false)
        if (isReload) {
            dispatch(setTimeGetData(new Date().getTime()))
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteHolidayRequest(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete holidayRequest")
                                dispatch(setTimeGetData(new Date().getTime()))
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Fragment>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit HolidayRequest"
                component={
                    <HolidayRequestEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomModal
                open={modalApprovalHead}
                onClose={handleCloseModalApprovalHead}
                title="Head Approval Request Holiday"
                component={
                    <HolidayRequestApprovalHead onClose={handleCloseModalApprovalHead} data={dataSelected} />
                }
            />
            <CustomModal
                open={modalApprovalHr}
                onClose={handleCloseModalApprovalHr}
                title="Hr Approval Request Holiday"
                component={
                    <HolidayRequestApprovalHr onClose={handleCloseModalApprovalHr} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/holiday/request"}
                filterDate={true}
                columns={[
                    {
                        name: "Employee",
                        key: "employee_name"
                    },
                    {
                        name: "Request Date",
                        key: "ts_created",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.ts_created ? moment(props.data.ts_created).format('DD-MM-YYYY hh:mm:ss') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Holiday Date",
                        key: "request_date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.request_date ? moment(props.data.request_date).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Notes",
                        key: "notes"
                    },
                    {
                        name: "Status",
                        key: "status_name"
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("HOLIDAY_REQUEST_CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => navigate('/holiday/request/add')}
                            >
                                New Holiday Request
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("HOLIDAY_REQUEST_EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("HOLIDAY_REQUEST_DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                rowActionsAdditional={(props: any) => {
                    return (
                        <Fragment>
                            {checkAccess("REQUEST_HOLIDAY_APPROVAL_HEAD") && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={e => {
                                        setDataSelected(props.data)
                                        setModalApprovalHead(true)
                                    }}
                                    disabled={Boolean(props.data.head_status_id === ID_STATUS_APPROVAL_APPROVED)}
                                >
                                    Head Approval
                                </Button>
                            )}
                            {checkAccess("REQUEST_HOLIDAY_APPROVAL_HR") && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={e => {
                                        setDataSelected(props.data)
                                        setModalApprovalHr(true)
                                    }}
                                    disabled={Boolean(props.data.hr_status_id === ID_STATUS_APPROVAL_APPROVED)}
                                >
                                    HR Approval
                                </Button>
                            )}
                        </Fragment>
                    )
                }}
                timeGetData={timeGetData}
            />
        </Fragment>
    )
}