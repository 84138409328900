import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { editHolidayRequest } from "../../holiday/holiday.api"
import FormAutocomplete from "../../../shared/FormAutocomplete"

export default function ReportHolidayRequestEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        head_status_id: "",
        hr_status_id: ""
    })

    useEffect(() => {
        setValues({
            head_status_id: props.data.head_status_id,
            hr_status_id: props.data.hr_status_id
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editHolidayRequest({
            ...values,
            id: props.data.id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit holiday request")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    {values.head_status_id && <FormAutocomplete url="/status/approval" label="Status Head" name="head_status_id" value={values.head_status_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    {values.hr_status_id && <FormAutocomplete url="/status/approval" label="Status HR" name="hr_status_id" value={values.hr_status_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Absent
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}