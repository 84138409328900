import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import FormDate from "../../../shared/FormDate";
import FormText from "../../../shared/FormText";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { ID_STATUS_APPROVAL_REQUEST } from "../../../state";
import { createHolidayRequest } from "../holiday.api";

export default function HolidayRequestAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        employee_id: "",
        request_date: "",
        notes: ""
    })

    useEffect(() => {
        dispatch(setTitle("Holiday"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Holiday",
                url: ""
            },
            {
                name: "HolidayRequest",
                url: "/holiday"
            },
            {
                name: "Create HolidayRequest",
                url: "/holiday/request/add"
            }
        ]))
        setValues({
            ...values,
            employee_id: window.localStorage.getItem("cqmToken"),
            head_status_id: ID_STATUS_APPROVAL_REQUEST,
            hr_status_id: ID_STATUS_APPROVAL_REQUEST
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createHolidayRequest(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new holiday request")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else if (response.status === 400) {
                    Swal.fire({
                        icon: "info",
                        text: response.data.message
                    })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Holiday Request
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create HolidayRequest
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Request Date" name="request_date" value={values.request_date} onChange={handleChangeValues} required disablePast={!["CSCA", "HR"].includes(JSON.parse(localStorage.getItem("cqmData") || "{}").position_name)} />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}