import { Check } from "@mui/icons-material";
import { Autocomplete, Button, Card, CardContent, Container, Divider, Grid, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import FormAutocomplete from "../../../../shared/FormAutocomplete";
import FormDate from "../../../../shared/FormDate";
import FormNumber from "../../../../shared/FormNumber";
import FormText from "../../../../shared/FormText";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { baseUrl } from "../../../../state";
import { createRemoveTarget } from "../removeTarget.api";

export default function RemoveTargetAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        employee_id: "",
        name: "",
        value: "",
        notes: "",
        head_status_id: "",
        jobnumber_id: undefined,
        koj_id: undefined,
        quantity_pole: undefined
    })
    const [projectId, setProjectId] = useState("")
    const [projectList, setProjectList] = useState<any>([])
    const [kojSelected, setKojSelected] = useState<any>(undefined)

    useEffect(() => {
        dispatch(setTitle("RemoveTarget"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "RemoveTarget",
                url: ""
            },
            {
                name: "RemoveTarget",
                url: "/removetarget/removeTarget"
            },
            {
                name: "Create RemoveTarget",
                url: "/removetarget/removeTarget/add"
            }
        ]))
        setValues({
            ...values,
            name: moment().format("YYYY-MM-DD"),
            employee_id: window.localStorage.getItem("cqmToken"),
            head_status_id: "78DDF83B-6C8F-4748-B053-100EBC8B91AE"
        })
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: `${baseUrl}/project`
        })
            .then((response: any) => {
                setProjectList(response.data.data.map((d: any) => { return { ...d, config_list: JSON.parse(d.config_list) } }))
            })
    }, [])

    useEffect(() => {
        if (Boolean(projectId)) {
            setKojSelected(undefined)
            setValues({
                ...values,
                jobnumber_id: undefined,
                quantity_pole: 0
            })
        }
    }, [projectId])

    useEffect(() => {
        if (Boolean(kojSelected?.id)) {
            setValues({
                ...values,
                quantity_pole: (480 / kojSelected.target) * (values.value || 0)
            })
        }
    }, [kojSelected, values.value])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createRemoveTarget({
            ...values,
            koj_id: kojSelected.koj_id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new removeTarget")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New RemoveTarget
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create RemoveTarget
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Remove Target Date" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormNumber label="Remove Target" name="value" value={values.value} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/project" label="Project" onChange={(e: any) => { setProjectId(e.target.value) }} />
                        <br />
                        {projectId && (
                            <Fragment>
                                <Divider />
                                <br />
                                <FormAutocomplete url={`/project/jobnumber/options?project_id=${projectId}&koj_id=ALL`} label="Job Number" name="jobnumber_id" value={values.jobnumber_id} onChange={handleChangeValues} />
                                <br />
                            </Fragment>
                        )}
                        {Boolean(values.jobnumber_id) && (
                            <Fragment>
                                <Divider />
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item md={3}>
                                        <b>KOJ</b>
                                    </Grid>
                                    <Grid item md={9}>
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            id="combo-box-demo"
                                            options={projectList.filter((d: any) => d.id === projectId)[0].config_list}
                                            getOptionLabel={(option: any) => { return option.koj_name || "" }}
                                            value={kojSelected}
                                            onChange={(e, value) => setKojSelected(value)}
                                            renderInput={(params) => <TextField {...params} size="small" placeholder={"Choose KOJ"} required />}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                            </Fragment>
                        )}
                        <Divider />
                        <br />
                        <FormNumber label="Quantity Pole" name="quantity_pole" value={values.quantity_pole} onChange={handleChangeValues} disabled />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}