import { createSlice } from '@reduxjs/toolkit'
import { createShift, deleteShift, editShift } from './shift.api'

export interface ShiftState {
    loading: boolean
}
const initialState: ShiftState = {
    loading: false
}

export const shiftSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createShift.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createShift.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createShift.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteShift.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteShift.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteShift.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editShift.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editShift.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editShift.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default shiftSlice.reducer