import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getEmployeeJobRunning = createAsyncThunk('getEmployeeJobRunning', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/running',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlJobStart = createAsyncThunk('controlJobStart', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/control/start/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const controlJobHold = createAsyncThunk('controlJobHold', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/employee/job/control/hold/' + id
        })
        return response
    } catch (error: any) {
        return error.response
    }
})