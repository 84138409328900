import { Button, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { deleteCalendar } from "../holiday.api";
import CalendarEdit from "./CalendarEdit";

export default function Calendar() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)
    const [filterDateInitialStart, setFilterDateInitialStart] = useState("")
    const [filterDateInitialEnd, setFilterDateInitialEnd] = useState("")

    useEffect(() => {
        let dt = new Date()
        setFilterDateInitialStart(`01-01-${dt.getFullYear()}`)
        setFilterDateInitialEnd(`12-31-${dt.getFullYear()}`)
    }, [])

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteCalendar(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete calendar")
                                setTimeGetData(new Date().getTime())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Fragment>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit Calendar"
                component={
                    <CalendarEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            {(filterDateInitialStart && filterDateInitialEnd) && (
                <CustomTable
                    url={"/calendar"}
                    filterDate={true}
                    filterDateInitialStart={filterDateInitialStart}
                    filterDateInitialEnd={filterDateInitialEnd}
                    columns={[
                        {
                            name: "Name",
                            key: "name"
                        },
                        {
                            name: "Date",
                            key: "date",
                            customRender: (props: any) => {
                                return (
                                    <td>
                                        <span>
                                            {props.data.date ? moment(props.data.date).format('DD-MM-YYYY') : '-'}
                                        </span>
                                    </td>
                                )
                            }
                        },
                        {
                            name: "Date Off",
                            key: "date_off",
                            customRender: (props: any) => {
                                return (
                                    <td>
                                        <span>
                                            {props.data.date_off ? moment(props.data.date_off).format('DD-MM-YYYY') : '-'}
                                        </span>
                                    </td>
                                )
                            }
                        },
                        {
                            name: "Type",
                            key: "type_name"
                        }
                    ]}
                    headerActions={(props: any) => (
                        <Fragment>
                            {checkAccess("CALENDAR_CREATE") &&
                                <Button
                                    variant="contained"
                                    onClick={() => navigate('/holiday/calendar/add')}
                                >
                                    New Calendar
                                </Button>
                            }
                        </Fragment>
                    )}
                    rowActions={(props: any) => (
                        <Fragment>
                            {checkAccess("CALENDAR_EDIT") &&
                                <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                    <ListItemText>Edit</ListItemText>
                                </ListItemButton>
                            }
                            {checkAccess("CALENDAR_DELETE") &&
                                <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                    <ListItemText>Delete</ListItemText>
                                </ListItemButton>
                            }
                        </Fragment>
                    )}
                    timeGetData={timeGetData}
                />
            )}
        </Fragment>
    )
}