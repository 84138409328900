import { Typography } from "@mui/material";
import moment from "moment";
import { Fragment } from "react";
import CustomTable from "../../../shared/CustomTable";

export default function WorksheeRelated() {

    return (
        <Fragment>
            <Typography variant="h4">Related Job</Typography>
            <br />
            <CustomTable
                url={"/employee/job/related"}
                columns={[
                    {
                        name: "KOJ Status",
                        key: "job_calculation_status"
                    },
                    {
                        name: "Job Number",
                        key: "jobnumber_number_job"
                    },
                    {
                        name: "Requestor",
                        key: "jobnumber_project_requestor_name"
                    },
                    {
                        name: "Submittal",
                        key: "jobnumber_project_submittal_name"
                    },
                    {
                        name: "District",
                        key: "jobnumber_project_district_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span style={{ backgroundColor: props.data.koj_color }}>{props.data.koj_name}</span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Job Received",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_received ? moment(props.data.jobnumber_date_received).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Due Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {props.data.jobnumber_date_due ? moment(props.data.jobnumber_date_due).format('DD-MM-YYYY') : '-'}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Source Job",
                        key: "source_job"
                    },
                    {
                        name: "QTY Request",
                        key: "job_calculation_total_request"
                    }
                ]}
            />
        </Fragment>
    )
}