import { Button, Container, ListItemButton, ListItemText } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import checkAccess from "../../../shared/checkAccess";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { deleteKpi } from "../kpi.api";
import moment from "moment";
import CustomModal from "../../../shared/CustomModal";
import KpiDetail from "./KpiDetail";
import KpiEdit from "./KpiEdit";
import { getEmployeeUnder } from "../../master/employee/employee.api";

export default function Kpi() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [timeGetData, setTimeGetData] = useState<any>(undefined)
    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalDetail, setModalDetail] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "KPI",
                url: "/report/kpi"
            }
        ]))
    }, [])

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteKpi(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete kpi")
                                setTimeGetData(new Date().getTime())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    const handleCreate = () => {
        dispatch<any>(getEmployeeUnder())
            .unwrap()
            .then(((response: any) => {
                if (response.status === 200) {
                    navigate('/report/kpi/add')
                }
                else {
                    notificationError(response)
                }
            }))
    }

    return (
        <Container>
            <CustomModal
                open={modalDetail}
                onClose={() => setModalDetail(false)}
                title="Detail KPI"
                component={
                    <KpiDetail data={dataSelected} />
                }
            />
            <CustomModal
                open={modalEdit}
                onClose={() => { setModalEdit(false); setTimeGetData(new Date().getTime()) }}
                title="Edit KPI"
                component={
                    <KpiEdit data={dataSelected} />
                }
            />
            <CustomTable
                url={"/kpi"}
                columns={[
                    {
                        name: "Data Created",
                        key: "ts_created",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span>
                                        {props.data.ts_created ? moment(props.data.ts_created).format('DD-MM-YYYY') : '-'}
                                    </span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Period",
                        key: "name"
                    },
                    {
                        name: "Head",
                        key: "head_name"
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("CREATE") &&
                            <Button
                                variant="contained"
                                onClick={handleCreate}
                            >
                                New Kpi
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                rowActionsAdditional={(props: any) => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => { setDataSelected(props.data); setModalDetail(true) }}
                        >
                            Detail
                        </Button>
                    )
                }}
                timeGetData={timeGetData}
            />
        </Container>
    )
}