import { Container, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer"
import RemoveTargetAll from "./RemoveTargetAll"
import RemoveTargetSelf from "./RemoveTargetSelf"

export default function RemoveTarget() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("RemoveTarget"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "RemoveTarget",
                url: ""
            },
            {
                name: "RemoveTarget",
                url: "/removetarget/removeTarget"
            }
        ]))
    }, [])

    return (
        <Container maxWidth="xl">
            <br />
            <RemoveTargetSelf />
            <br />
            <Typography variant="h4">All RemoveTarget</Typography>
            <br />
            <RemoveTargetAll />
        </Container >
    )
}