import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../shared/FormAutocomplete"
import FormDate from "../../../shared/FormDate"
import FormText from "../../../shared/FormText"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { editHolidayRequest } from "../holiday.api"

export default function HolidayRequestApprovalHr(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        employee_id: "",
        request_date: "",
        notes: "",
        head_status_id: "",
        hr_status_id: ""
    })

    useEffect(() => {
        setValues({
            id: props.data.id,
            employee_id: props.data.employee_id,
            request_date: moment(props.data.request_date).format("YYYY-MM-DD"),
            notes: props.data.notes,
            head_status_id: props.data.head_status_id,
            hr_status_id: props.data.hr_status_id
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editHolidayRequest(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit absent")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormDate label="Request Date" name="request_date" value={values.request_date} onChange={handleChangeValues} disabled />
                    <br />
                    <Divider />
                    <br />
                    <FormText label="Notes" name="notes" value={values.notes} onChange={handleChangeValues} disabled />
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/status/approval" label="Status" name="head_status_id" value={values.head_status_id} onChange={handleChangeValues} disabled />}
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/status/approval" label="Status" name="hr_status_id" value={values.hr_status_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Request Holiday
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}