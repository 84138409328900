import { Check } from "@mui/icons-material";
import { Button, Card, CardContent, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import FormAutocomplete from "../../../shared/FormAutocomplete";
import FormDate from "../../../shared/FormDate";
import FormText from "../../../shared/FormText";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { createCalendar } from "../holiday.api";

export default function CalendarAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [values, setValues] = useState<any>({
        name: "",
        date: "",
        type_id: ""
    })

    useEffect(() => {
        dispatch(setTitle("Holiday"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Holiday",
                url: ""
            },
            {
                name: "Calendar",
                url: "/holiday"
            },
            {
                name: "Create Calendar",
                url: "/holiday/calendar/add"
            }
        ]))
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(createCalendar(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success create new holiday")
                        .then(() => {
                            navigate(-1)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container>
            <Card>
                <CardContent className="custom-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-header">
                            <span>
                                New Calendar
                            </span>
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<Check />}
                                    type="submit"
                                >
                                    Create Calendar
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Divider />
                        <br />
                        <FormText label="Name" name="name" value={values.name} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Date" name="date" value={values.date} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormDate label="Date Off" name="date_off" value={values.date_off} onChange={handleChangeValues} required />
                        <br />
                        <Divider />
                        <br />
                        <FormAutocomplete url="/calendar/type" label="Type" name="type_id" value={values.type_id} onChange={handleChangeValues} />
                        <br />
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}