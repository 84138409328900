import { Article, Assessment, AssignmentTurnedIn, CalendarMonth, ChevronRight, Dashboard, ExpandLess, MoveDown, PersonAddAlt1, ReceiptLong, SensorOccupied, SignalCellularAlt, Timeline } from "@mui/icons-material"
import { Badge, Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getEmployeeDetail } from "../../pages/login/login.api"
import { getTransferJobAvailable } from "../../pages/transferjob/transferJob.api"
import notificationError from "../../shared/notificationError"
import { RootState } from "../../store"
import { setTransferJobBadge } from "../containers.reducer"

export default function Sidetab() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { transferJobBadge } = useSelector((state: RootState) => state.containers)

    const [expanded, setExpanded] = useState("")
    const [accessList, setAcceesList] = useState<any>({})

    const menuList = [
        {
            name: "Dashboard",
            url: "/dashboard",
            icon: <Dashboard />
        },
        {
            name: "Project",
            url: "/project",
            icon: <SignalCellularAlt />,
            child: [
                {
                    name: "Project",
                    url: "/project"
                },
                {
                    name: "Job Management",
                    url: "/project/jobnumber"
                },
                {
                    name: "Archive",
                    url: "/project/archive"
                },
                {
                    name: "Additional Poles",
                    url: "/project/additionalpoles"
                }
            ]
        },
        {
            name: "Tracker",
            url: "/tracker",
            icon: <Timeline />
        },
        {
            name: "Transfer Job",
            url: "/transfer",
            icon: <Badge badgeContent={transferJobBadge} color="error">
                <MoveDown />
            </Badge>
        },
        {
            name: "Worksheet",
            url: "/worksheet",
            icon: <Article />
        },
        {
            name: "Taken Job",
            url: "/takenjob",
            icon: <AssignmentTurnedIn />
        },
        {
            name: "Bill",
            url: "/bill",
            icon: <ReceiptLong />,
            child: [
                {
                    name: "Bill Job",
                    url: "/bill"
                },
                {
                    name: "Invoice Status",
                    url: "/invoicestatus"
                }
            ]
        },
        {
            name: "Absent",
            url: "/absent",
            icon: <SensorOccupied />,
            child: [
                {
                    name: "Absent",
                    url: "/absent/absent"
                },
                {
                    name: "Overtime",
                    url: "/absent/overtime"
                },
                {
                    name: "Remove Target",
                    url: "/absent/removetarget"
                }
            ]
        },
        {
            name: "Holiday",
            url: "/holiday",
            icon: <CalendarMonth />
        },
        {
            name: "Master Data",
            url: "/master",
            icon: <PersonAddAlt1 />,
            child: [
                {
                    name: "Team",
                    url: "/master/team"
                },
                {
                    name: "Employee",
                    url: "/master/employee"
                },
                {
                    name: "Position",
                    url: "/master/position"
                },
                {
                    name: "Shift",
                    url: "/master/shift"
                },
                {
                    name: "District",
                    url: "/master/district"
                },
                {
                    name: "KOJ",
                    url: "/master/koj"
                },
                {
                    name: "Requestor",
                    url: "/master/requestor"
                },
                {
                    name: "Submittal",
                    url: "/master/submittal"
                },
                {
                    name: "Status Jobnumber",
                    url: "/master/statusjobnumber"
                },
                {
                    name: "Type Jobnumber",
                    url: "/master/typejobnumber"
                },
                {
                    name: "Type Target",
                    url: "/master/typejob"
                },
                {
                    name: "Default Project Config",
                    url: "/master/projectdefault"
                },
                {
                    name: "Default Billing Config",
                    url: "/master/billdefault"
                }
            ]
        },
        {
            name: "Individual Report",
            url: "/individualreport",
            icon: <Assessment />,
            child: [
                {
                    name: "Job Report",
                    url: "/individualreport/job"
                },
                {
                    name: "Self Report",
                    url: "/individualreport/self"
                }
            ]
        },
        {
            name: "Report",
            url: "/report",
            icon: <Assessment />,
            child: [
                {
                    name: "Daily Report",
                    url: "/report/daily"
                },
                {
                    name: "QC Daily Report",
                    url: "/report/qc"
                },
                {
                    name: "Fault Report",
                    url: "/report/fault"
                },
                {
                    name: "Absent Report",
                    url: "/report/absent"
                },
                {
                    name: "Absent Log Report",
                    url: "/report/absent/log"
                },
                {
                    name: "Overtime Report",
                    url: "/report/overtime"
                },
                {
                    name: "Remove Target Report",
                    url: "/report/removetarget"
                },
                {
                    name: "Holiday Request Report",
                    url: "/report/holidayrequest"
                },
                {
                    name: "All Report",
                    url: "/report/all"
                },
                {
                    name: "KPI",
                    url: "/report/kpi"
                }
            ]
        }
    ]

    useEffect(() => {
        dispatch<any>(getEmployeeDetail())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    let newData = JSON.stringify({
                        name: response.data.data.name,
                        position_name: response.data.data.position_name,
                        shift_id: response.data.data.shift_id,
                        koj_list: JSON.parse(response.data.data.koj_list),
                        position_access: JSON.parse(response.data.data.position_access),
                        team_ids: (response.data.data.team_ids).split(',')
                    })
                    if (newData !== window.localStorage.getItem("cqmData")) {
                        localStorage.setItem('cqmData', newData)
                        window.location.reload()
                    }
                }
                else {
                    notificationError(response)
                }
            })
        dispatch<any>(getTransferJobAvailable())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setTransferJobBadge(response.data.data))
                }
                else {
                    notificationError(response)
                }
            })
        let cqmDataString = localStorage.getItem('cqmData')
        let cqmData = cqmDataString ? JSON.parse(cqmDataString) : {}
        let positionAccess = cqmData.position_access || {}
        setAcceesList(positionAccess)
    }, [])

    const handleClickMenu = (menu: any) => {
        if (!menu.child) {
            navigate(menu.url)
        }
        else if (expanded === menu.url) {
            setExpanded("")
        }
        else {
            setExpanded(menu.url)
        }
    }

    return (
        <Drawer
            open={true}
            variant="permanent"
        >
            <div className="sidetab">
                <div className="brand">
                    <span className="brand-short">CQM</span>
                    <span>
                        Colaborative <br />
                        Quality <br />
                        Management <br />
                    </span>
                </div>
                <List>
                    {menuList.filter(m => m.child ? m.child.some(c => Object.keys(accessList).includes(c.url)) : Object.keys(accessList).includes(m.url)).map((menu, i) => (
                        <div key={i}>
                            <ListItemButton className={(
                                window.location.hash.includes(menu.url)
                                    ? " active"
                                    : ""
                            )} onClick={() => handleClickMenu(menu)}>
                                <ListItemIcon>
                                    {menu.icon}
                                </ListItemIcon>
                                <ListItemText primary={menu.name} />
                                {expanded === menu.url ? <ExpandLess /> : <ChevronRight />}
                            </ListItemButton>
                            {menu.child && (
                                <Collapse in={expanded === menu.url}>
                                    <List disablePadding>
                                        {menu.child.filter(m => Object.keys(accessList).includes(m.url)).map((child, i) => (
                                            <ListItemButton key={i} onClick={() => handleClickMenu(child)}>
                                                <ListItemIcon></ListItemIcon>
                                                <ListItemText primary={child.name} />
                                                <ChevronRight />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    ))}
                </List>
            </div>
        </Drawer>
    )
}