import { Checkbox, FormControlLabel, FormGroup, Table } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import notificationError from "../../../../shared/notificationError"
import { getMenu } from "../position.api"

export default function PositionEditAccess(props: any) {

    const dispatch = useDispatch()

    const [menus, setMenus] = useState<any>([])
    const [values, setValues] = useState<any>({})

    useEffect(() => {
        dispatch<any>(getMenu())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setMenus(response.data.data)
                }
                else {
                    notificationError(response)
                }
            })
        setValues(JSON.parse(props.value) || {})
    }, [])

    const handleCheckAll = (e: any, url: string) => {
        let accessOnMenus = menus.filter((menu: any) => menu.url === url)[0].access_names
        if (e.target.checked) {
            setValues({
                ...values,
                [url]: accessOnMenus
            })
        }
        else {
            let newValues = JSON.parse(JSON.stringify(values))
            delete newValues[url]
            setValues(newValues)
        }
    }

    const handleCheck = (e: any, url: string, access: string) => {
        if (e.target.checked) {
            let lastValue = values[url]
            let lastValueArr = lastValue?.split(',') || []
            let newValueArr = [...lastValueArr, access]
            let newValue = newValueArr.toString()
            if (newValue[0] === ',') {
                newValue = newValue.substring(1)
            }
            setValues({
                ...values,
                [url]: newValue
            })
        }
        else {
            let lastValue = values[url]
            let lastValueArr = lastValue?.split(',') || []
            let newValueArr = lastValueArr.filter((value: string) => value !== access)
            let newValue = newValueArr.toString()
            if (!newValue.length) {
                let newValues = JSON.parse(JSON.stringify(values))
                delete newValues[url]
                setValues(newValues)
            }
            else {
                setValues({
                    ...values,
                    [url]: newValue
                })
            }
        }
    }

    useEffect(() => {
        props.onChange({
            target: {
                name: 'access',
                value: JSON.stringify(values)
            }
        })
    }, [values])

    return (
        <Table>
            <tbody>
                {menus.map((menu: any, i: number) => {
                    let accessArr = menu.access_names ? menu.access_names.split(',') : []
                    return (
                        <tr key={i} style={{ borderTop: "1px solid black" }}>
                            <td><b>{menu.name}</b></td>
                            <td>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(values[menu.url]?.length === menu.access_names.length) || false}
                                                onChange={(e) => handleCheckAll(e, menu.url)}
                                            />}
                                        label="ALL"
                                    />
                                </FormGroup>
                                {accessArr.map((access: string, j: number) => (
                                    <FormGroup key={j}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values[menu.url]?.includes(access) || false}
                                                    onChange={(e) => handleCheck(e, menu.url, access)}
                                                />
                                            }
                                            label={access}
                                        />
                                    </FormGroup>
                                ))}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}