import { createSlice } from '@reduxjs/toolkit'
import { approveRemoveTargetHead, approveRemoveTargetHr, createRemoveTarget, deleteRemoveTarget, editRemoveTarget } from './removeTarget.api'

export interface RemoveTargetState {
    loading: boolean,
    timeGetData: any
}
const initialState: RemoveTargetState = {
    loading: false,
    timeGetData: undefined
}

export const removeTargetSlice = createSlice({
    name: 'removeTarget',
    initialState,
    reducers: {
        setTimeGetData: (state, action) => {
            state.timeGetData = action.payload;
            console.log(action.payload)
        }
    },
    extraReducers: (builder) => {

        builder.addCase(createRemoveTarget.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRemoveTarget.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRemoveTarget.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteRemoveTarget.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRemoveTarget.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRemoveTarget.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editRemoveTarget.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRemoveTarget.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRemoveTarget.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(approveRemoveTargetHead.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(approveRemoveTargetHead.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(approveRemoveTargetHead.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(approveRemoveTargetHr.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(approveRemoveTargetHr.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(approveRemoveTargetHr.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setTimeGetData
} = removeTargetSlice.actions;

export default removeTargetSlice.reducer