import { Close } from "@mui/icons-material";
import { Button, Card, CardContent, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import notificationError from "../../../../shared/notificationError";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../containers/containers.reducer";
import { baseUrl } from "../../../../state";

export default function BillDefaultFormKoj(props: any) {

    const dispatch = useDispatch()

    const [options, setOptions] = useState<any>([])
    const [optionsSelected, setOptionsSelected] = useState<any>([])

    useEffect(() => {
        dispatch(setLoading(true))
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/koj'
        })
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setLoading(false))
                    setOptions(response.data.data)
                    setOptionsSelected(props.value)
                }
                else {
                    dispatch(setLoading(false))
                    notificationError(response)
                }
            })
            .catch((error: any) => {
                dispatch(setLoading(false))
                notificationError(error.response)
            })
    }, [])

    const handleSelect = (item: any) => {
        let newSelected = [...optionsSelected, { koj_id: item.id, koj_name: item.name, target: 0, order_value: optionsSelected.length }]
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    const handleUnselect = (item: any) => {
        let newSelected = optionsSelected.filter((option: any) => option.koj_id !== item.koj_id)
        setOptionsSelected(newSelected)
        props.onChange({
            target: {
                name: 'koj_list',
                value: newSelected
            }
        })
    }

    return (
        <Grid container spacing={2} className="bill-default-add-koj">
            <Grid item md={3}>
                <b>KOJ</b>
            </Grid>
            <Grid item md={9}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                KOJ Lists
                                <br />
                                <br />
                                {options.map((item: any) => (
                                    <Button
                                        key={item.id}
                                        style={{ marginTop: 5, marginBottom: 5 }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleSelect(item)}
                                        disabled={optionsSelected.map((koj: any) => { return koj.koj_id }).includes(item.id)}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                Selected KOJ
                                <br />
                                <ul className="koj-list">
                                    {optionsSelected.sort((a: any, b: any) => { return a.order_value - b.order_value }).map((item: any) => (
                                        <li key={item.koj_id}>
                                            <span>{item.koj_name}</span>
                                            <Close onClick={() => handleUnselect(item)} />
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}